// Angular Imports
// =========================================================
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
// Prime NG (UI) Imports
// =========================================================
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
// NG Idle Imports
// =========================================================
import { Idle } from '@ng-idle/core';
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service';
import { Subject, takeUntil } from 'rxjs';
// Custom Imports
// =========================================================
// import { AuthService } from 'src/app/core/authentication/auth.service';
@Injectable({
	providedIn: 'root',
})
@Component({
	selector: 'app-err-modal',
	templateUrl: './err-modal.component.html',
	styleUrls: ['./err-modal.component.scss'],
	providers: [DialogService],
})
export class ErrModalComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	timeStamp: string = '';
	title: string = '';
	errorStatus: string = null;
	errorMessage: string = '';
	endSession: boolean = false;
	customMessage: string = '';
	inactiveUser;
	countdown: number = 60;

	constructor(
		public ref: DynamicDialogRef,
		public dialogService: DialogService,
		public config: DynamicDialogConfig,
		private idle: Idle,
		public cognitoService: CognitoService
	) {}

	ngOnInit(): void {
		// Error data
		let error = this.config.data.error;

		error
			? ((this.timeStamp = new Date().toLocaleString()),
			  (this.title = error.title),
			  (this.errorStatus = error.errorStatus),
			  (this.errorMessage = error.errorMessage),
			  (this.endSession = error.logout),
			  // To find the component with an error message console log the customMessage
			  (this.customMessage = error.customMessage))
			: null;

		if (error.errorStatus === `${403}`) {
			this.inactiveUser = error.errorStatus === `${403}`;

			this.idle.onTimeoutWarning.subscribe((seconds) => {
				this.countdown = seconds || 60;
				if (seconds === 1) {
					this.ref.close();
					this.cognitoService.signOut();
				}
			});
			// console.log('Inactive User: ', this.inactiveUser);
		}
	}
	// Close the modal
	close(event) {
		this.ref.close();
	}
	// Logout
	logout(event) {
		this.ref.close();
		this.cognitoService.signOut();
	}
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.ref.close();
	}
}
