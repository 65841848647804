<input
  #input
  [id]="propertyName"
  [(ngModel)]="inputValue"
  (ngModelChange)="updateFilter($event)"
  style="height: 28px; font-weight: 400; font-size: 12px"
  [style.width]="params.column.actualWidth + 'px'"
/>
<ag-grid-angular
  style="font-weight: 150"
  [style.height]="gridHeight + 'px'"
  [style.max-width]="gridWidth + 'px'"
  class="ag-theme-balham"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [rowSelection]="rowSelection"
  (gridReady)="onGridReady($event)"
  (rowClicked)="rowClicked($event)"
>
</ag-grid-angular>
