// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// Ag Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, GridReadyEvent, RowDataChangedEvent } from 'ag-grid-community';
// Custom Imports
// =========================================================
import { CreateTestScenariosService } from 'src/app/core/services/db/create-test-scenarios.service';
import { FormatKey } from 'src/app/core/utils/global-functions';
import { handleError } from 'src/app/core/utils/error-handling';

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: `./create-test-scenarios-detail-grid.component.html`,
})

export class CreateTestScenarioDetailGrid implements ICellRendererAngularComp {

  gridApi: GridApi;
  gridOptions: any = {};
  // Grid Data
  columnDefs: any[] = [];
  rowData: any[] = [];
  params;
  masterGridApi;
  rowId;
  defaultColDef;
  selectedRows: any[] = []

  updatingRowData: any[] = [];
  loadingMessage: string = '';

  constructor(
    private createTestScenariosService: CreateTestScenariosService
  ) {}

  // called on init
  agInit(params: any): void {
    console.log("child params: ", params)
    this.params = params;

    this.masterGridApi = params.api;
    this.rowId = params.node.id;
    this.gridOptions = {
      ...params.detailGridOptions,
      fullWidthCellRenderer: 'rowLoadingCellRenderer',
      context: {componentParent: this},
      onRowDataChanged: (event: RowDataChangedEvent) => this.scrollToBottom(event),
      onSelectionChanged: (event) => {console.log("rows selected: ", event.api.getSelectedRows()); return this.selectedRows = event.api.getSelectedRows()},
      isFullWidthCell: (rowNode) => this.updatingRowData.indexOf(`${rowNode.id}`) >= 0,
      getRowNodeId: (data) => FormatKey(data.name),
    }
    this.columnDefs = params.detailGridOptions.columnDefs
    this.rowData = typeof params.data.test_cases === "object" ? params.data.test_cases : []
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    let gridInfo = {
      id: this.rowId,
      api: event.api,
      columnApi: event.columnApi,
    };
    console.log('adding detail grid info with id: ', this.rowId);
    this.gridApi.setRowData(this.rowData)
    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }
  // When a new test is created scroll to bottom
  scrollToBottom(event) {
    if (this.rowData) {
      event.api.ensureIndexVisible(this.rowData.length -  1, 'bottom')
    }
  }
  // Delete test case scenarios
  deleteTestCase(event: any) {
    const selectedTests = this.gridApi.getSelectedNodes();
    const numofTests = `${selectedTests.length} Test Scenario${selectedTests.length === 1 ? '' : 's'}`
    this.loadingMessage = `Deleting ${numofTests}`

    // Reformat the test data to send to the db
    const deleteTests = selectedTests.map(row => {
      this.updatingRowData.push(`${row.id}`)
      return {
        dim_id: this.params.data.dim_id,
        testName: row.data.name
      }
    })
    // Redraw the grid to show which rows are updating
    this.gridApi.redrawRows()

    // Run the Delete Test Scenarios
    this.createTestScenariosService.deleteTestScenario(deleteTests).subscribe({
      next: ({new_recs}) => {
        console.log("Test Scenario Deleted: ", new_recs);
        this.params.context.componentParent.initialRender = false
        this.params.context.componentParent.addRowData = new_recs
        this.params.context.componentParent.getAllDimensions();
      },
      complete: () => {},
      error: (error) => handleError(this.params.context.componentParent.dialogService, error, 'Error Deleting Test Scenarios')
    })
  }

  ngOnDestroy(): void {
    // detail grid is automatically destroyed as it is an Angular component
    console.log('removing detail grid info with id: ', this.rowId);
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  }
}