import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"
import { DSO_customCellFormatting } from "../../03_ag-cellFormatters/define-size-offerings-cell-formatters";

export interface DSO_GridDataParams {
    mainColDefs: any[];
    detailColDefs: any[];
}

export async function DSO_GenerateGridData(params: DSO_GridDataParams ) {
    const { mainColDefs, detailColDefs} = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {
            let obj = {
               ...GenerateDefaultColDefs(colKey, colVal),
                width: colVal['refData'].colSize || 100,
                flex: colVal.refData.colSize ? 0 : 1,
            }
            Object.assign(mappedCols, { 
                [obj.colId]:  DSO_customCellFormatting(obj, gridToRender)
            })
        })

        return Object.values(mappedCols)
    }

    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")
        let detailCols = await GenerateCols(detailColDefs, "detail")

        let results = await Promise.all([mainCols, detailCols]);
        const m = results.indexOf(mainCols)
        const d = results.indexOf(detailCols)

        const data = {
            mainColDefs: results[m],
            detailColDefs: results[d]
        }
        return data
    }
    return getResults()

}