// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
import { cloneDeep } from "lodash";
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
// AG Grid Imports
// =========================================================
import { GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
// Mock JSON Data
// =========================================================
import VendorPacks_MainColDefs from "../../../../core/json/set-carton-and-buy-params/colDefs-main-vendor-packs-modal.json";
import VendorPacks_DetailColDefs from "../../../../core/json/set-carton-and-buy-params/colDefs-detail-vendor-packs-modal.json";
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
import { DefaultDetailGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-variables';
import { VendorPacks_GenerateGridData } from "../../../02_ag-grid-settings/02_ag-generate-col-Defs/set-carton-and-buy-params/vendor-packs-modal-grid-config";
import { VendorPackModalDetailGrid } from './vp-detail-grid/vendor-packs-modal-detail-grid.component';
import { SetCartonAndBuyParamsService } from 'src/app/core/services/db/set-carton-and-buy-params.service';
import { handleError } from 'src/app/core/utils/error-handling';

@Component({
  selector: 'app-vendor-packs-modal',
  templateUrl: './vendor-packs-modal.component.html',
  styleUrls: ['./vendor-packs-modal.component.scss']
})

export class VendorPacksModalComponent implements OnInit {

  // Ag Grid Configuration
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: "Set Carton And Buy Parameters | Vendor Packs",
    },
  };

  modalTitle: string = 'Vendor Packs'

  gridApi: GridApi;
  gridOptions: any = {};

  columnDefs: any = []
  detailColumnDefs: any = []
  objectKeys = Object.keys

  og_selected: any[] = []
  vendorPacksUpdated: any = {}
  parentData: any = {}

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private SCABP_Service: SetCartonAndBuyParamsService,
    public dialogService: DialogService,
  ) {
    console.log("data: ", this.config.data)
    this.parentData = this.config.data.rowNode.data
    // Set AgGrid Options
    this.gridOptions = {
      rowData: this.config.data.vendor_packs || [],
      ...this.customGridOptions,
      frameworkComponents: {
        ...this.customGridOptions.frameworkComponents,
        vendorPackModalDetailGrid: VendorPackModalDetailGrid
      },
      rowSelection: 'multiple',
      detailCellRenderer: 'vendorPackModalDetailGrid',
      getRowHeight: (params) => { if (params.node && params.node.detail) return 260; },
      onSelectionChanged: ( event: SelectionChangedEvent) => this.updateVendorPacks(event),
    };

    this.renderGrid(VendorPacks_MainColDefs[0], VendorPacks_DetailColDefs[0])

  }

  ngOnInit(): void {
  }
  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs, detailColDefs) {
    const params = {
      mainColDefs: mainColDefs,
      detailColDefs: detailColDefs
    }

    const gridData = await VendorPacks_GenerateGridData(params);
    if (gridData) {
      console.log("vendor packs modal col defs: ", gridData.mainColDefs)
      this.columnDefs = gridData.mainColDefs
      this.detailColumnDefs = gridData.detailColDefs
    }
  }
  // On Grid Ready
  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    // Store the original settings to determine which rows have been updated
    this.og_selected = cloneDeep(this.gridOptions.rowData)
  }

  // Nested Detail Grid Configuration
  detailCellRendererParams = (parentParams) => {
    const colDefs = this.detailColumnDefs
    return {
      detailGridOptions: {
        ...DefaultDetailGridOptions,
        suppressRowClickSelection: true,
        context: {
          pageTitle: "Set Carton And Buy Parameters",
        },
        columnDefs: colDefs
      },
    }
  };

  updateVendorPacks(event) {
    // get all selected -> eligible rows
    const eligibleRows = event.api.getSelectedNodes().map(row => row.data.pack_id)

    this.gridApi.forEachNode(node => {
      const updated = this.og_selected.find(row => row.pack_id === node.data.pack_id)

      node.data.eligible = eligibleRows.includes(node.data.pack_id)
      if (updated.eligible !== node.data.eligible) {
        this.vendorPacksUpdated[updated.pack_id] = {...node.data, dim_id: this.parentData.dim_id}
      } else {
        delete this.vendorPacksUpdated[updated.pack_id]
      }
    });
  }

  // If a user modifies the eligibility update the vendor packs object. 
  onSave() {
    console.log("save data: ", this.vendorPacksUpdated)

    this.SCABP_Service.updateVendorPacks(Object.values(this.vendorPacksUpdated))
      .subscribe({
        next: (data) => { },
        complete: () => { this.ref.close() },
        error: (error) => handleError(this.dialogService, error, 'Error set carton and buy params: updating vendor packs')
      })
  }

  close() {
    console.log("close modal")
    this.ref.close()
  }
}
