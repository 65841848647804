// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { GetFormErrorMessage } from 'src/app/core/utils/form-error-messages';
import { IsKeyInObj } from 'src/app/core/utils/global-functions';


@Component({
  selector: 'app-add-size-recommendation',
  templateUrl: './add-size-recommendation.component.html',
  styleUrls: ['./add-size-recommendation.component.scss']
})
export class AddNewSizeModalComponent
  implements OnInit {

  modalTitle = "Add a New Size";
  addNewSizeForm: FormGroup;
  modelSizes: any[] = [];
  methodOptions = ["Flat Percentage", "Percent of Model"]

  newSizes: any[] = [];
  errorMessages = {}

  constructor(
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
  ) {
    console.log("init add new size modal config: ", config);
    const rowData = config.data.rowData
    this.modelSizes = rowData.filter(size => !IsKeyInObj(size, "userAddedSrc"));

    this.newSizes = config.data.context.sizeOrder.filter(sizeOrder => {
      return rowData.findIndex(modelSize => modelSize.sizeNm === sizeOrder.product_size ) === -1;
    })
  }

  ngOnInit(): void {
    this.addNewSizeForm = this.formBuilder.group({
      setMethod: [this.methodOptions[0], [Validators.required]],
      locked: [true, []],
      percentage: [null, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern(/^(([0-9]{1,2})?(\.[0-9]{1,2}0*)?|100)$/) // percents of the form 0 to 100, with up to two decimal places
      ]],
      modelSize: [{ value: null, disabled: true }, [Validators.required]],
      newSize: [null, [Validators.required]],
    })

    // Subscribe and monitor set Method Formcontrol
    this.addNewSizeForm.controls['setMethod'].valueChanges.subscribe(value => this.checkMethod(value))
  }
  // convenience getter for easy access to form fields
  get form() {
    return this.addNewSizeForm.controls;
  }
  checkMethod(method) {
    const emEvent = { emitEvent: false }

    if (method !== this.methodOptions[0]) {
      this.addNewSizeForm.controls['modelSize'].enable(emEvent);
      this.addNewSizeForm.controls['locked'].setValue(false);
    } else {
      this.addNewSizeForm.controls['modelSize'].setValue(null);
      this.addNewSizeForm.controls['modelSize'].disable(emEvent);
      this.addNewSizeForm.controls['locked'].setValue(true);
    }
  }

  setFormError(controlName){
    const params = {
      controlName: controlName,
      form:  this.addNewSizeForm,
      customMessages: {
        pattern: {
          percentage: "The value must be between 0 and 100, inclusive, and only have up to 2 decimal places.",
        }
      }
    }
    return GetFormErrorMessage(params)
  }

  onSubmitSize(formData) {
    console.log("form data: ", formData)
    console.log("this.newSizes: ", this.newSizes)
    console.log("this.model size: ", this.modelSizes)

    let newSize = this.newSizes.find(size => size.product_size === formData.newSize);
    const objParams = {
      sizeNm: newSize.product_size,
      locked: formData.locked,
      delete: 1, 
      modelSize: formData.modelSize || null,
      userAddedSrc: "UI",
      augmented_sales: 0,
      recoContribPct: 0,
      radc: 0,
      sales: 0
    }

    if (formData.locked) {
      return this.ref.close({
         ...objParams,
         final_c: formData.percentage / 100,
        })
    } else {
      const modelSize = this.modelSizes.find(size => size.sizeNm === formData.modelSize);
      // const total = (formData.percentage * modelSize.radc) / 100
      const total = (formData.percentage * modelSize.radc) / 100
      
      const radc = !isNaN(total) ? total : 0;
      return this.ref.close({ ...objParams, final_c: radc })
    }
  }

  close() {
    this.ref.close()
  }

}

