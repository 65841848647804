// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// Ag Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { FormatChartValuePair, CheckIsNumber, GenerateRandomColor } from 'src/app/core/utils/global-functions';
// Custom Imports
// =========================================================

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: `./run-test-scenarios-detail-grid.component.html`,
})

export class RunTestScenariosDetailGrid implements ICellRendererAngularComp {

  gridApi: GridApi;

  params;
  masterGridApi;
  rowId;
  defaultColDef;


  // Chart Variables
  lineChartData_1: any;
  lineChartOptions_1: any;

  lineChartData_2: any;
  lineChartOptions_2: any;

  barChartData: any;
  barChartOptions: any;

  constructor() { }

  // called on init
  agInit(params: any): void {
    this.params = params;
    console.log("Run Test Scenarios Detail Grid: ", params)
    this.masterGridApi = params.api;
    this.rowId = params.node.id;

    this.generateChartData(this.params.data.size_test_records)
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  async generateChartData(chartData) {
    console.log("chartData: ", chartData)
    let labels = []
    let chartColors = []
    const getColors = () => {
      const color = GenerateRandomColor(chartColors)
      chartColors.push(color)
      return color
    }
    let counter = 0;

    let lineData_1 = {
      current_raw_sales: {
        label: 'Current Sales',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      current_badc: {
        label: 'Current BADC',
        // borderColor: 'rgb(99, 189, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      current_radc: {
        label: 'Current RADC',
        // borderColor: 'rgb(99, 132, 189)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      proposed_raw_sales: {
        label: 'Proposed Sales',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      proposed_badc: {
        label: 'Proposed BADC',
        // borderColor: 'rgb(99, 189, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      proposed_radc: {
        label: 'Proposed RADC',
        // borderColor: 'rgb(99, 132, 189)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      }
    }
    let lineData_2 = {
      current_buy: {
        label: 'Current Buy',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
      proposed_buy: {
        label: 'Proposed Buy',
        // borderColor: 'rgb(99, 189, 132)',
        borderColor: getColors(),
        backgroundColor: 'rgba(0, 0, 0, 0.0)',
        data: []
      },
    }
    let barData =
    {
      currentError: {
        label: 'Current Error',
        order: 1,
        // borderColor: 'rgb(99, 189, 132)',
        // backgroundColor: 'rgb(99, 189, 132)',
        backgroundColor: getColors(),
        data: []
      },
      proposed_error: {
        label: 'Proposed Error',
        order: 2,
        // borderColor: 'rgb(99, 132, 189)',
        // backgroundColor: 'rgb(99, 132, 189)',
        backgroundColor: getColors(),
        data: []
      }
    }

    chartData.map((pct, i) => {
      counter++;

      console.log("pct: ", pct)
      // Set the labels array
      if (!labels.includes(pct.size_nm)) labels.push(pct.size_nm);

      // Update the Bar Chart Data object
      barData.currentError.data.push(FormatChartValuePair(pct.current_error))
      // barData.currentError.backgroundColor
      barData.proposed_error.data.push(FormatChartValuePair(pct.proposed_error))

      // Update Line Chart ( 1 ) Data object
      lineData_1.current_raw_sales.data.push(CheckIsNumber(pct.current_sales))
      lineData_1.current_badc.data.push(CheckIsNumber(pct.current_badc))
      lineData_1.current_radc.data.push(CheckIsNumber(pct.current_radc))
      lineData_1.proposed_raw_sales.data.push(CheckIsNumber(pct.proposed_sales))
      lineData_1.proposed_badc.data.push(CheckIsNumber(pct.proposed_badc))
      lineData_1.proposed_radc.data.push(CheckIsNumber(pct.proposed_radc))

      // Update Line Chart ( 2 ) Data object
      lineData_2.current_buy.data.push(CheckIsNumber(pct.current_buy))
      lineData_2.proposed_buy.data.push(CheckIsNumber(pct.proposed_buy))
    })

    const reBalance = async (chartData) => Object.entries(chartData).map(([key, value]) => {
      let finalTotal = 0;
      let totalSum = value['data'].reduce((a, b) => a + b);
      const ratio = 100 / totalSum;

      value['data'].forEach((val, i) => {
        const totalVal = parseFloat((val * ratio).toFixed(2))
        finalTotal += totalVal
        value['data'][i] = totalVal

        if (i + 1 === value['data'].length) {
          console.log("total value: ", finalTotal)
          return finalTotal
        }
      })
    })

    if (counter === chartData.length) {
      // generate the grid options
      const res_1 = await reBalance(lineData_1);
      const res_2 = await reBalance(lineData_2);

      Promise.all([res_1, res_2]).then(r => {
        this.generateLineChart_1(Object.values(lineData_1), labels)
        this.generateLineChart_2(Object.values(lineData_2), labels)
        this.generateBarChart(Object.values(barData), labels)
      })
    }
  }

  generateLineChart_1(data, labels) {
    this.lineChartData_1 = {
      datasets: data.filter(e => e),
    }
    this.lineChartOptions_1 = {
      title: {
        display: true,
        text: 'Size Curve (%)'
      },
      animation: {
        duration: 0
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            beginAtZero: true
          },
        },
        x: {
          type: 'category',
          labels: labels.sort((a, b) => a - b),
        },
      },
      plugins: {
        tooltip: {
          mode: 'point',
          intersect: false,
          axis: 'y',
          position: 'nearest',
          backgroundColor: '#ffffff',
          borderWidth: 2,
          displayColors: false,
          titleColor: "#4e4e4e",
          borderColor: "#011c26",
          padding: 10,
          callbacks: {
            title: (context) => {
              console.log("title: ", context)
              return `Size: ${context[0].label}`
            },
            label: (context) => {
              console.log("label: ", context)
              console.log("this.lineChartData: ", this.lineChartData_1)
              return `${context.dataset.label}: ${context.formattedValue}`
            },
            labelTextColor: (tooltipItem, chart) => {
              return this.lineChartData_1.datasets[tooltipItem.datasetIndex].borderColor
            },
          }
        }
      }
    }
  }
  generateLineChart_2(data, labels) {
    console.log("chart labels: ", labels)
    console.log("data: ", data)

    this.lineChartData_2 = {
      datasets: data.filter(e => e),
    }
    this.lineChartOptions_2 = {
      title: {
        display: true,
        text: 'Buy Quantity (%)'
      },
      animation: {
        duration: 0
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            beginAtZero: true
          },
        },
        x: {
          type: 'category',
          labels: labels.sort((a, b) => a - b),
        },
      },
      plugins: {
        tooltip: {
          mode: 'point',
          intersect: false,
          axis: 'y',
          position: 'nearest',
          backgroundColor: '#ffffff',
          borderWidth: 2,
          displayColors: false,
          titleColor: "#4e4e4e",
          borderColor: "#011c26",
          padding: 10,
          callbacks: {
            title: (context) => {
              // console.log("title: ", context)
              return `Size: ${context[0].label}`
            },
            label: (context) => {
              // console.log("label: ", context)
              // console.log("this.lineChartData: ", this.lineChartData)
              // return `${this.lineChartData_2.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel}%`

              return `${context.dataset.label}: ${context.formattedValue}%`
            },

            labelTextColor: (tooltipItem, chart) => {
              return this.lineChartData_2.datasets[tooltipItem?.datasetIndex].borderColor
            },
          }
        }
      }
    }
  }

  generateBarChart(data, labels) {
    console.log("x labels: ", labels)
    this.barChartData = {
      datasets: data.filter(e => e),
    }
    this.barChartOptions = {
      title: {
        display: true,
        text: 'Buy Error'
      },
      animation: {
        duration: 0
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          type: 'category',
          labels: labels,
        },
        //   xAxes: [{
        //     gridLines: {
        //       color: function(context) {
        //         if(context.tick.value < 0) {
        //           return "blue";
        //         }
        //         return "green"
        //       }
        //       // color: "blue"
        //     },
        //     ticks: {
        //       callback: function(value, index, values) {
        //         return (value == 0) ? "0" : null;
        //       }
        //     }
        //   }]
      },
      plugins: {
        tooltip: {
          mode: 'point',
          intersect: false,
          axis: 'y',
          position: 'nearest',
          backgroundColor: '#ffffff',
          borderWidth: 2,
          displayColors: false,
          titleColor: "#4e4e4e",
          borderColor: "#011c26",
          padding: 10,
          callbacks: {
            title: (context) => {
              console.log("title: ", context)
              return `Size: ${context[0].label}`
            },
            label: (context) => {
              return `${this.barChartData.datasets[context.datasetIndex].label}: ${context.formattedValue}`
            },
            labelTextColor: (tooltipItem, chart) => {
              return this.barChartData.datasets[tooltipItem.datasetIndex].borderColor
            },
          }
        }
      }
    }
  }
}