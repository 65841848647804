
//  ----------------------------
//  Global Formatters
//  ----------------------------

import moment from 'moment';
import { IsKeyInObj, FormatKey, GetValue } from 'src/app/core/utils/global-functions';

export const GenerateDefaultColDefs = (colKey, colVal) => {
    // console.log("colKey: ", colKey)
    // console.log("colVal: ", colVal)

    const getType = (dataType) => colVal.refData.dataType.includes(dataType)
    const dataType = colVal['refData'].dataType
    const actionCol = getType("open-modal") || getType("action-button") || getType("checkbox")
    const colId = FormatKey(colKey)
    const field = colVal['refData'].customField || colId
    const headerName = FormatHeaderCell(dataType, colKey)

    return {
        headerName: headerName,
        headerTooltip: headerName,
        headerCheckboxSelection: dataType === 'checkbox' && !colVal['refData']['displayHeaderName'],
        field: field,
        colId: colId,
        type: SetCellType(dataType, colVal['editable'], colVal.aggFunc),
        suppressMenu: actionCol,
        sortable: !actionCol,
        width: colVal?.refData?.colSize ? colVal.refData.colSize : 100,
        flex: colVal?.refData?.colSize ? 0 : 1,
        ...ToolPanelConfig(actionCol, dataType),
        ...GetToolTipText(colVal),
        ...colVal,
    }
}
export const FormatHeaderCell = (dataType, header) => {
    const h = header.toUpperCase()
    if (dataType === "checkbox") return ''
    switch(dataType) {
        case "checkbox":
        return '';
        case "percentage":
        return h + " (%)";
        case "currency":
        return h + " ($)";
        default:
        return h
    }
}

// Set Cell Type array
export const SetCellType = (dataType, editable, aggFunc) => {
    const getFormatter = () => {
        const type = dataType.includes('|') ? dataType.split('|')[0] : dataType
        const getNumericCellTypes = (numFormatter) => [numFormatter,  editable ? 'editableNumValues' : null].filter(t => t !== null)
        switch (type){
            case "number":
                return getNumericCellTypes('numberFormatter');
            case "percentage":
                return getNumericCellTypes('percentageFormatter')
            case "currency":
                return getNumericCellTypes('currencyFormatter')
            case "string":
                return ['stringFormatter'];
            case "string-delimiter":
                return ['stringDelimiterFormatter']
            case "date":
                return ['dateFormatter'];
            case "checkbox":
                return ['checkboxFormatter'];
            case "open-modal":
                return ['buttonFormatter'];
            case "action-button":
             return ['buttonFormatter'];
            case "dropdownSelect":
                return ['dropdownFormatter'];
            case 'selectionAutoComplete':
                return ['selectionAutoCompleteFormatter']
            default:
                return ['stringFormatter']
        }
    }
    let typeArr = [...getFormatter()];

    if (aggFunc === 'customAverage') typeArr.push('customAverage');

    return typeArr;
};

// Set Tooltip text
export const GetToolTipText = (col) => {
    if (col.refData.showToolTip && col.refData.tooltipField) return {tooltipField: col.refData.tooltipField};
    return {}
}
// Configure filter and column tool panels
export const ToolPanelConfig = (isActionCol, dataType) => {
    // Columns to hide
    if(isActionCol) return {
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
    }
   // Set filter values
   if (dataType !== 'date') {
    return {
        filterValueGetter: params => {
            if (params.data){
                const val = params.data[params.colDef.field];
                return typeof val === 'string' ?  val : `${GetValue(params.data[params.colDef.field])}`};
            return `${params.node.key}`;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
            debounceMs: 200,
            newRowsAction: 'keep',
            valueFormatter: (params) => {
                switch (dataType) {
                    case "number":
                        return FormatNumber(params)
                    case "percentage":
                        return FormatPercentage(params)
                    case "currency":
                        return FormatCurrency(params)
                        case "date":
                    return FormatDate(params)
                    case "string":
                    default:
                        return params.value
                }
            },
            comparator: (a, b) =>  a - b
        }
    }
} else {
    return {}
}
}
// Number Formatter
export function FormatNumber(params) {
	if (IsKeyInObj(params, "value")) {
		if (params.value === null) return params.value = "--"

		const number = typeof params.value === "object" ? params.value["value"] : params.value ;

        if (params) {
            if (params?.colDef?.refData?.is_whole_num) {
                return Math.round(number)
            } else if (isFinite(number)) {
				// Display up to two decimal places
				const decimalAdjust = (value, exp) => {
					value = +value;
					exp = +exp;
					// Shift
					value = value.toString().split('e');
					value = Math.floor(
						+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp))
					);
					// Shift back
					value = value.toString().split('e');
					return +(value[0] + 'e' + exp);
				};
				if (params.colDef.dataType === "percentage"){
					return decimalAdjust(number, -4)
					.toString()
				}
				else {
					return decimalAdjust(number, -2)
					.toString()
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
				}

			} else if (isNaN(number) && params.value !== '--' && params.value !== null) {
				return '0';
			} else {
				return '--';
			}
		} else {
			return '0';
		}
	}

}
// Percentage Formatter
export function FormatPercentage(params) {
	const value = FormatNumber(params)

	return value !== '--' ? (Math.abs(Number(value))).toFixed(2) + '%' : value;
}
// Number Formatter
export function FormatCurrency(params) {
	let number = typeof params.value === "object" ? params.value["value"] : params.value ;

	return number !== '--' && number !== null ? '$' + FormatNumber(params) : number === '--' ? number : null;
}
// String Formatter
export function FormatString(params) {
    if (params.value) return params.value
    else return '--'
}
// Date Formatter
export function FormatDate(params) {
    // console.log("format date: ", params)
    const dateConfig = params?.colDef?.refData?.dateFormat || null
    let dateFormat;
    if (typeof params.value === 'number') {
        // 12/05/2022
        dateFormat = dateConfig || 'L'
        const date = moment(params.value).format(dateFormat)
        return date === 'Invalid date' ? '--' : date
    } else if (typeof params.value === 'string') {
        if (dateConfig === 'string') {
            return params.value
        } else {
            dateFormat = dateConfig|| 'lll'
            const date = moment(params.value).format(dateFormat)
            return date === 'Invalid date' ? '--' : date
        }
    } else {
        return '--'
    }
}


