import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormatKey } from './global-functions';

export const CheckForDuplicates = (valuesNotAllowed): ValidatorFn => {

    return (control: AbstractControl) : ValidationErrors | null => {
        const value = control.value !== null ? FormatKey(control.value) : null;

        if (!value) {
            return null;
        }

        const uniqueValue = !valuesNotAllowed.includes(value)

        return !uniqueValue ? { duplicateValue: true }: null;
    }
}