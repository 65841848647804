<app-modal-header
    [modalTitle]="modalTitle"
	[showCloseBtn]="true"
	(closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content pack-sum-modal">
    <p-table [value]="configData" class="table">
        <ng-template pTemplate="header">
            <tr>
                <th>Sizes</th>
                <th>Qty</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
            <tr>
                <td align="center">{{product.product_size}}</td>
                <td align="center">{{product.inner_carton_qty}}</td>
            </tr>
        </ng-template>
         <!-- Total -->
         <ng-template pTemplate="summary" >
         <span>  Total Qty: {{total}} </span>
        </ng-template>
    </p-table>
</div>
<app-modal-footer [showUpdateBtn]='false' (closeModal)="close()"></app-modal-footer>
