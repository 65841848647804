// Angular Imports
// =========================================================
import { Component } from '@angular/core';
import { cloneDeep } from "lodash";
// Ag Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellValueChangedEvent, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';// Prime NG Imports
// Prime NG Imports
// =========================================================
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
// Custom Imports
// =========================================================
import { PackSettingsData } from "src/app/core/utils/types";
import { RunTestScenariosComponent } from 'src/app/components/03_modals/set-carton-and-buy-params/run-test-scenarios/run-test-scenarios.component';
import { SetCartonAndBuyParamsService } from 'src/app/core/services/db/set-carton-and-buy-params.service';
import { handleError } from 'src/app/core/utils/error-handling';
import { FormatKey, IsKeyInObj } from 'src/app/core/utils/global-functions';
import { DataService } from 'src/app/core/services/data.service';
import { Subscription } from 'rxjs';
import { API, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: `./set-carton-and-buy-params-detail-grid.component.html`,
})

export class SetCartonAndBuyParamsDetailGrid implements ICellRendererAngularComp {

  gridApi: GridApi;
  gridOptions: any = {};
  // Grid Data

  params;
  masterGridApi;
  componentParent;

  rowId;
  defaultColDef;
  objectKeys = Object.keys
  // Global Variables
  ref: DynamicDialogRef;
  origSettings = [];
  // variable to update row styling dynamically
  rowValueUpdated: any = {};
  dims_updated: any[] = []

  messages: Subscription;
  stateMachine: string = null

  runTestCaseScenarioDisabled = true

  existingAttrFam = []

  constructor(
    public dialogService: DialogService,
    private SCABP_Service: SetCartonAndBuyParamsService,
    private dataService: DataService
  ) { }
  // called on init
  agInit(params: any): void {

    console.log("Set Carton And Buy Params Detail Grid: ", params)
    this.params = params;
    this.masterGridApi = params.api;
    this.rowId = params.node.id;
    this.componentParent = params.context.componentParent
    this.runTestCaseScenarioDisabled = !params.data.test_case_total

    console.log("button disabled", this.runTestCaseScenarioDisabled)

    this.rowValueUpdated = this.componentParent.changesPromoted || {}
    this.dims_updated = Object.values(this.rowValueUpdated).map(row => row['dim_id'])

    this.gridOptions = {
      rowData: params.data.settings,
      columnDefs: params.detailGridOptions.columnDefs,
      ...params.detailGridOptions,
      context: this,
      onCellValueChanged: (event) => this.updatedCellStyling(event),
    }

    this.existingAttrFam = params.data.settings
      .filter(obj => obj.setting_name.includes('Attribute Family'))
      .map(obj => obj.setting_value)
      .filter(obj => obj); // for null values
    console.log('this.existingattrfam', this.existingAttrFam)
    this.dataService.connect();
    this.messages = this.dataService.messages$.subscribe((message: any) => {
      console.log('received message from server: ', message);
      if (message?.error) {
        this.ref.close();
        this.gridApi.redrawRows();
        handleError(this.dialogService, {statusText: "Error processing state machine", status: 404}, `State Machine: ${this.stateMachine}. Error: ${message.error}`)
      } else {

      }
    });
  }

  updatedCellStyling(event: CellValueChangedEvent) {
    console.log("cell val changed: ", event);
    if (event.data.setting_name.includes('Attribute Family')) {
      if (this.existingAttrFam.includes(event.newValue)) {
        alert('same value')
        event.node.setDataValue('value', event.newValue)
        event.node.data.error = true

        this.params.data.settings = this.gridOptions.rowData;
        this.params.node.setData(this.params.data)
        this.masterGridApi.redrawRows();
        return;
      }
    }

    if (
      FormatKey(event.newValue) === FormatKey(event.data.og_val) ||
      FormatKey(event.newValue) === FormatKey(event.oldValue)
    ) {
      delete this.params.context.componentParent.changesPromoted[event.node.id];
      this.params.data.highlight = this.dims_updated.includes(this.params.data.dim_id) && this.params.data.test_executed === false
      this.masterGridApi.redrawRows();
    } else {
      // this.params.data.highlight = true
      this.params.data.test_executed = false
      // Update the value changes
      this.params.context.componentParent.changesPromoted[event.node.id] = {
        new_val: FormatKey(event.newValue),
        setting_name: event.data.setting_name,
        dim_id: this.params.data.dim_id
      };
      if (event.oldValue !== event.newValue) {
        event.node.setDataValue('value', event.newValue)
        this.params.data.settings = this.gridOptions.rowData;
        this.params.node.setData(this.params.data)
        this.masterGridApi.redrawRows();
      }
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    let gridInfo = {
      id: this.rowId,
      api: event.api,
      columnApi: event.columnApi,
    };

    console.log('adding detail grid info with id: ', this.rowId);
    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }
  //   TODO: check functionality
  promoteChanges(row: PackSettingsData, node: RowNode, location: string) {
    console.log("promoting  changes")
    // console.log("row: ", row)
    // console.log("node: ", node)
    // console.log("dim id: ", this.params.node.data.dim_id)
    // console.log("this.rowValueUpdated: ", this.rowValueUpdated)
    const promote = () => this.SCABP_Service.promoteChanges({ dim_id: this.params.node.data.dim_id, location: location }).subscribe({
      next: (data) => { console.log("settings Promoted: ", data) },
      complete: () => {
        this.params.context.componentParent.subscribeToRowDataById(this.params.node.data.dim_id);
        if (location === "modal") return this.ref.close();
      },
      error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: row data')
    });
    console.log("location: ", location)

    if (location === "grid") {
      const updateParamSettings = () => this.SCABP_Service.updateParameterSettings(Object.values(this.rowValueUpdated))
        .subscribe({
          next: (data) => { },
          complete: () => {
            promote()
          },
          error: (error) => handleError(this.dialogService, error, 'Error updating parameter settings')
        })

      if (!this.params.data.test_executed) {
        this.SCABP_Service.checkTestStatus({ dim_id: this.params.data.dim_id })
          .subscribe({
            next: (data) => { console.log("test checked") },
            complete: () => { updateParamSettings() },
            error: (error) => handleError(this.dialogService, error, 'Error checking test status')
          })
      } else {
        updateParamSettings()
      }
    }


    if (location === "modal") {
      promote()
    }
  }

  async runTestCaseScenario(row: PackSettingsData, node: RowNode) {
    console.log("packSettings: ", row)
    this.params.data.test_executed = true
    this.params.data.highlight = false
    this.params.data.error = false

    // TODO: removed sql -> data is transferring through the payload
    // this.SCABP_Service.runTestScenariosInsertJob(this.params.data.dim_id)
    // .subscribe({
    //   next: (data) => { console.log("Record inserted") },
    //   complete:  async () => {
    const apiName = 'OrchestratorApiGateway';
    const path = '/task';
    const initBody = {
      body: {
        stateMachineName: `sizeui-orchestrator-service-${environment.stage}-RunTestScenarios`,
        identityId: this.dataService.identityId,
        payload: { dim_id: this.params.data.dim_id }
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
    };

    await API.post(apiName, path, initBody).then((response) => {
      this.stateMachine = "runTest"
      console.log("Run Test Orchestrator Call Complete: ", response);
      this.openTestCaseResultsModal(row, node);
    }).catch((error) => {
      console.log("Error Running Run Test Orchestrator: ", error);
      handleError(this.dialogService, error, 'Error Running Run Test Orchestrator')
    });

    //   },
    //   error: (error) => handleError(this.dialogService, error, 'Error Removing Test Results')
    // })
  }
  openTestCaseResultsModal(row: PackSettingsData, node: RowNode) {

    // TODO: do not delete
    this.ref = this.dialogService.open(RunTestScenariosComponent, {
      showHeader: false,
      styleClass: 'g-large-modal',
      dismissableMask: false,
      data: {
        onPromoteChanges: () => this.promoteChanges(row, node, 'modal'),
        row: row,
        updatedRows: Object.values(this.rowValueUpdated),
        onDiscardChanges: () => this.discardChanges(row, node),
      },
    });

    this.ref.onClose.subscribe((data) => {
      this.SCABP_Service.removeTestResults({ dim_id: this.params.data.dim_id })
        .subscribe({
          next: (data) => { console.log("tests removed") },
          complete: () => {
            this.masterGridApi.redrawRows();
          },
          error: (error) => handleError(this.dialogService, error, 'Error Removing Test Results')
        })
    })
  }

  discardChanges(row: PackSettingsData, node: RowNode) {
    console.log("discardChanges", node);

    this.gridOptions.rowData.map(row => { row.setting_value = row.og_val })
    this.params.node.setData(this.params.data)
    this.masterGridApi.redrawRows();
    this.componentParent.resetRowData(this.params)
    this.ref.close();
  }

  ngOnDestroy(): void {
    // detail grid is automatically destroyed as it is an Angular component
    console.log('removing detail grid info with id: ', this.rowId);
    // this.params.data.highlight = false;
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  }
}