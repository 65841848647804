// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// AG Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'status-component',
  templateUrl: './status-panel-renderer.html',
  styleUrls: ['./status-panel-renderer.scss'],
})

export class StatusPanelRenderer implements ICellRendererAngularComp {
  private params: IStatusPanelParams;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    console.log("status panel: ", params)
  //   // console.log("total rows: ", this.params.api.getDisplayedRowCount())
  //   params.api.addEventListener('gridReady', () => {        // Or the firstDataRendered event
  //     console.log(this.params.api.getModel().rowsToDisplay);   // Rows will now be available
  // });
  }

  onClick(): void {
    alert('Selected Row Count: ' + this.params.api.getSelectedRows().length);
  }

  refresh(): boolean {
    return false;
  }
}
