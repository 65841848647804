<div style="row-gap: 20px" class="detail-grid-container">
  <div
    style="
      display: flex;
      column-gap: 10px;
      justify-content: center;
      max-height: 340px;
    "
  >
    <div style="flex: 2; padding: 0px" class="mat-card">
      <ag-grid-angular
        #agGrid
        style="height: 100%; width: 99.6%"
        class="detail-grid-template ag-theme-alpine"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
      >
      </ag-grid-angular>
    </div>
    <div style="flex: 1; padding: 0px" class="mat-card">
      <p-chart
        class="chart"
        type="line"
        model="chartView.horizontalBarModel"
        [data]="lineChartData"
        [options]="lineChartOptions"
      >
      </p-chart>
    </div>
  </div>
  <div class="g-detail-grid-button-container">
    <button
      mat-raised-button
      class="submit-btn"
      (click)="openAddNewSizeModal($event)"
    >
      Add New Size
    </button>
    <button
      mat-raised-button
      class="submit-btn"
      (click)="params.context.saveSizeOffering(params.node)"
    >
    Save To Future Season
    </button>
  </div>
</div>
