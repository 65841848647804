<div class="container">
    <div class="container" [ngClass]="{'filter-padding' : !isOpen}">
        <div [@filterPanel]="isOpen ? 'open' : 'closed'" class='side-panel'>
            <div style="display: flex; justify-content: flex-end">
            <button aria-label="Close Filter Panel" class="close-btn" mat-icon-button (click)="toggle()">
                <span class="material-icons" aria-label="Close Filter Panel">close</span>
            </button>
            </div>
            <div class="filter-pane">
                <ng-content></ng-content>
            </div>
        </div>
        <div class="main-body nav-tabs">
            <!-- Filter Panel Trigger Buttons -->
            <div class="panel-container">

                <div style="position: absolute; z-index: 8">
                    <button
                    aria-label="Filter Options"
                    title="Filter"
                    [@filterBtn]="isOpen ? 'open' : 'closed'"
                    [ngClass]="{'filter-btn' : true}"
                    mat-icon-button
                    [disabled]="false"
                    (click)="toggle()"
                    >
                    <span *ngIf="!isOpen" [ngClass]="{'material-icons' : true}" aria-label="Open Filter Panel">filter_list</span>
                    </button>
                </div>
            </div>
        </div>
  </div>