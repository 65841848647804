<app-modal-header
    [modalTitle]="modalTitle"
	[showCloseBtn]="true"
	(closeModal)="close()"
></app-modal-header>

<mat-tab-group class="user-input modal-content">
    <mat-tab *ngFor="let tab of data; let index = index" [label]="tab.setting_group">
        <form id="configurationForm" #configurationForm="ngForm">
            <div *ngFor="let input of tab.settings">

                <span *ngIf="input.data_type === 'boolean'" class="p-grid align-center">
                    <div class="p-col-8 p-all-0 p-all-0 checkbox-field">
                        <mat-form-field appearance="standard">
                            <mat-label for="{{input.setting_name}}" class="p-col-4 p-all-0 col-left">{{input.setting_name}}: </mat-label>
                            <input matInput hidden="true" />
                            <mat-checkbox matInput type="text" name="{{input.setting_name}}" class="text-center" [(ngModel)]="input.setting_value"></mat-checkbox>
                        </mat-form-field>
                    </div>
                </span>
                <span *ngIf="input.data_type === 'string'" class="p-grid align-center">
                    <div class="p-col-8 p-all-0">
                        <mat-form-field appearance="standard">
                            <mat-label>{{input.setting_name}}</mat-label>
                            <input matInput type="text" name="{{input.setting_name}}" class="text-center" [(ngModel)]="input.setting_value">
                        </mat-form-field>
                    </div>
                </span>
                <!-- <span *ngIf="input.data_type === 'date'" class="p-grid align-center">
                    <div class="p-col-8 p-all-0">
                        <mat-form-field appearance="standard">
                            <mat-label>{{input.setting_name}}</mat-label>
                            <input matInput [matDatepicker]="picker" class="text-center" placeholder="Choose a date"
                            name="date" formControlName="date" [(ngModel)]="input.setting_value ? setDateValue(input.setting_value) : 'null'">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="date" #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </span> -->
                <span *ngIf="input.data_type === 'integer' || input.data_type === 'number'" class="p-grid align-center">
                    <div class="p-col-8 p-all-0">
                        <mat-form-field appearance="standard">
                            <mat-label>{{input.setting_name}}</mat-label>
                            <input matInput type="number" name="{{input.setting_name}}" class="text-center" [(ngModel)]="input.setting_value">
                        </mat-form-field>
                    </div>
                </span>
                <span *ngIf="input.data_type === 'float'" class="p-grid align-center">
                    <div class="p-col-8 p-all-0">
                        <mat-form-field appearance="standard">
                            <mat-label>{{input.setting_name}}</mat-label>
                            <input matInput type="number" step="0.01" name="{{input.setting_name}}" class="text-center" [(ngModel)]="input.setting_value">
                        </mat-form-field>
                    </div>
                </span>
            </div>

        </form>
    <!-- </div> -->

    </mat-tab>

  </mat-tab-group>

<app-modal-footer [showUpdateBtn]='true' (update)="submit()" (closeModal)="close()"></app-modal-footer>