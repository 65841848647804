// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
import { RowPositionUtils } from 'ag-grid-community';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { FormatKey, GenerateRandomColor } from 'src/app/core/utils/global-functions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-totals-charts',
  templateUrl: './store-totals-charts.component.html',
  styleUrls: ['./store-totals-charts.component.scss']
})

export class StoreTotalsChartsComponent implements OnInit {

  modalTitle = "Size Breaks";

  data: any;
  options: any;
  sizes = [];
  sizeDataPoints: any[] = [];
  // level: number = 0;

  threshold = 0.5;

  colors = {
    "bad_c": [
      '255,0,0', '255,128,0' // unused
    ],
    "rad_c": [
      environment.colors.chartDarkBlueRGB, environment.colors.chartGreenRGB
    ],
    "sales": [
      environment.colors.chartLightBlueRGB, environment.colors.chartPinkRGB
    ]
  }
  cellParams: any;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    console.log("View Store Totals: ", this.config.data);
    this.sizes = this.config.data.sizes;
    this.cellParams = this.config.data.params

    this.calcChartData(this.config.data.selectedRows);
  }
  // Function to calculate the bar chart data points
  calcChartData(data) {
    const chartData = []
    const row = data[0]
    const key = this.cellParams.node.group ? this.cellParams.node.key.split('|') : this.cellParams.node.id.split('|');
    const levels = typeof key === 'string' ? [key] : key.filter(key => isNaN(Number(key)))
    const refData = this.config.data.extraData
    const channel = levels.length > 1 ? typeof row.channel === 'string' ? row.channel : row.channel[0] : 'Total';

    const setChartData = (j: number, data: any, metric: string, channel: string, label: string) =>  chartData.push({
      ...this.getChartItem({
        data: data,
        j: j,
        metric: metric,
        label: `${channel} - ${label}`
      })
    });
    let channelBadC = [];
    let channelRadC = [];
    let channelSales = [];
    console.log("channel", channel)
    console.log("extradata", refData)

    switch (levels.length) {
      case 1:
        Object.keys(refData).forEach((channel, j) => {

          channelBadC = [];
          channelRadC = [];
          channelSales = [];
          this.sizes.forEach(size => {
            const sizeKey = FormatKey(size)
            const c = FormatKey(channel)

            // channelBadC.push({
            //   x: size,
            //   y: refData[c][sizeKey].bad_c
            // });
            channelRadC.push({
              x: size,
              y: refData[c][sizeKey].rad_c * 100
            });
            channelSales.push({
              x: size,
              y: refData[c][sizeKey].sales_pct * 100
            });
          });

          // setChartData(j, channelBadC, 'bad_c', channel, 'bad_c')
          setChartData(j, channelRadC, 'rad_c', channel, 'contrib')
          setChartData(j, channelSales, 'sales', channel, 'sales')
        });
        break;
      case 2:
        console.log("LEVEL 2: ", levels)
        this.sizes.forEach(size => {
          const sizeKey = FormatKey(size)
          const c = FormatKey(channel)

          // channelBadC.push({
          //   x: size,
          //   y: refData[c][sizeKey].bad_c
          // });
          channelRadC.push({
            x: size,
            y: refData[c][sizeKey].rad_c * 100
          });
          channelSales.push({
            x: size,
            y: refData[c][sizeKey].sales_pct * 100
          });
        });
        // setChartData(0, channelBadC, 'bad_c', channel, 'bad_c')
        setChartData(0, channelRadC, 'rad_c', channel, 'contrib')
        setChartData(0, channelSales, 'sales', channel, 'sales')

        break;
      case 3:
        console.log("LEVEL 3: ", levels)
        let storeRadC = []

        this.sizes.forEach(size => {
        const sizeKey = FormatKey(size)

          storeRadC.push({
            x: size,
            y: row[sizeKey] * 100
          });
          channelRadC.push({
            x: size,
            y: refData[FormatKey(row.channel)][sizeKey].rad_c * 100
          });
        });
        setChartData(0, channelRadC, 'rad_c', channel, 'contrib')
        setChartData(0, storeRadC, 'sales', this.cellParams.data.location_id, 'contrib')
        break;
    }


    // const chartData_old = data.map((store, i) => {
    //   let dataPoints = [];
    //   const sId = store.store

    //   for (let key in store) {
    //     let value = store[key];
    //     if (this.sizes.includes(key.toUpperCase())) dataPoints.push({ x: key.toUpperCase(), y: Number(value) });
    //   }
    //   const lineColor = GenerateRandomColor(chartColors)

    //   chartColors.push(lineColor)
    //   return {
    //     label: sId === "All Store Totals" ? sId : `Store ${sId}`,
    //     order: i,
    //     hoverBackgroundColor: '#011C26',
    //     borderColor: sId === "All Store Totals" ? '#026873' : lineColor,
    //     padding: '20px',
    //     data: dataPoints,
    //     borderWidth: 1,
    //     fill: false,
    //     tension: 0.1
    //   }
    // })
    console.log("chartData: ", chartData)
    this.updateChart(chartData)
  }

  getChartItem({data, j, metric, label}) {

    return {
      label: label,
      order: j,
      backgroundColor: this.bgColor(this.colors[metric][j % 2]),
      borderColor: this.borderColor(this.colors[metric][j % 2]),
      padding: '20px',
      data: data,
      borderWidth: 1,
      fill: false,
      tension: 0.1
    }
  }


  bgColor(rgb: string) {
      return `rgba(${rgb}, 0.25)`
  }

  borderColor(rgb: string) {
    return `rgba(${rgb}, 1)`
  }

  updateChart(chartData) {
    console.log("labels: ", this.sizes)
    this.data = {
      datasets: chartData.filter(e => e),
    }
    this.options = {
      indexAxis: 'y',
      hover: {
        // Overrides the global setting
        mode: 'point',
        axis: 'x',
        intersect: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: false,
      },
      legend: {
        position: 'top',
        align: 'center', // start or end
      },
      scales: {
        x: {
          type: 'category',
          labels: this.sizes,
        },
        y: {
          ticks: {
            padding: 10,
            callback: function (value) {
              return `${value.toFixed(0)}%`;
            }
          },
          type: 'linear',
          position: 'left',
        }
      },
      plugins: {
        tooltip: {
          // mode: 'point',
          // intersect: false,
          // axis: 'y',
          // // backgroundColor: "#f3f3f3",
          // backgroundColor: '#ffffff',
          // borderWidth: 2,
          // displayColors: false,
          // titleFontColor: "#4e4e4e",
          // padding: 10,
          callbacks: {
            // title: (tooltipItems, data) => {
            //   console.log("CALLIUNG TITLE")
            //   let title = data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x
            //   tooltipItems.xLabel = title
            //   tooltipItems.label = title
            //   return `Size: ${title}`
            // },
            title: (context) => {
              console.log("title context", context)
              return context[0].formattedValue
            },
            label: (context) => {
              console.log("tooltip items: ", context)
              // console.log("data: ", data)
              return parseFloat(context.label).toFixed(2) + '%';// `${chartData[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel}`
            },

            // labelTextColor: (tooltipItem, chart) =>  {
            //   // console.log("chart: ", chart);
            //   // console.log("tooltipItem: ", tooltipItem);
            //   chart.tooltip._model.borderColor = chartData[tooltipItem.datasetIndex].borderColor
            //   return chartData[tooltipItem.datasetIndex].borderColor},
          }
        }
      }

    }
  }

  close() {
    this.ref.close();
  }

}
