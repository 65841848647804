import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"

export interface RTS_GridDataParams {
    mainColDefs: any[];
}

export async function RTS_GenerateGridData(params: RTS_GridDataParams ) {
    const { mainColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {
          
            let obj = {
                ...GenerateDefaultColDefs(colKey, colVal),
            }
            Object.assign(mappedCols, { 
                [obj.colId]:  RTS_customCellFormatting(obj, gridToRender)
            })
        })

        return Object.values(mappedCols)
    }


    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")

        let results = await Promise.all([mainCols]);
        const m = results.indexOf(mainCols)

        const data = {
            mainColDefs: results[m],
        }
        return data
    }
    return getResults()

}

export const RTS_customCellFormatting = (col, gridToRender) => {
    let updatedCol = col

    return updatedCol

}