// Angular Imports 
// =========================================================
import { Component, OnInit } from '@angular/core';
import { trigger, transition, state, animate, style } from '@angular/animations';

// Custom Imports 
// =========================================================

@Component({
selector: 'app-filter-sidenav',
templateUrl: './filter-side-panel-trigger.component.html',
styleUrls: ['./filter-side-panel-trigger.component.scss'], 
providers: [],
animations: [
  trigger('filterPanel', [
    state('open', style({
      width: '300px',
      marginRight: '30px',
      opacity: 1,
    })),
    state('closed', style({
      width: '0px',
      opacity: 0.5,
    })),
    transition('* => *', [
      animate('.5s ease')
    ]),
  ]),
  trigger('filterBtn', [
    state('open', style({
      opacity: 0,
    })),
    state('closed', style({
      opacity: 1,
    })),
    transition('* => *', [
      animate('.3s ease')
    ]),
  ]),
]
})

export class FilterSidePanelTriggerComponent implements OnInit {
    // Default settings 
    isOpen = false;
    disabled: boolean = false;

    constructor() {}

    ngOnInit(): void {
    // Default Settings
    this.isOpen = false;
    }
    // Toggle Side Panel (filters)
    toggle() {
    this.isOpen = !this.isOpen;
    }
}
