import { Component } from '@angular/core';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-loading-overlay',
    templateUrl: './grid-loading-overlay.html',
    styleUrls: ['./grid-loading-overlay.scss'],

})
export class GridLoadingOverlay implements ILoadingOverlayAngularComp {
  private params: ILoadingOverlayParams;

  loadingMessage: string = "loading"

  agInit(params): void {
    this.params = params;
    this.loadingMessage = this.params['loadingMessage']
  }
}