<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content p-all-20">
  <!-- Season Selected header -->
  <div class="w-100">
    <span  class="modal-form-container-full-width">
      <h4 class="modal-form-label">Current Season:</h4>
      <span [ngSwitch]="noFutureSeasons" class="modal-form-input">
        <p *ngSwitchDefault>{{currentSeason}}</p>
        <p *ngSwitchCase="true" style="color: red" >{{currentSeason}}</p>
      </span>
    </span>
  </div>
  <!-- form data -->
  <form *ngIf="objectKeys(selectionOptions).length > 0" class="w-100" [formGroup]="saveSizeOfferingForm">
    <!-- Select a Future Season -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label"><b *ngIf="noFutureSeasons" style="color: red">*</b> Save To Season:</h4>
      <mat-form-field class="modal-form-input">
        <mat-label>{{noFutureSeasons ? 'No Future Seasons Available' : 'Select a Season'}}</mat-label>
        <mat-select formControlName="selectSeason" [errorStateMatcher]="matcher">
          <mat-option [value]="season.key" *ngFor="let season of selectionOptions.seasonId">
            {{ season.label }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            saveSizeOfferingForm.hasError('noChangesError') ||
            (saveSizeOfferingForm.get('selectSeason').touched &&
            saveSizeOfferingForm.get('selectSeason').errors)
          "
          >{{ setFormError("selectSeason") }}</mat-error
        >
      </mat-form-field>

    </span>
    <!-- Select a Product ID -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">Product:</h4>
      <mat-form-field class="modal-form-input">
        <mat-label>Select a Product </mat-label>
        <mat-select formControlName="selectProdId" [errorStateMatcher]="matcher">
          <mat-option [value]="product.key" *ngFor="let product of selectionOptions.parent_product_id">
            {{ product.label }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            saveSizeOfferingForm.hasError('noChangesError') ||
            (saveSizeOfferingForm.get('selectProdId').touched &&
            saveSizeOfferingForm.get('selectProdId').errors)
          "
          >{{ setFormError("selectProdId") }}</mat-error
        >
      </mat-form-field>
    </span>
     <!-- Select a Location -->
     <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">Channel:</h4>
      <mat-form-field class="modal-form-input">
        <mat-label>Select a Channel</mat-label>
        <mat-select formControlName="selectLocation" [errorStateMatcher]="matcher">
          <mat-option [value]="channel.key" *ngFor="let channel of selectionOptions.channel">
            {{ channel.label }}
          </mat-option>
        </mat-select>
        <mat-error
        *ngIf="
          saveSizeOfferingForm.hasError('noChangesError') ||
          (saveSizeOfferingForm.get('selectLocation').touched &&
          saveSizeOfferingForm.get('selectLocation').errors)
        "
        >{{ setFormError("selectLocation") }}</mat-error
        >
      </mat-form-field>
    </span>
  </form>
  <!-- No Filter params loaded -->
  <div *ngIf="objectKeys(selectionOptions).length === 0">
    <p>Error Loading Form Details</p>
  </div>
</div>
<app-modal-footer
  [showUpdateBtn]="true"
  (update)="onSubmit(saveSizeOfferingForm.getRawValue())"
  (closeModal)="close()"
  [disabled]="!saveSizeOfferingForm?.valid"
></app-modal-footer>
