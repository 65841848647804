<!-- Header -->
<app-nav-bar pageTitle="Define Size Offering"></app-nav-bar>
<!-- Main -->
<main>
    <div class="g-main-body-container">
        <!-- Center Container -->
        <div class="g-main-container-center">
            <!-- Action Buttons Container -->
            <div class="g-grid-actions-container" style="align-items: center;">
                <!-- Delete Size Ranges  -->
                <caption *ngIf="currentSeasonSelected.length > 1" style="font-size: 12px; color: red">* Multiple seasons selected. </caption>
                <button mat-raised-button
                        (click)="openDeleteSizeRangesModal($event)"
                        id="d-size-offering-submit"
                        [disabled]=" currentSeasonSelected.length == 0"
                        class="submit-btn">
                        Delete Size Ranges
                </button>
                <!-- Save Size Offering  -->
                <button mat-raised-button
                        (click)="openSaveSizeOfferingsModal($event, true)"
                        id="s-size-offering-submit"
                        [disabled]="updatingRowData.length > 0 || currentSeasonSelected.length !== 1"
                        class="submit-btn">
                        Save To Future Season
                </button>
                <!-- Manage Size Offerings -->
                <button mat-raised-button
                        (click)="openUpdateNewSizeOfferingsModal($event)"
                        id="upload-size-offering-submit"
                        class="submit-btn">
                        Manage Size Offerings
                </button>
            </div>
            <div style="display:flex">
                <!-- Filter Panel -->
                <app-filter-sidenav>
                    <app-filters-panel [filtersToDisplay]="filtersToDisplay" ></app-filters-panel>
                </app-filter-sidenav>
                <!-- Grid Container -->
                <mat-card class="g-mat-card-container ag-grid-wrapper">
                    <ag-grid-angular
                        #mainGrid
                        id="define-size-offerings-grid"
                        class="ag-theme-alpine ag-grid-container"
                        [gridOptions]="gridOptions"
                        [columnDefs]="columnDefs"
                        (gridReady)="onGridReady($event)"
                        [masterDetail]="true"
                        [detailCellRendererParams]="detailCellRendererParams"
                        [groupRemoveLowestSingleChildren]="true"
                    ></ag-grid-angular>
                </mat-card>
            </div>
        </div>
    </div>
</main>

