<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content">
  <!--Line Chart -->
  <div style="padding: 20px; width: 100%; height: 100%">
    <p-chart
      class="chart"
      id="store-totals-charts"
      type="line"
      model="chartView.horizontalBarModel"
      [data]="data"
      [options]="options"
    >
    </p-chart>
  </div>
</div>

<app-modal-footer
  [showUpdateBtn]="false"
  (closeModal)="close()"
></app-modal-footer>
