import { Component, ViewChild, ViewEncapsulation, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { parseArgs } from 'util';

@Component({
	selector: 'auto-input-delimiter-editor',
    templateUrl: './auto-input-delimiter-cell-editor.html',

})
export class AutoInputDelimiterCellEditor implements ICellEditorAngularComp {
	// variables for agGrid
    public params: any;
	public rowSelection: string = 'single';

	// variables for component
	public cellValue: string;
	public inputValue: string;
	public gridHeight: number = 175;
	public gridWidth: number = 375;
	public delimiter: string = ',';
	public isCanceled: boolean = true;

    @ViewChild("input") input: ElementRef;

    constructor() {}

	// ICellEditorAngularComp functions
    agInit(params: any): void {

        this.params = params;
        this.cellValue = params.value
        this.delimiter = this.params?.colDef?.refData?.delimiter || this.delimiter

		console.log("AutoInputDelimiterCellEditor params: ", params)

		if (params.gridHeight) this.gridHeight = params.gridHeight;
        if (params.gridWidth) this.gridWidth = params.gridWidth;

		if (!params.charPress) {
			if(this.cellValue) this.inputValue = this.cellValue;
		} else {
			this.inputValue = params.charPress;
        }
    }
    afterGuiAttached() {
        document.getElementById("auto-input-delimiter").focus()
    }

    getValue(): any {
        console.log("value: ", this.inputValue)
       return this.inputValue.trim()
    }

	isCancelAfterEnd(): boolean {
		return this.isCanceled
	}

	// component functions
	rowClicked(params) {
		this.isCanceled = false;
		this.params.api.stopEditing();
	}

	rowConfirmed() {
		this.params.api.stopEditing();
    }

    onKey(event: any) {
        event.stopPropagation();
        const value = event.target.value ? event.target.value.split(' ').join('') : ''
        event.target.value = value
        console.log("e.target.value.endsWith: ", event.target.value)

        if (
            this.inputValue &&
            value &&
            event.code === 'Space' &&
            !value.endsWith("|")
        ) {
            if (value.endsWith(".")) {
                event.target.value = value.replace('.', " ").split(' ').join('');
            } else {
                event.target.value = (event.target.value + this.delimiter)
            }
        }
        if (event.code === "Enter") {
            document.getElementById("auto-input-delimiter").focus();
        }
    }
    //
	updateInput(event?) {
        // console.log("Update Val: ", this.inputValue)
        this.inputValue = this.inputValue.split(' ').join('');
        this.params.data[this.params.colDef.colId] = this.inputValue
    }
    //
    formatFinalValue(event) {
        const hasVal = this.inputValue && this.inputValue !== ''
        let inputArray = hasVal ? [...this.inputValue.split('')] : []
        const totalInputs = inputArray.length

        if (totalInputs > 0 && inputArray[totalInputs- 1] === this.delimiter) {
            inputArray.splice(totalInputs - 1, 1)
            this.inputValue = inputArray.join('')
        }
        // Update the data object with the final value
        this.params.data[this.params.colDef.colId] = this.inputValue
        this.params.api.stopEditing();
        this.params.api.tabToNextCell()
    }

}