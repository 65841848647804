import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { FilterService } from 'src/app/core/services/filter.service';
import { TreeFilterComponent } from '../tree-filter/tree-filter.component';
import { GlobalServices } from 'src/app/core/services/db/global-services.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { PrimeNGTreeFilter } from '../primeng-tree-filter/primeng-tree-filter.component';
import { TreeNode } from 'primeng/api';
import { LowestLevelFilterInterface } from 'src/app/core/utils/interfaces/lowest-level-filters.interface';

let matchedNode;

@Component({
  selector: 'app-filters-panel',
  templateUrl: './filters-panel.component.html',
  styleUrls: ['./filters-panel.component.scss']
})
export class FiltersPanelComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  @Input('filtersToDisplay') filtersToDisplay: any[] = ["none"];
  @Input('modalStyling') modalStyling: boolean = false;
  @Output() valueSelected = new EventEmitter<any>();
  // valueSelected: any;

//  valueSelected:{
//     prodId: string[],
//     season: string[],
//     channel: string[]
//   } = {};

  @ViewChildren(PrimeNGTreeFilter) filterTree: PrimeNGTreeFilter[];

  season: any[] = [];
  channel: any[] = [];
  prodHier: any[] = [];
  filtersLoaded: any[] = [];

  fitlerOptions: LowestLevelFilterInterface = {
    season: [],
    channel: [],
    prodId: []
  }

  selectedData: {
    parent_product_id: string[],
    season: string[],
    channel: string[]
  } =
  {
    parent_product_id: [],
    season: [],
    channel: []
  };

  constructor(
    private filterService: FilterService,
    private globalServices: GlobalServices
  ) {
    this.filterService.triggerFilterReviewOrders$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        console.log('filter service triggered with data', data)
        this.selectedData = data;
    });
  }

  ngOnInit(): void {
    this.filtersLoaded = [];
    console.log('Filters to Display: ', this.filtersToDisplay);
    const applyFilter = (filter) => this.filtersToDisplay.includes(filter)

    if (applyFilter("season")) this.getSeasonFilters();
    if (applyFilter("channel")) this.getChannelFilters();
    if (applyFilter("product")) this.getProductFilters();

    if (applyFilter("none")) this.filtersLoaded = ["none"];
    // Object.keys(this.valueSelected).forEach(key => this.selectedData[key] = this.valueSelected[key])
  }
  getSeasonFilters(){
      this.globalServices.getSeasonFilters()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
          next: seasons =>{
          const sortedSeasons = seasons.seasons['children'].sort((a, b) => a.data > b.data ? 0 : -1);
          this.season = [{ ...seasons.seasons, children: sortedSeasons }];
          this.setLowestLevelFilterOptions('season', this.season)
        },
        complete: () => {
          console.log("season filters: ", this.season);
          this.filtersLoaded.push("season");
        },
          error: (error) => console.log('Error loading seasons to filter: ', error)
        })
  }
  getChannelFilters(){
    this.globalServices.getChannelFilters()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: channels => {
        this.channel = [channels.channels];
        this.setLowestLevelFilterOptions('channel', this.channel)
      },
      complete: () => {
        console.log("channels: ", this.channel);
        this.filtersLoaded.push("channel");
      },
        error: (error) => console.log('Error loading channels to filter: ', error)
      })
  }
  getProductFilters(){
    this.globalServices.getProductFilters()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: prodHier => {
        this.prodHier = [prodHier.products];
        this.setLowestLevelFilterOptions('prodId', this.prodHier)
      },
      complete: () => {
        this.filtersLoaded.push("product");
      },
        error: (error) => console.log('Error loading products to filter: ', error)
      })
  }

  // for prime ng tree. hacky. should have database function return the fields named correctly
  // next: prodHier => this.prodHier = JSON.parse(JSON.stringify([prodHier.products])
  // .replaceAll('"prodId":', '"data":')
  // .replaceAll('"prodNm":', '"label":')),

  @Input('selectedData')
  set _selectedData(data) {
    console.log('received selecteddata in filters-panel component', data)
    this.selectedData = data
  }

  nodesSelected(data: string[], filter: string) {
    console.log('filter field', filter)
    console.log("node selected", cloneDeep(data))
    const applyFilter = {[filter]: data}
    this.selectedData = {
      ...this.selectedData,
      ...applyFilter
    }
    // this.valueSelected.emit(this.selectedData);
    // this.valueSelected.emit();
  }

  onValueSelected(data) {
    console.log('onvalueselected', data)
    this.selectedData[data.filterName] = data.values;
    this.valueSelected.emit(this.selectedData.parent_product_id);
  }

  onApply() {
    console.log('applying', this.selectedData);
    this.filterService.onUpdateFilters(this.selectedData);
  }

  async setLowestLevelFilterOptions(name, filters) {
    try {
      const checkChildren = (options) => {
        options.forEach(opt => {
          if (opt.children.length > 0) {
              checkChildren(opt['children'])
          } else if (opt.children.length === 0 && !this.fitlerOptions[name].includes(opt['key'])) {
            this.fitlerOptions[name].push(opt['key']);
          }
        })
      }
      await checkChildren(filters)
    } catch (error) {

    } finally {
      console.log("filter Options: ", name, this.fitlerOptions)
      this.filterService.onSetFilters(this.fitlerOptions);

   }

  }

  onClear() {
    console.log("clear nodes: ",)
    this.selectedData = {
      parent_product_id: [],
      season: [],
      channel: []
    };
    // this.filterTree.forEach(tree => {
    //   tree.selectedValues = [];
    //   tree.
    // })
    this.onApply();
  }
}
