<!-- Render sign-in screen otherwise with authenticator -->
<!-- <ng-container *ngIf="authenticator.route !== 'authenticated'"> -->
<!-- <ng-container *ngIf="!user"> -->
  <!-- <app-login></app-login>
</ng-container> -->

<ng-container *ngIf="authenticator.route === 'authenticated'">
<!-- <ng-container *ngIf="user"> -->
  <router-outlet></router-outlet>
</ng-container>
<!-- Render loading if authStatus is still configuring  -->
<ng-container *ngIf="authenticator.authStatus === 'configuring'">
  Loading...
</ng-container>