import {Component,EventEmitter,Input,OnInit, Output} from '@angular/core';
import {TreeNode} from 'primeng/api';
import { FilterService } from 'src/app/core/services/filter.service';
import { IsKeyInObj } from 'src/app/core/utils/global-functions';

@Component({
  selector: "app-primeng-tree-filter",
  templateUrl: './primeng-tree-filter.component.html',
  styleUrls: ['./primeng-tree-filter.component.scss']
})
export class PrimeNGTreeFilter implements OnInit {

    @Input() filterName = '';
    @Input() modalStyline = false;
    @Input() treeData: TreeNode[] = [];
    selectedValues: TreeNode[] = [];
    @Output() valueSelected = new EventEmitter<any>();
    @Output() nodesSelected: EventEmitter<any> = new EventEmitter<any>();

    selectedNodes: string[] = [];
    lowestLevelFilters: any = {}

    constructor(
      private filterService: FilterService
      ) { }

    ngOnInit() {
      console.log('treedata', this.treeData)
      console.log('primeng tree selecftedvalues', this.selectedValues)
      this.treeData.forEach(node => {
        this.hasChild(0, node)
      })
    }

    nodeSelect(event) {
      console.log('node select', event);
      console.log(this.selectedValues)
      this.valueSelected.emit({
        filterName: this.filterName,
        values: this.selectedValues
      });
      // const data = {}
      // data[this.filterName] = this.selectedValues;
      // this.filterService.onUpdateFiltersReviewOrders(data)
    }
    @Input('selectedValues')
    set _selectedValues(data: TreeNode[]) {
      console.log('setting selectedvalues data ' + this.filterName, data)
      if (!data) data = []
      const selectedKeys = data.map(e => e.key);
      if (data.length === 0) {
        console.log('empty selection', this.filterName)
        this.unselectNode(this.treeData[0])
      } else {
        console.log('setting partial selected')
        data.forEach(node => {
          console.log('for parent', node.parent)
          this.setParentsPartialSelected(node.parent, selectedKeys)
        })
      }
      this.selectedValues = data
    }

    setParentsPartialSelected(node: TreeNode, selectedKeys: string[]) {
      if (node && !selectedKeys.includes(node.key)) {
        console.log('setting parent partialselect true')
        node.partialSelected = true
        console.log(node.partialSelected)
        this.setParentsPartialSelected(node.parent, selectedKeys)
      }
    }

    unselectNode(node: TreeNode) {
      console.log('running unselectnode', this.filterName)
      // used for clearing partial selects when clear button is clicked
      node.partialSelected = false
      node.children?.forEach(child => {
        this.unselectNode(child)
      })
    }

    hasChild = (_: number, node: any) => {
      // console.log("NODE IN HASCHILD", node)
      if (node.children.length === 0) {
        const objToUpdate = { ...node, filter: this.filterName }
        // Prevent Duplication of filters
        const existingFilter = Object.keys(this.lowestLevelFilters).length > 0 ? this.lowestLevelFilters[this.filterName]?.find(filter => {
          // console.log("find filter", filter)
          // console.log('filter.filter', filter.filter)
          // console.log('filter[filter.filter]', filter[filter.filter])
          // console.log('objtoupdate', objToUpdate)
          // console.log('objtoupdate[filter.filter]', objToUpdate[filter.filter])
          return filter['data'] === objToUpdate['data']
        }) : null
        // console.log('EXISTINGFILTER IS', existingFilter)
        if (IsKeyInObj(this.lowestLevelFilters, this.filterName) && !existingFilter) {
          this.lowestLevelFilters[this.filterName].push(objToUpdate);
        } else if (!existingFilter) {
          Object.assign(this.lowestLevelFilters, { [this.filterName]: [objToUpdate] })
        }
        this.filterService.updateLowestLevelFilters(this.filterName, this.lowestLevelFilters)
      }
      else {
        node.children.forEach(child => this.hasChild(_ + 1, child))
      }
      return !!node.children && node.children.length > 0
    };
}