import { Injectable } from '@angular/core';
import { BehaviorSubject }    from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SelectedRowService{
    constructor() {}
    // Observable string sources
    private selectedRowId = new BehaviorSubject(null);
    private selectedRowsArray = new BehaviorSubject([]);


    getSelectedRowId(){
        return this.selectedRowId.asObservable();
    }

    updateSelectedRowId(id){
        return this.selectedRowId.next(id)
    }
    getSelectedRowsArray = () => this.selectedRowsArray.asObservable();
    updateSelectedRowsArray = (idArray) => this.selectedRowsArray.next(idArray);

}
