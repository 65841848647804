import { NestedTreeControl } from "@angular/cdk/tree";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import {
  MatTreeNestedDataSource,
  MatTreeNodeToggle,
} from "@angular/material/tree";
import { FilterService } from 'src/app/core/services/filter.service';
import { IsKeyInObj } from 'src/app/core/utils/global-functions';
// import { ProdIdNode } from 'src/app/core/utils/types';

interface TreeDataNode {
  parentId?: string;
  children?: TreeDataNode[];
  selected?: boolean;
}

let matchedNode;

@Component({
  selector: "app-tree-filter",
  templateUrl: "./tree-filter.component.html",
  styleUrls: ["./tree-filter.component.scss"],
})
export class TreeFilterComponent implements OnInit {
  @Input() label: string;
  @Input() nodeId: string;
  @Input() nodeName: string;
  @Input() treeData: any;
  @Input() modalStyling: boolean = false;
  @Output() nodesSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren("nodeCheckbox") nodeCheckbox: HTMLInputElement[];

  treeControl = new NestedTreeControl<TreeDataNode>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<TreeDataNode>();

  selectedNodes: string[] = [];
  lowestLevelFilters: any = {}

  constructor(
    private filterService: FilterService
  ) {
    this.filterService.getLowestLevelFilters()
      .subscribe(res => { this.lowestLevelFilters = res })
  }

  ngOnInit(): void {
    this.dataSource.data = this.treeData;
    this.setNodeParentField(this.treeData);
    if (!this.nodeName) {
      this.nodeName = this.nodeId;
    }
  }

  getDisplayLabel(node) {
    console.log('displaying node', node)
    if (this.nodeName === "prodId") {
      if (node["prodId"] === "All") return "All"
      return node["prodNm"]
    } else {
      // console.log("node", node)
      if (!node["parentId"]) {
        return "All"
      }
      return node[this.nodeName]
    }
  }

  hasChild = (_: number, node: any) => {
    if (node.children.length === 0) {
      const objToUpdate = { ...node, filter: this.nodeId }
      // Prevent Duplication of filters
      const existingFilter = Object.keys(this.lowestLevelFilters).length > 0 ? this.lowestLevelFilters[this.nodeId]?.find(filter => filter[filter.filter] === objToUpdate[filter.filter]) : null
      if (IsKeyInObj(this.lowestLevelFilters, this.nodeId) && !existingFilter) {
        this.lowestLevelFilters[this.nodeId].push(objToUpdate);
      } else if (!existingFilter) {
        Object.assign(this.lowestLevelFilters, { [this.nodeId]: [objToUpdate] })
      }
      // this.filterService.updateLowestLevelFilters(this.lowestLevelFilters)
    };
    return !!node.children && node.children.length > 0
  };

  selectAllChildren(children: TreeDataNode[], value: boolean) {
    console.log("selectAllChildren children", children)
    console.log("selectAllChildren value", value)

    children.forEach((child) => {
      child.selected = value;
      this.nodeCheckbox.find(
        (el) => el.id === child[this.nodeId]
      ).checked = value;

      if (child.children) {
        this.selectAllChildren(child.children, value);
      }
    });
  }

  getNode(data: TreeDataNode[], id: string) {
    console.log("getNode data", data)
    console.log("getNode id", id)
    if (!data) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i][this.nodeId] === id) {
        matchedNode = data[i];
        break;
      }

      if (data[i].children) {
        this.getNode(data[i].children, id);
      }
    }
    return matchedNode;
  }

  setNodeParentField(data: TreeDataNode[]) {
    if (!data) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].children) {
        data[i].children.forEach((child) => {
          child.parentId = data[i][this.nodeId];
        });
        this.setNodeParentField(data[i].children);
      }
    }
  }

  async onNodeSelect(event: MatCheckboxChange, node: TreeDataNode) {
    try {
      console.log("onNodeSelect: ", event.checked)
      node.selected = event.checked;
      if (node.children.length) {
        await this.selectAllChildren(node.children, event.checked);
      }

      if (node.parentId) {
        const parentData = await this.getNode(this.treeData, node.parentId);
        const parentCheckbox = await this.nodeCheckbox.find(
          (el) => el.id === node.parentId
        );
        const allChildrenSelected =
          parentData.children.length ===
          await parentData.children.filter((child) => child.selected).length;
        parentData.selected = allChildrenSelected;
        parentCheckbox.checked = allChildrenSelected;
      }

    } catch (error) {

    } finally {
      const updatedNodes = this.nodeCheckbox
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);
      this.selectedNodes = updatedNodes

       console.log("filter data: ", updatedNodes)
      // For Review Order Screen only
      this.filterService.onUpdateFiltersReviewOrders(updatedNodes)
      this.nodesSelected.emit(updatedNodes);
    }
  }

  clearNodeSelectedProperty(data: TreeDataNode[]) {
    if (!data) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      data[i].selected = false;
      if (data[i].children) {
        this.clearNodeSelectedProperty(data[i].children);
      }
    }
  }

  clearNodes() {
    console.log("clearing nodes");
    this.nodeCheckbox.forEach((checkbox) => (checkbox.checked = false));
    this.clearNodeSelectedProperty(this.treeData);
    this.selectedNodes = [];

    // only called by parent function, which handles the application of the empty arrays itself, so we shouldn't emit here
    // this.nodesSelected.emit(this.selectedNodes);
  }

  readProdIdUrlParam() {
    setTimeout(() => {
      if (this.selectedNodes.length) {
        this.selectedNodes.forEach((id) => {
          this.nodeCheckbox.find((el) => el.id === id).checked = true;
        });
      }
    });
  }
}
