<div [className]="'filter-sidebar' + (modalStyling ? ' modal-filter' : '')">
  <div *ngIf="!modalStyling" class="buttons-container">
    <button mat-raised-button (click)="onApply()">Apply</button>
    <button mat-button class="text-warning" (click)="onClear()">Clear</button>
  </div>
  <div *ngIf="filtersLoaded.length === 0"
  [className]="modalStyling ? 'modal-filter-loading' : ''">
      <app-loader [loadingMessage]="'loading'"></app-loader>
  </div>
  <div *ngFor="let filter of filtersToDisplay">

    <div *ngIf="filtersLoaded.length === filtersToDisplay.length" [class]="'filter-tile' + (modalStyling ? ' no-buttons' : '')" [ngSwitch]="filter">

      <!-- Product Filter -->
      <!-- <p-tree [value]="prodHier"
      selectionMode="checkbox"
      (onNodeSelect)="nodeSelect($event, 'prodId')"
      (onNodeUnselect)="nodeSelect($event, 'prodId')"
      ></p-tree> -->
      <app-primeng-tree-filter
      *ngSwitchCase="'product'"
      [filterName]="'parent_product_id'"
      [treeData]="prodHier"
      [modalStyling]="modalStyling"
      [selectedValues]="selectedData.parent_product_id"
      (valueSelected)="onValueSelected($event)"></app-primeng-tree-filter>
      
      <app-primeng-tree-filter
      *ngSwitchCase="'channel'"
      [filterName]="'channel'"
      [treeData]="channel"
      [modalStyling]="modalStyling"
      [selectedValues]="selectedData.channel"
      (valueSelected)="onValueSelected($event)"></app-primeng-tree-filter>
      
      <app-primeng-tree-filter
      *ngSwitchCase="'season'"
      [filterName]="'season'"
      [treeData]="season"
      [modalStyling]="modalStyling"
      [selectedValues]="selectedData.season"
      (valueSelected)="onValueSelected($event)"></app-primeng-tree-filter>
      <!-- <app-tree-filter
        *ngSwitchCase="'product'"
        #filterTree
        label="Products"
        nodeId="prodId"
        nodeName="prodId"
        [treeData]="prodHier"
        [modalStyling]="modalStyling"
        (nodesSelected)="nodesSelected($event, 'prodId')"
      >
      </app-tree-filter> -->
      <!-- Location Filter -->
      <!-- <app-tree-filter
        *ngSwitchCase="'channel'"
        #filterTree
        label="Channels"
        nodeId="locationId"
        nodeName="locationId"
        [treeData]="channel"
        (nodesSelected)="nodesSelected($event, 'channel')"
      >
      </app-tree-filter> -->
      <!-- Season Filter -->
      <!-- <app-tree-filter
        *ngSwitchCase="'season'"
        #filterTree
        label="Seasons"
        nodeId="seasonId"
        nodeName="seasonId"
        [treeData]="season"
        (nodesSelected)="nodesSelected($event, 'season')"
      >
      </app-tree-filter> -->
      <!-- Default: No Filters -->
      <span *ngSwitchCase="'none'"> No filters to display. </span>
    </div>
  </div>
</div>
