<div class="custom-tooltip" [style.background-color]="data.color">
  <p class="text-center pack-name">
    <span>{{ packName }} Sizes: </span>
  </p>
  <div *ngFor="let size of configData" class="p-grid">
    <div class="p-col-6 p-all-0">
      <p align="right">
        <span>{{ size.Size }} : </span>
      </p>
    </div>
    <div class="p-col-6 p-all-0">
      <p>
        <span>{{ size.Qty }}</span>
      </p>
    </div>
  </div>
</div>
