// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
// Material UI Imports
// =========================================================
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

// Custom Imports
// =========================================================
// import * as configSettings from '../../../../assets/mock-data/07_config-settings.json';
import * as configSettings from '../../../../core/json/review-orders/07_config-settings.json';

import { FormGroup, FormControl } from '@angular/forms';
import { ReviewOrdersService } from 'src/app/core/services/db/review-orders.service';
import { handleError } from 'src/app/core/utils/error-handling';

@Component({
  selector: 'app-user-input-config',
  templateUrl: './user-input-config.component.html',
  styleUrls: ['./user-input-config.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})

export class UserInputConfigComponent implements OnInit {

  modalTitle = "Configuration Settings"
  configData: any
  data: any[]
  value: any[]
  ObjectKeys = Object.keys
  events: string[] = [];
  date = new Date()
  testForm: FormGroup;
  dimId: number;
  rowId: number = null;
  isLoading: boolean;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private ro_services: ReviewOrdersService,
    private dialogService: DialogService,
  ) {
    this.configData = configSettings //this.config.data.data['settings']
    // console.log("config", config)
    this.dimId = config.data.dimId
    this.rowId = config.data.rowId
    // console.log("this.dimId", this.dimId)
    // console.log("configdata",this.configData)
    // var oldData = Object.keys(configSettings['default']).map(key => { return { 
    //   "field": [key], 
    //   "data": configSettings['default'][key] 
    // } })
    // console.log("oldData", oldData)

    this.ro_services.ro_getSettingsByDimId(this.dimId)
    .subscribe({
      next: (data) => {
        console.log("Data",data)
        const groups = data.settings[0].obj
        groups.forEach(group => {
          console.log("group", group)
          group.settings = group.settings.sort((a, b) => {
            if (a.data_type == b.data_type) {
              return a.setting_name.localeCompare(b.setting_name)
            }
            else return a.data_type.localeCompare(b.data_type)
          })
        })
        groups.forEach(group => {
          group.settings.forEach(setting => {
            if (setting.data_type === 'boolean'
              && setting.setting_value.toUpperCase() === 'FALSE') {
                setting.setting_value = false
              }
          })
        })
        this.data = groups
        console.log('from service', this.data)
      },
      complete: () => { this.isLoading = false },
      error: (error) => handleError(this.dialogService, error, 'Error fetching settings data')
    })
  }
// FIXME: make config settings modal case insensitive 
  ngOnInit(): void {
    this.testForm = new FormGroup({
      data: new FormControl(this.data)
    });
  }

  setDateValue(date){
    if (this.date !== new Date(date)){
      // console.log("date: ", new Date(date))
      return this.date = new Date(date)
    }
  }
  getValue(tabName, field){
    // console.log("tab name: ", tabName)
    // console.log("field: ", field)
  }

  submit() {
    const submitData = []
    console.log('submit', this.data)
    for (var i = 0; i < this.data.length; i++) {
      const group = this.data[i]
      for (var j = 0; j < group.settings.length; j++) {
        const setting = group.settings[j]
        submitData.push({
          setting_group: group.setting_group,
          setting_name: setting.setting_name,
          setting_key: setting.setting_key,
          setting_value: setting.setting_value,
          dim_id: this.dimId,
          row_id: this.rowId,
          data_type: setting.data_type
        })
      }
    }
    this.ro_services.ro_setSettings(submitData, this.dimId).subscribe({
      next: () => { this.ref.close() },
      complete: () => { },
      error: (error) => handleError(this.dialogService, error, 'Error saving settings')
    })
  }

  close() {
    this.ref.close()
  }
}
