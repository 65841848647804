  <div class="loading-container-main">
      <div class="loading-container-inner">
          <div class="letter-container">
            <!-- Default Loading Data -->
            <span *ngIf="isLoading" class="message-container">
              <h3 class="l-1 letter">L</h3>
              <h3 class="l-2 letter">o</h3>
              <h3 class="l-3 letter">a</h3>
              <h3 class="l-4 letter">d</h3>
              <h3 class="l-5 letter">i</h3>
              <h3 class="l-6 letter">n</h3>
              <h3 class="l-7 letter">g</h3>
              <h3 class="l-8 letter"> </h3>
              <h3 class="l-9 letter"> </h3>
              <h3 class="l-10 letter"> </h3>
            </span>
            
            <span *ngIf="isExporting" class="message-container">
              <h3 class="l-1 letter">E</h3>
              <h3 class="l-2 letter">x</h3>
              <h3 class="l-3 letter">p</h3>
              <h3 class="l-4 letter">o</h3>
              <h3 class="l-5 letter">r</h3>
              <h3 class="l-6 letter">t</h3>
              <h3 class="l-7 letter">i</h3>
              <h3 class="l-8 letter">n</h3>
              <h3 class="l-9 letter">g</h3>
              <h3 class="l-10 letter"> </h3>
            </span>

            <!-- Updating Data -->
            <span *ngIf="!isLoading && !isExporting" class="message-container">
              <h3 class="l-1 letter">U</h3>
              <h3 class="l-2 letter">p</h3>
              <h3 class="l-3 letter">d</h3>
              <h3 class="l-4 letter">a</h3>
              <h3 class="l-5 letter">t</h3>
              <h3 class="l-6 letter">i</h3>
              <h3 class="l-7 letter">n</h3>
              <h3 class="l-8 letter">g</h3>
              <h3 class="l-9 letter"> </h3>
              <h3 class="l-10 letter"> </h3>
            </span>

            <h3 [class]="{'data-1': true, 'letter': true, 'loading': isLoading}">D</h3>
            <h3 [class]="{'data-2': true, 'letter': true, 'loading': isLoading}">a</h3>
            <h3 [class]="{'data-3': true, 'letter': true, 'loading': isLoading}">t</h3>
            <h3 [class]="{'data-4': true, 'letter': true, 'loading': isLoading}">a</h3>


            <h3 [class]="{'dot-1': true, 'letter': true, 'loading': isLoading}">.</h3>
            <h3 [class]="{'dot-2': true, 'letter': true, 'loading': isLoading}">.</h3>
            <h3 [class]="{'dot-3': true, 'letter': true, 'loading': isLoading}">.</h3>
          </div>
      </div>
  </div>