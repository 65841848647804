<div class="container" #group tabindex="0" >
  <!-- (keydown)="onKeyDown($event)" -->
  <mat-form-field style="width: 70px">
    <mat-select
      [(value)]="selected"
      panelClass="ag-custom-component-popup"
      (selectionChange)="value($event)"
    >
      <mat-option *ngFor="let type of params.value" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
