// Angular and RJX Imports
// =========================================================
import { Component, Input, type OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DashboardTileComponent implements OnInit {
  @Input() category: any

  constructor (public router: Router) {}
  ngOnInit (): void {
    // console.log('category: ', this.category)
  }

  onClickNav (path: string) {
    console.log('navigate to: ', path)
    this.router.navigateByUrl(path)
  }
}
