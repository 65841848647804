<app-modal-header
    [modalTitle]="modalTitle"
	[showCloseBtn]="true"
	(closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content">
  <!--Bar Chart -->
  <div style="padding: 20px; width: 100%; height: 100%">
    <p-chart 
        class="chart"
        id="buy-summary-charts"
        [type]="isBuy ? 'line' : 'bar'"
        model="chartView.horizontalBarModel"
        [data]="data"
        [options]="options"
    >
    </p-chart>
  </div>
</div>

<app-modal-footer [showUpdateBtn]='false' (closeModal)="close()"></app-modal-footer>
