import { Component, type OnInit } from '@angular/core'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ChatWindowComponent } from './chat-window/chat-window.component';

@Component({
  selector: 'app-chat-wrapper',
  templateUrl: './chat-wrapper.component.html',
  styleUrls: ['./chat-wrapper.component.scss']
})
export class ChatWrapperComponent implements OnInit {
    ref: DynamicDialogRef;

  constructor(
    public dialogService: DialogService,
  ) {

  }

  ngOnInit () {
    this.ref = this.dialogService.open(ChatWindowComponent, {
        closable: false,
        showHeader: false,
        modal: false,
        width: '500px',
        styleClass: 'chat-wrapper',
        style: {
            'align-self': 'flex-start', 
            'margin-left': 'auto',
            'margin-top': 'auto',
            'margin-bottom': '10px', 
            'margin-right': '10px',
            'max-height': '500px'
        }
    });
    // (document.querySelectorAll('.p-dialog-mask')[0] as HTMLElement).style.backgroundColor = null;
  }
}


