// Angular Imports
// =========================================================
import { Component, OnInit, SimpleChanges, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UploadNewSizeOfferingsService } from 'src/app/core/services/define-size-offerings-services/upload-new-size-offerings-service';
// Ag Grid Imports
// =========================================================
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';
// JSON Data
// =========================================================
import DeleteSizeRangesMainColDefs from '../../../../core/json/define_size_offerings/colDefs-main-delete_size_ranges.json'

// Custom Imports
// =========================================================
import { DefineSizeOfferingsService } from 'src/app/core/services/db/define-size-offerings.service';
import { handleError } from 'src/app/core/utils/error-handling';
import { CustomGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
import { DeleteSizeRanges_GenerateGridData } from 'src/app/components/02_ag-grid-settings/02_ag-generate-col-Defs/define-size-offerings/delete-size-ranges-main-grid-config';


@Component({
    selector: 'app-delete-size-ranges',
    templateUrl: './delete-size-ranges.component.html',
    styleUrls: ['./delete-size-ranges.component.scss']
})

export class DeleteSizeRangesComponent implements OnInit {
    // private unsubscribe$ = new Subject<void>();
    @Output() deleteEvent = new EventEmitter<any>();
    // Ag Grid Configuration
    private customGridOptions: any = {
        ...CustomGridOptions,
        context: {
            componentParent: this,
        },
    };

    objectKeys = Object.keys

    // Ag Grid Configuration
    columnDefs;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    gridOptions: any = {};
    rowData = []
    isDeleted = false;

    loadingMessage: string = '';
    updatingRowData: any[] = [];
    params;
    selectedSizes:any[] = [];
    selectedRows: any[] = []

    modalTitle = "Delete Size Ranges";
    numOfSizes: string = '';
    disableSubmit: boolean = false; 

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        public uploadNewSizeOfferingsService: UploadNewSizeOfferingsService,
        private defineSizeOfferingsService: DefineSizeOfferingsService

    ) {
        const {data} = this.config
            this.selectedSizes = data.selectedSizes;
        console.log("data.selectedSizes", this.selectedSizes)
        

        this.gridOptions = {
            ...this.customGridOptions,
            frameworkComponents: {
                ...this.customGridOptions.frameworkComponents,
            },
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            // getRowNodeId: (data) => data?.id,
        };
    }


    ngOnInit(): void {
        this.numOfSizes = `Deleting the ${this.selectedSizes.length} row${this.selectedSizes.length === 1 ? '' : 's'} below is permanent and cannot be undone. Are you sure you want to continue?`
    }
    
    // when AG Grid is done with initial processing, run the following functions
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.showLoadingOverlay();
        this.renderGrid(DeleteSizeRangesMainColDefs[0])
    }

    //Render AF Grid Column Definitions
    async renderGrid(mainColDefs) {
        const params = {
            mainColDefs: mainColDefs,
        };
        const gridData = await DeleteSizeRanges_GenerateGridData(params); 
        if (gridData && !this.gridApi["destroyCalled"]) {
            console.log('Delete Size Ranges Column Defs: ', gridData.mainColDefs);
            this.columnDefs = gridData.mainColDefs;
            this.gridApi.setColumnDefs(this.columnDefs);
            this.gridApi.setRowData(this.selectedSizes);
        }
    }
	
   async deleteSizeRanges(event: any) {
     
        this.gridApi.showLoadingOverlay()
        // try{
        console.log("selectedSizes", this.selectedSizes)
        // Reformat the sizes data to send to the db
        const deleteSizes = this.selectedSizes.map(row => {
    
        return {
            rec_id: row.rec_id,  
        }
        })
    
        // Run the Delete Sizes
        await this.defineSizeOfferingsService.deleteSizeRanges(deleteSizes).subscribe({
            // this.defineSizeOfferingsService.deleteSizeRanges([{rec_id: "hello"}]).subscribe({
            next: (r) => {console.log("r: ", r)},
        complete: () => { console.log("completed"); this.ref.close(true)},
        error: (error) => { 
            console.log("errorrrrr" , error); 
            this.gridApi.hideOverlay();
            // TODO: figure out why error modal is not displaying 
            handleError(this.params.context.componentParent.dialogService, error, 'Error Deleting Test Scenarios')
        }
        })
   }

    onExportFile(){
        this.gridApi.exportDataAsCsv({
            fileName: 'delete_sizes_ranges.csv',
        });
    }

    close() {
        this.ref.close()
        
    }
}
