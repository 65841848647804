import { Injectable } from '@angular/core';
import { BehaviorSubject }    from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalcBreaksService{
    constructor() {}
    // Observable string sources
    private selectedJSONData = new BehaviorSubject(0);

    getDataIndex(){
        return this.selectedJSONData.asObservable();
    }

    updateDataIndex(dataIndex){
        return this.selectedJSONData.next(dataIndex)
    }
}
