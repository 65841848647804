import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})

export class DefineSizeOfferingsService {
	private api = '/api/defineSizeOfferings';

	private dso_allRows = `${this.api}/allRows`;
	private endDate = `${this.api}/seasonEndDate`;
	private run_QSOR = `${this.api}/runQSOR`;
	private saveSizeOfferings = `${this.api}/saveSizeOfferings`;
	private saveBulkSizeOfferings = `${this.api}/saveBulkSizeOfferings`;
	private recByRecId = `${this.api}/getRecByRecId`;
	private $getRowByRecId = `${this.api}/getRowByRecId`;
	private $bulkUploadSizeRanges = `${this.api}/bulkUploadSizeRanges`
	private reviewBulkSizeRanges = `${this.api}/reviewBulkSizeRanges`
    private deleteSize = `${this.api}/deleteSize`;

	constructor(private http: HttpClient) {}

	dso_getAllRows() {
		return this.http.get<any>(this.dso_allRows);
	}
	getRecByRecId(data) {
		console.log('recId: ', data)
		return this.http.put<any>(`${this.recByRecId}`, data)
	}
	getRowByRecId(id) {
		console.log('recId: ',id)
		return this.http.get<any>(`${this.$getRowByRecId}/${id}`,)
	}
	getSeasonStartEndDates() {
		return this.http.get<any>(this.endDate);
	}

	runQSOR(rec_id) {
		return this.http.put<any>(`${this.run_QSOR}`, rec_id)
	}

	dso_saveSizeOfferings(data){
		return this.http.put<any>(`${this.saveSizeOfferings}`, data)
	}

	dso_saveBulkSizeOfferings(data){
		return this.http.put<any>(`${this.saveBulkSizeOfferings}`, data)
	}

	bulkUploadSizeRanges(data) {
		console.log("upload bulk size range: ", `${this.$bulkUploadSizeRanges}`, data)
		return this.http.post<any>(`${this.$bulkUploadSizeRanges}`, data)
	}
	getBulkSizeRangesToReview() {
		return this.http.get<any>(this.reviewBulkSizeRanges);
	}
	deleteSizeRanges(data) {
        return this.http.put<any>(`${this.deleteSize}`, data)
    }  
}
