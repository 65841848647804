// // AutoInputDelimiterCellRenderer
// import { Component, ViewChild, ViewEncapsulation, ElementRef, HostListener } from '@angular/core';
// import { ICellRendererAngularComp } from 'ag-grid-angular';
// import 'ag-grid-enterprise';
// import { ICellRendererParams } from 'ag-grid-enterprise';


// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// AG Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'edit-cell-icon-renderer',
  templateUrl: "./editable-cell-icon-renderer.html"
})

export class EditableCellIconRenderer implements ICellRendererAngularComp {
  params!: ICellRendererParams;

  value: string;
  iconName: string;

  agInit(params: ICellRendererParams): void {
    console.log("Edit Cell Icon Renderer Params: ", params)
    this.params = params;
    this.value = params.valueFormatted ? params.valueFormatted : params.value
    this.iconName = params['renderer_icon']
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    this.value = params.valueFormatted ? params.valueFormatted : params.value
    return true;
  }
}