// Angular Imports
// =========================================================
import { Component, OnDestroy, OnInit } from "@angular/core";
// rxjs / lodash Imports
// =========================================================
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as  _  from "lodash";
// Ag Grid Imports
// =========================================================
import {
  CellValueChangedEvent,
  GridApi,
  GridReadyEvent,
  RowGroupOpenedEvent,
  RowNode,
  ColumnApi,
} from "ag-grid-community";
// Prime NG Imports
// =========================================================
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
// Mock JSON Data
// =========================================================
import SCABP_MainColDefs from "../../../core/json/set-carton-and-buy-params/colDefs-main-set-carton-and-buy-params.json";
import SCABP_DetailColDefs from "../../../core/json/set-carton-and-buy-params/colDefs-detail-set-carton-and-buy-params.json";
// Custom Imports
// =========================================================
import { FilterService } from "src/app/core/services/filter.service";
import { DoesExternalFilterPass, GetRowNodeId, IsExternalFilterPresent, CollapseRowById } from '../../02_ag-grid-settings/g-ag-grid-functions';
import { CustomGridOptions } from "../../02_ag-grid-settings/g-ag-grid-options";
import { DefaultDetailGridOptions } from "../../02_ag-grid-settings/g-ag-grid-variables";
import { SDVPS_GenerateGridData } from "../../02_ag-grid-settings/02_ag-generate-col-Defs/set-carton-and-buy-params/set-carton-and-buy-params-grid-config";
import { VendorPacksModalComponent } from '../../03_modals/set-carton-and-buy-params/vendor-packs/vendor-packs-modal.component';
import { EditStoreDistroModalComponent } from '../../03_modals/set-carton-and-buy-params/edit-store-distro/edit-store-distro-modal.component';
import { SetCartonAndBuyParamsDetailGrid } from './scabp-detail-grid/set-carton-and-buy-params-detail-grid.component';
import { SetCartonAndBuyParamsService } from "src/app/core/services/db/set-carton-and-buy-params.service";
import { handleError } from "src/app/core/utils/error-handling";
import { GlobalServices } from "src/app/core/services/db/global-services.service";

@Component({
  selector: "app-set-carton-and-buy-params",
  templateUrl: "./set-carton-and-buy-params.component.html",
  styleUrls: ["./set-carton-and-buy-params.component.scss"],
})

export class SetCartonAndBuyParamsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  // Ag Grid Configuration
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: "Set Carton And Buy Parameters",
      vendor_packs: (params) => this.openVendorPacksModal(params),
      edit_store_distro: (params) => this.openEditStoreDistroModal(params)
    },
  };
  gridApi: GridApi;
  columnApi: ColumnApi;
  gridOptions: any = {};

  // Grid Data
  columnDefs: any = []
  detailColumnDefs: any = []
  // 'External side panel filters'
  externalFiltersToDisplay: any[] = ['channel', 'product']
  filtersApplied = {};
  // Update RowData
  updatingRowData: any[] = [];
  loadingMessage: string = '';
  // Global Variables
  dimensions: any = [];
  origSettings: any[] = [];
  edited: any = {};
  ref: DynamicDialogRef;
  initialRender: boolean = true;

  changesPromoted: any = {};

  constructor(
    filterService: FilterService,
    public dialogService: DialogService,
    private SCABP_Service: SetCartonAndBuyParamsService,
    private globalServices: GlobalServices,
  ) {
    // Subscribe to side panel filters
    filterService.triggerFilterSource$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.filtersApplied = data;
        this.gridApi.onFilterChanged();
      });

    // Set AgGrid Options
    this.gridOptions = {
      ...this.customGridOptions,
      frameworkComponents: {
        ...this.customGridOptions.frameworkComponents,
        setCartonAndBuyParamsDetailGrid: SetCartonAndBuyParamsDetailGrid
      },
      detailCellRenderer: 'setCartonAndBuyParamsDetailGrid',
      fullWidthCellRenderer: 'rowLoadingCellRenderer',

      getRowNodeId: (data) => data.dim_id,
      onRowGroupOpened: (event) => this.onRowGroupOpened(event),
      isExternalFilterPresent: (event) => IsExternalFilterPresent(this.filtersApplied),
      doesExternalFilterPass: (node) => DoesExternalFilterPass(node, this.filtersApplied),
      isFullWidthCell: (rowNode) => this.updatingRowData.indexOf(`${rowNode.id}`) >= 0,
      getRowHeight: (params) => { if (params.node && params.node.detail) return 330; },
      onFirstDataRendered:  (params) => {
      if  (params.lastRow !== 0 ) this.initialRender = false;
          console.log("first data rendered: ",  params
      )},
    };

    // this.renderGrid(SCABP_MainColDefs[0], SCABP_DetailColDefs[0])
    // Get All Dimensions then populate row data
    this.getAllDimensions()
  }

  ngOnInit(): void {
    this.origSettings = []
  }

  // Subscribe to Dimensions
  private getAllDimensions(): void {
    this.globalServices.getAllDimensions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ dimensions }) => { this.dimensions = _.cloneDeep(dimensions) },
        complete: () => { this.subscribeToRowData() },
        error: (error) => handleError(this.dialogService, error, 'Error fetching dimensions data')
      })
  }

  private subscribeToRowData(): void {
    console.log("subscribeToRowData 1")
    // if (this.initialRender) {
    this.SCABP_Service.scabp_getAllRows()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ data }) => {console.log("subscribeToRowData 1: ", data); if (data) this.transformRowData(data)},
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: row data')
      })
    // }
    // else {
    //   this.transformRowData(this.addRowData)
    // }
  }

  private subscribeToRowDataById(dim_id): void {
    console.log("here")
    // if (this.initialRender) {
    this.SCABP_Service.getRowByDimId(dim_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ data }) => { if (data) (this.initialRender = false, this.transformRowData([data])) },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: row data by dim_id')
      })
    // }
    // else {
    //   this.transformRowData(this.addRowData)
    // }
  }

  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs, detailColDefs) {
    const params = {
      mainColDefs: mainColDefs,
      detailColDefs: detailColDefs
    }

    const gridData = await SDVPS_GenerateGridData(params);
    if (gridData) {
      console.log("Set Carton and Buy Params col defs: ", gridData.mainColDefs);
      this.columnDefs = gridData.mainColDefs;
      this.detailColumnDefs = gridData.detailColDefs;
    }
  }

  // Format Row Data
  transformRowData(objData) {
    console.log("----- Transforming Row Data-------")
    console.log("Initial Render: ", this.initialRender);
    console.log("Dimensions: ", this.dimensions);
    console.log("Data to be formatted: ", objData)

    const rowData = objData.map((params, i) => {
      let data = params.data
      const dim = this.dimensions.find(dim => dim.id === data.dim_id) || {}
      data.settings.sort(
        function(a, b) {
           if (a.setting_group === b.setting_group) {
              return a.setting_name.localeCompare(b.setting_name);
           }
           return a.setting_group.localeCompare(b.setting_group);
        });
      // Final object for the grid row
      const row = {
        dim_id: data.dim_id,
        settings: data.settings.map(setting => {
          return {
            ...setting,
            og_val: _.cloneDeep(setting.setting_value),
            setting_value: _.cloneDeep(setting.setting_value)
          }
        }) || [],
        highlight: data.highlight,
        error: data.error,
        test_executed: data.highlight ? false : null,
        vendor_packs_available: data.vendor_packs_available,
        test_scenarios: data.test_scenarios,
        test_case_total: data.test_case_total ? data.test_case_total : 0,
        fit_score: data.fit_score,
        gap_pct: data.gap_pct,
        prodId: dim.product_id,
        parent_product_id: dim.product_id, // for filter compatibility
        // TODO: remove
        // product_id: data.dim_id,
        channel: dim.channel,
        size_range: dim.size_range_desc,
      };
      return row;
    });

    if (this.initialRender) {
      console.log("init render: ", this.initialRender)
      this.gridApi.setRowData(rowData)
      console.log("row data: ", rowData)
      this.origSettings = _.cloneDeep(rowData)

    } else {
      console.log("new rowData: ", rowData)
      let updatedRowData = []
      this.gridApi.forEachNode((rowNode, index) => {
        let existingRowId = null
        const updated = rowData.find((row, i) => { if (row.dim_id === rowNode.data.dim_id) existingRowId = i; return row.dim_id === rowNode.data.dim_id })
        if (existingRowId !== null) return (
          rowData.splice(existingRowId, 1),
          updatedRowData.push(updated)
        );
        return updatedRowData.push(rowNode.data)

      });
      if (updatedRowData.length > 0) {
        const updatedData = [...updatedRowData, ...rowData]
        console.log("updated data: ", updatedData)

        this.origSettings = _.cloneDeep(updatedData)
        this.gridApi.setRowData(updatedData)
        this.resetRowAfterUpdate()
      }
    }
  }

  // On Grid Ready
  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.columnApi = event.columnApi;
    this.renderGrid(SCABP_MainColDefs[0], SCABP_DetailColDefs[0])
  }

  // Nested Detail Grid Configuration
  detailCellRendererParams = (parentParams) => {

    const colDefs = this.detailColumnDefs
    return {
      detailGridOptions: {
        ...DefaultDetailGridOptions,
        suppressRowClickSelection: true,
        context: {
          pageTitle: "Set Carton And Buy Parameters",
        },
        columnDefs: colDefs,
      },
    }
  };

  onRowGroupOpened(event: RowGroupOpenedEvent) {
    console.log("toggle expanded: ", event)

    if (!event.node.expanded && Object.keys(this.changesPromoted).length !== 0) {
      // if a row is closed and the changes have not been promoted ->
      // when the row group is closed, overwrite the changed settings with orig settings.
      this.resetRowData(event.node)
    };

  }
  resetRowData(node){
    this.initialRender = false
    this.changesPromoted = {}
    console.log("reset row node: ", node)

    const row = this.gridApi.getRowNode(node.data.dim_id);
    const ogData = this.origSettings.find(setting => setting.dim_id === node.data.dim_id)
    row.setData(ogData)
  }

  // Edit Store Distro Modal
  openEditStoreDistroModal(cell) {
    console.log("cell: ", cell)
    this.SCABP_Service.getStoreDistros(cell.data.dim_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ store_distros }) => {
          if (store_distros) console.log("Store Distros: ", store_distros);

          this.ref = this.dialogService.open(EditStoreDistroModalComponent, {
            showHeader: false,
            styleClass: 'g-large-modal',
            // closeOnEscape: false,
            dismissableMask: false,
            data: {
              rowNode: cell,
              ...store_distros
            },
          });
        },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: store distros')
      })

  }
  // Vendor Packs Modal
  openVendorPacksModal(cell) {
    this.SCABP_Service.getVendorPacks(cell.data.dim_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ vendor_packs }) => {
          if (vendor_packs) console.log("Vendor Packs: ", vendor_packs);
          this.ref = this.dialogService.open(VendorPacksModalComponent, {
            showHeader: false,
            styleClass: 'g-large-modal',
            // closeOnEscape: false,
            dismissableMask: false,
            data: {
              rowNode: cell,
              ...vendor_packs
            },
          });
        },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: vendor packs')
      })

  }

  resetRowAfterUpdate() {
    this.updatingRowData = []
    this.gridApi.redrawRows()
    this.gridApi.deselectAll()
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
