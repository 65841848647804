// Angular Imports
// =========================================================
import { Component} from '@angular/core';
import { FormControl } from '@angular/forms';
// AG Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-dropdown-cell-renderer',
  templateUrl: './dropdown-cell-renderer.component.html',
  styleUrls: ['./dropdown-cell-renderer.component.scss']
})

export class DropdownCellRendererComponent implements ICellRendererAngularComp {
  public params: any;

  optimizationTypes: any[] = []
  selectOptimizationType = new FormControl();
  selected = 'QOPS';

  agInit(params: any): void {
    this.optimizationTypes = params.value
    this.params = params;
  }
  value(event) {
    console.log("value changed: ", event.value)
  }

  public ConfigModalBtn() {
    this.params.context.componentParent.selectOptimizationType(this.params);
  }

  refresh(): boolean {
    return false;
  }

}
