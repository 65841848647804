// Angular, RJX and lodash Imports
// =========================================================
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// Amplify Imports
// =========================================================
// Prime NG Imports
// =========================================================
// Custom Imports
// =========================================================

@Injectable({
	providedIn: 'root',
})
export class TriggerApiCallsService {

	// public clearApiCalls;

	constructor(
	) {
		// this.triggerApiCalls$.subscribe({
		// 	next: (data) => {
		// 		this.clearApiCalls = data['clear_api_calls'];
		// 		console.log("Clear api calls: ", this.clearApiCalls)
		// 	},
		// });
	};

	public triggerApiCalls = new BehaviorSubject({clear_api_calls: false});
	public triggerApiCalls$ = this.triggerApiCalls.asObservable();

	// Tracks and updates globally accessible plan data
	onTriggerApiCalls(data) {
		this.triggerApiCalls.next(data);
	}

}
