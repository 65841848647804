import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClaudeResponse, Message } from 'src/app/components/06_chat/message';
import { Observable } from 'rxjs';
import { API, Auth } from 'aws-amplify';

@Injectable({
    providedIn: 'root',
})

export class ChatService {
    private api = '/api/chat';
    

    private submitMessageURL = `${this.api}`;

    private messageStack: Message[] = []
    // TODO set in env
    private model = 'claude-3-opus-20240229';
    private maxTokens = 1024;
    private apiKey = 'lgBOd3UQD66Jf5djiIAOKaELeABMxPYe2UAdtLBX';

    constructor(private http: HttpClient) { }

    submitMessages(messages: Message[]) {
        this.messageStack = messages;
        return this.sendMessagesToApi();
    }

    sendMessagesToApi() {
        const apiName = 'OrchestratorApiGateway';
        const path = '/chatbot';
        const initBody = {
            body: {
                model: this.model,
                max_tokens: this.maxTokens,
                messages: this.messageStack.map(msg => {
                    return {
                        role: msg.role,
                        content: msg.content
                    }
                })
            },
            headers: {
                'x-api-key': this.apiKey,
                'Content-Type': 'application/json'
            },
        };
        return API.post(apiName, path, initBody);
    }

    wipeMessages() {
        this.messageStack = [];
    }
}
