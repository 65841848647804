

  <!-- Modal Header -->
  <app-modal-header
    [modalTitle]="title"
    [showCloseBtn]="true"
    (closeModal)="close($event)"
  ></app-modal-header>

  <!-- Modal Body -->
  <div class="modal-content loading-modal-body">
    <!-- <div> -->
    <app-loader [loadingMessage]='loadingMessage'></app-loader>
  </div>
  <!-- Modal Footer -->
<app-modal-footer
  [showUpdateBtn]="false"
  (closeModal)="close($event)"
  closeBtnText="Close"
></app-modal-footer>