<div [className]="className">
    <!-- <div class="message-author">
        {{message.role == 'user' ? 'You' : 'Chatbot'}}
    </div> -  -->
    <div class="message-timestamp">
        {{message.timestampFormat}}
    </div>
    <br>
    <div class="message-content">
        {{contentText}}
        <ul *ngIf="dimsFormatted.length">
            <li *ngFor="let dim of dimsFormatted">
                {{dim.productID}} - {{dim.sizeRange}} - {{dim.channel}}
            </li>
        </ul>
    </div>
</div>