import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class CreateTestScenariosService {
    private api = '/api/createTestScenarios';

    private allTests = `${this.api}/allTests`;
    private createTest = `${this.api}/createTest`;
    private deleteTest = `${this.api}/deleteTest`;

    constructor(private http: HttpClient) { }

    getAllTestScenarios() {
        return this.http.get<any>(this.allTests);
    }

    createTestScenario(data) {
        return this.http.put<any>(`${this.createTest}`, data)
    }

    deleteTestScenario(data) {
        return this.http.put<any>(`${this.deleteTest}`, data)
    }
}
