// Angular Imports
// =========================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
// rxjs Imports
// =========================================================
import { Subject, takeUntil } from 'rxjs';
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { CheckForDuplicates } from 'src/app/core/utils/form-validators'
import { GetFormErrorMessage } from 'src/app/core/utils/form-error-messages';
import { FormatKey } from 'src/app/core/utils/global-functions';
import { handleError } from 'src/app/core/utils/error-handling';
import { GlobalServices } from 'src/app/core/services/db/global-services.service';

@Component({
    selector: 'app-tsc-create-modal',
    templateUrl: './test-scenario-create-modal.component.html',
    styleUrls: ['./test-scenario-create-modal.component.scss']
})

export class TestScenarioCreateModalComponent implements OnInit, OnDestroy {
    private unsubscribe$ = new Subject<void>();
    modalTitle = "Create A New Test Scenario";

    createTestScenarioForm: FormGroup;
    testsCreated: any[] = [];
    dim_id: number = null
    storeDistrosList: any = [];
    currentTestNames: any[] = [];
    isLoading: boolean = true; 

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private formBuilder: FormBuilder,
        private globalServices: GlobalServices,
        public dialogService: DialogService,
    ) {
      const{data, store_distro} = this.config.data
      console.log(data)
      console.log(data.test_cases)
      console.log("store_distros master", store_distro)
      console.log("constructor for test scenario create modal")
      this.dim_id = data.dim_id
      this.currentTestNames = data.test_cases?.map(test => FormatKey(test.name))
      // this.storeDistrosList = new Set(store_distro?.distros)
      // console.log("store_distros: ", this.storeDistrosList )
      this.globalServices.getStoreDistrosByDimId(this.dim_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data) => {
          this.storeDistrosList = data.store_distro.map(item => item.store_distro)
          if (!this.storeDistrosList) {
            // TODO handle
            console.error("No store distros for dim id", this.dim_id)
            return
          }
          this.storeDistrosList?.sort((a, b) => {
            return a.localeCompare(b)
          })
        },
        complete: () => {
          this.isLoading = false;
        },
        error: (error) => {
          console.error(error)
          handleError(this.dialogService, error, 'Error fetching All Store Distros')
        }
      })
    }

    ngOnInit(): void {
        console.log("Create a new Test Scenario Modal: ", this.config.data.data)
        this.createTestScenarioForm = this.formBuilder.group({
            name: ['', [Validators.required, CheckForDuplicates(this.currentTestNames)]],
            store_distro: ['', [Validators.required]],
            size_of_buy: ['', [Validators.required, Validators.min(0)]],
        })
        console.log("Created form")
    }

  // convenience getter for easy access to form fields
  get form() {
    console.log("Getting form")
    return this.createTestScenarioForm.controls;
  }
  setFormError(controlName) {
      // console.log("listed errors: ", this.createTestScenarioForm)
        const params = {
          controlName: controlName,
          form:  this.createTestScenarioForm,
          customMessages: {}
        }
        return GetFormErrorMessage(params)
      }
    // Save and Add another test
  onSaveAndReset(formDirective: FormGroupDirective) {
    const form = this.createTestScenarioForm.value
    this.currentTestNames.push(FormatKey(form.name))
      const testCaseData = {...form, dim_id: this.dim_id}
        this.testsCreated.push(testCaseData)
        // Reset the form fields
        this.createTestScenarioForm.reset()
        formDirective.resetForm()
    }
    onSave(form) {
      const testCaseData = {...form, dim_id: this.dim_id}
        this.testsCreated.push(testCaseData)
        this.close()
    }
    close() {
        this.testsCreated.length === 0 ? this.ref.close() : this.ref.close(this.testsCreated)
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
