import value from '*.json';

export const MapSizes = (data) => {
  const value = Object.keys(data).map((index) => {
    let newObject = {};
    Object.entries(data[index]).map(([key, value]) => {
      if (key === "Sizes") {
        [value].map((size) => Object.assign(newObject, size));
      } else {
        Object.assign(newObject, { [key]: value }), {};
      }
    });
    return newObject;
  });
  return value;
}
// Format Keys for Objects
export const FormatKey = (key) => key === null ? key : key.replaceAll(" ", "_").toLowerCase();
// Function to format keys to prevent text case issues
export function ReverseFormatKey(key) { return key.replaceAll('_', ' ') }
// Check Object for keys
export const IsKeyInObj = (value_1, value_2) => Object.keys(value_1).includes(value_2);
// Is value a number
export const CheckIsNumber = (total) => {
  return isNaN(total) || isNaN(total) || !isFinite(total) || total === Number("-0") ? 0 : total;
}
// Function to format and generate values from row Data
export function GetValue(data) {
  const checkObject = (value) => value && typeof value === "object" ? checkObject(value.value) : CheckIsNumber(value)
  return checkObject(data)
}

export const GetDifferenceInDates = (date_1, date_2) => {
  // To calculate the time difference of two dates
  const diff_In_Time = date_1.getTime() - date_2.getTime();
  // To calculate the no. of days between two dates
  const diff_In_Days = diff_In_Time / (1000 * 3600 * 24);
  return diff_In_Days
}

export const SortSizes = (sizeArray, arrayToSort, key?: string) => {
  const objKey = key ? key : 'sizeNm'
  const sizeOrder = sizeArray.sort((a, b) => a.size_order - b.size_order)
  return arrayToSort.sort((a, b) => {
      return sizeOrder.findIndex((size) => size.product_size === a[objKey]) - sizeOrder.findIndex((size) => size.product_size === b[objKey])
    });
}
export const FormatChartValuePair = (coordinates) => {
  const values = coordinates?.match(/\[(.*?)\]/)
  const valuePair = values ? values[1]?.split(',').map(val => Number(val)) : null

  if (valuePair) return valuePair;
  return null;
}

// export function toPct(number) {
//   return number * 100 + "%";
// }

// export function format100Pct(num: number) {
//   if (!num) return "0%"; // for undefined and null
//   return num + "%";
// }

export function formatPct(num: number, outOf1 = false) {
  if (!num) return "0%"; // for undefined and null
  let result: any = num;
  if (outOf1) {
    result = (num * 100).toFixed(2);
    if (result.includes(".")) {
      if (result.endsWith("0")) {
        result = result.substring(0, result.length - 1);
      }
      if (result.endsWith("0")) {
        result = result.substring(0, result.length - 2);
      }
    }
  }
  return result + "%";
}

export const GenerateRandomColor = (colorsToExclude) => {
  const acceptableColors = ["#ffcdd2","#ef9a9a","#e57373","#ef5350","#f44336","#e53935","#d32f2f","#c62828","#b71c1c","#ff8a80","#ff5252","#ff1744","#d50000","#f8bbd0","#f48fb1","#f06292","#ec407a","#e91e63",
"#d81b60","#c2185b","#ad1457","#880e4f","#ff80ab","#ff4081","#f50057","#c51162","#e1bee7","#ce93d8","#ba68c8","#ab47bc","#8e24aa","#7b1fa2","#6a1b9a","#4a148c","#ea80fc","#e040fb","#aa00ff","#d1c4e9","#b39ddb","#9575cd","#7e57c2","#673ab7","#512da8","#311b92","#7c4dff","#6200ea","#c5cae9","#9fa8da","#7986cb","#5c6bc0","#3f51b5","#3949ab","#283593","#1a237e","#8c9eff","#536dfe","#90caf9","#64b5f6","#42a5f5","#2196f3","#1e88e5","#1976d2","#1565c0","#0d47a1","#82b1ff","#448aff","#2979ff","#2962ff","#4fc3f7","#29b6f6","#039be5","#0288d1","#0277bd","#01579b","#40c4ff","#00b0ff","#0091ea","#80deea","#4dd0e1","#26c6da","#00bcd4","#00acc1","#0097a7","#00838f","#00e5ff","#00b8d4","#80cbc4","#4db6ac","#26a69a","#009688","#00897b","#00796b","#00695c","#004d40","#00bfa5","#a5d6a7","#81c784","#66bb6a","#4caf50","#43a047","#388e3c","#2e7d32","#1b5e20","#00c853","#aed581","#8bc34a","#7cb342","#689f38","#558b2f","#33691e","#64dd17","#c0ca33","#afb42b","#9e9d24","#827717","#a3da05","#ead837","#fdd835","#fbc02d","#f9a825","#f57f17","#ffd54f","#ffca28","#ffc107","#ffb300","#ffa000","#ff8f00","#ff6f00","#ffd740","#ffc400","#ffab00","#ffcc80","#ffb74d","#ffa726","#ff9800","#fb8c00","#f57c00","#ef6c00","#e65100","#ffab40","#ff9100","#ff6d00","#ffccbc","#ffab91","#ff8a65","#ff7043","#ff5722","#f4511e","#e64a19","#d84315","#bf360c","#ff9e80","#ff6e40","#ff3d00","#dd2c00","#bcaaa4","#a1887f","#8d6e63","#795548","#6d4c41","#5d4037","#4e342e","#3e2723"].filter((hex, i) => {
  if(!colorsToExclude.includes(hex)) {
    return hex
  }
});

  const color = acceptableColors.length > 0 ? acceptableColors[Math.floor(Math.random() * acceptableColors.length)] : "	#800000"
  return color;
};

export function stripTimestamp(timestamp: string): string {
  return new Date(timestamp).toLocaleDateString();
}

export const isLocalhost = Boolean(

  window.location.hostname === "localhost" ||

    // [::1] is the IPv6 localhost address.

    window.location.hostname === "[::1]" ||

    // 127.0.0.1/8 is considered localhost for IPv4.

    window.location.hostname.match(

    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/

    )

  );
export const isCloudfront = Boolean(

  window.location.hostname.includes('.cloudfront.net')
  
);

export const redirectSignIn = (awsconfig) => {
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production

  const localRedirectSignIn = awsconfig.oauth.redirectSignIn.split(",")[0];

  const productionRedirectSignIn = isCloudfront ? awsconfig.oauth.redirectSignIn.split(",")[1] : awsconfig.oauth.redirectSignIn.split(",")[2];

  const redirectSignIn = isLocalhost ? localRedirectSignIn : productionRedirectSignIn;

  return redirectSignIn;
}

export const redirectSignOut = (awsconfig) => {
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production


  const localRedirectSignOut = awsconfig.oauth.redirectSignOut.split(",")[0];

  const productionRedirectSignOut = isCloudfront ? awsconfig.oauth.redirectSignOut.split(",")[1] : awsconfig.oauth.redirectSignOut.split(",")[2];

  const redirectSignOut = isLocalhost ? localRedirectSignOut : productionRedirectSignOut;
  
  return redirectSignOut;
}
