<div class="chat-window">
    <div class="chat-header">
        <div class="header-title">Chat</div>
        <div class="header-buttons">
            <button mat-raised-button (click)="toggleMinimize()">
                <mat-icon>{{isMinimized ? 'unfold_more' : 'unfold_less'}}</mat-icon>
            </button>
            <button mat-raised-button (click)="endSession()">
                End Session
            </button>
        </div>
    </div>
    <div class="chat-body" *ngIf="!isMinimized">
        <div class="message-area" id="message-area">
            <app-chat-message *ngFor="let message of messages | slice:1" [message]="message">
            </app-chat-message>
        </div>
        <div class="input-area">
            <form (ngSubmit)="onSubmit()" required>
                <div class="input-wrapper">
                    <input id="chat-input" pInputText [formControl]="chatTextInput" [disabled]="chatIsDisabled"/>
                </div>
                <div class="button-wrapper">
                    <button mat-raised-button class="button" type="submit" for="chat-input" [disabled]="chatTextInput.value == '' || chatIsDisabled"><mat-icon>send</mat-icon></button>
                </div>
            </form>
        </div>
    </div>
</div>