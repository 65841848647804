// Angular Imports
// =========================================================
import { ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';
import {
    NavigationEnd,
    NavigationStart,
    Router,
    RoutesRecognized,
} from '@angular/router';
// Custom Imports
// Amplify
import { Amplify } from 'aws-amplify';
// NG Idle
// =========================================================
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
// Prime NG (UI) Imports
// =========================================================
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { RouterHistoryService } from './core/services/routing-services/router-history-service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { CognitoService } from './core/services/auth/auth-cognito.service';
import { PrimeNgOverlayPanelFixService } from './core/services/overlay.service';
import { handleError } from './core/utils/error-handling';
import awsconfig from '../aws-exports';
import { environment } from 'src/environments/environment';
import { redirectSignIn, redirectSignOut } from 'src/app/core/utils/global-functions';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [DialogService]
})
export class AppComponent {
    title = 'qw-web-services-app';
    idleState = 'NOT_STARTED';
    countdown?: number = null;
    lastPing?: Date = null;
    sessionWarning: boolean = false;
    user: any;
    previousUrlViaNavigationEnd$ = new BehaviorSubject<string>(null);
    currentUrlViaNavigationEnd$ = new BehaviorSubject<string>(null);
    previousUrlViaRoutesRecognized$ = new BehaviorSubject<string>(null);
    currentUrlViaRoutesRecognized$ = new BehaviorSubject<string>(null);
    // Via RouterHistoryService
    previousUrlViaRouterHistoryService$ = this.routerHistoryService.previousUrl$;
    currentUrlViaRouterHistoryService$ = this.routerHistoryService.currentUrl$;
    logs: { event: string; message: string }[] = [];
    // isLocalhost = Boolean(
    //     window.location.hostname === "localhost" ||
    //       // [::1] is the IPv6 localhost address.
    //       window.location.hostname === "[::1]" ||
    //       // 127.0.0.1/8 is considered localhost for IPv4.
    //       window.location.hostname.match(
    //         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    //       )
    //   );
    //   // Assuming you have two redirect URIs, and the first is for localhost and second is for production
    //   localRedirectSignIn = awsconfig.oauth.redirectSignIn.split(",")[0];
    //   productionRedirectSignIn = awsconfig.oauth.redirectSignIn.split(",")[1];
    //   localRedirectSignOut = awsconfig.oauth.redirectSignOut.split(",")[0];
    //   productionRedirectSignOut = awsconfig.oauth.redirectSignOut.split(",")[1];
    constructor(
        public router: Router,
        private routerHistoryService: RouterHistoryService,
        public authenticator: AuthenticatorService,
        public primeNgOverlayPanelFixService: PrimeNgOverlayPanelFixService,
        public cognitoService: CognitoService,
        public ref: DynamicDialogRef,
        public dialogService: DialogService,
        public config: DynamicDialogConfig,
        private idle: Idle,
        keepalive: Keepalive,
        cd: ChangeDetectorRef,
    ) {
        // Event logging only
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.logs.push({
                    event: 'NavigationStart',
                    message: JSON.stringify(event),
                });
            }
            if (event instanceof NavigationEnd) {
                this.logs.push({
                    event: 'NavigationEnd',
                    message: JSON.stringify(event),
                });
            }
        });
        // Via Navigation End Event
        this.currentUrlViaNavigationEnd$.next(router.url);
        router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.previousUrlViaNavigationEnd$.next(
                    this.currentUrlViaNavigationEnd$.value
                );
                this.currentUrlViaNavigationEnd$.next(event.urlAfterRedirects);
            }
        });
        // Via RoutesRecognized
        router.events
            .pipe(
                filter((evt) => evt instanceof RoutesRecognized),
                pairwise()
            )
            .subscribe(
                ([previous, current]: [RoutesRecognized, RoutesRecognized]) => {
                    this.previousUrlViaRoutesRecognized$.next(previous.urlAfterRedirects);
                    this.currentUrlViaRoutesRecognized$.next(current.urlAfterRedirects);
                }
            );
        this.cognitoService.triggerUserUpdate$.subscribe((user) => {
            console.log('user: ', user);
            if (this.user !== user) {
                this.user = user;
            };
            if (!this.user){
                this.router.navigateByUrl('/');
                this.cognitoService.authFederatedSignIn();
            };
            // If a check has been run to verify the user -> reset the idle tracker
            if (this.user !== null) this.reset();
            // if (!this.user && this.router.url !== '/') this.router.navigateByUrl('/');
        });
        this.cognitoService.hubListener();
        //TODO: set to 1800 -> 30 minutes
        idle.setIdle(1740); // how long can they be inactive before considered idle, in seconds
        idle.setTimeout(60); // how long can they be idle before considered timed out, in seconds
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        keepalive.interval(600); // will ping at this interval while not idle, in seconds
        // TODO: Remove after testing
        // idle.setIdle(10); // how long can they be inactive before considered idle, in seconds
        // idle.setTimeout(10); // how long can they be idle before considered timed out, in seconds
        // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active
        // keepalive.interval(15); // will ping at this interval while not idle, in seconds
        // User becomes idle
        idle.onIdleStart.subscribe(() => {
            this.idleState = 'IDLE';
            console.log('user is idle ');
        });
        // User is no longer idle
        idle.onIdleEnd.subscribe(async () => {
            try {
                this.idleState = 'NOT_IDLE';
                console.log(`${this.idleState} ${new Date()}`);
                await this.ref.close();
                await this.ref.destroy();
            } catch (error) {
            } finally {
                // console.log("this.ref: ", this.dialogService.dialogComponentRefMap.map())
                // console.log("this.ref: ", this.ref)
                this.countdown = null;
                cd.detectChanges(); // how do i avoid this kludge?
            }
        });
        // User's has timed out
        idle.onTimeout.subscribe(() => {
            console.log('Session Expired');
            this.idleState = 'TIMED_OUT';
        });
        // do something as the timeout countdown does its thing
        idle.onTimeoutWarning.subscribe((seconds) => {
            this.countdown = seconds;
            // console.log('count down: ', this.countdown);
            if (!this.sessionWarning) {
                this.sessionWarning = true;
                handleError(
                    this.dialogService,
                    { status: 403, statusText: 'Inactive User' },
                    `Your session will expire in`
                );
            }
        });
        // Keep the user alive  / refresh cognito tokens
        keepalive.onPing.subscribe(() => {
            this.lastPing = new Date();
            console.log('Active User: ', (this.lastPing = new Date()));
            this.cognitoService.refreshToken();
            if (this.sessionWarning) {
                this.sessionWarning = false;
            }
        });
    }
    ngOnInit() {
        Amplify.configure({
            Auth: {
                region: awsconfig.aws_cognito_region,
                userPoolId: awsconfig.aws_user_pools_id,
                userPoolWebClientId: awsconfig.aws_user_pools_web_client_id
            },
            ...awsconfig,
            oauth: {
                ...awsconfig.oauth,
                // redirectSignIn: this.isLocalhost ? this.localRedirectSignIn : this.productionRedirectSignIn,
                // redirectSignOut: this.isLocalhost ? this.localRedirectSignOut : this.productionRedirectSignOut,
                redirectSignIn: redirectSignIn(awsconfig),
                redirectSignOut: redirectSignOut(awsconfig),
              },
            endpoints: [
                {
                    name: 'OrchestratorApiGateway',
                    endpoint:
                        `https://${environment.apiServiceId}.execute-api.us-east-1.amazonaws.com/${environment.stage}`,
                    custom_header: async () => {
                        return {
                            Authorization: 'token',
                        };
                    },
                },
            ],
        });
    }
    // Call this method to start/reset the idle process
    reset() {
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = 'NOT_IDLE';
        this.countdown = null;
        this.lastPing = null;
    }
}