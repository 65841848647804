
<!-- <div  class="flex-center-all" style="margin-top: 20px; height: 50px; background-color: rgb(1,32,126)"> -->
<div style="display: flex; flex-direction: column; height: 100%">

  <!-- Action Buttons Container -->
	<div *ngIf="importTabData.id === 'userInput'" class="g-grid-tab-actions-container">
		<button mat-raised-button class="submit-btn m-right-10"
			(click)="onMarkPending()" [matTooltip]="pendingDisabledMessage"
			[disabled]="approvePendingDisabled">
			Mark as Pending Review
		</button>
		<button mat-raised-button class="submit-btn m-right-10"
			(click)="onApprove()" [matTooltip]="approveDisabledMessage"
			[disabled]="approvePendingDisabled">
			Approve
		</button>
		<button mat-raised-button class="submit-btn m-right-10"
			(click)="onExport()" [matTooltip]="exportDisabledMessage"
			[disabled]="exportDisabled">
			Export
		</button>
		<button mat-raised-button class="submit-btn-dark-reverse m-right-10"
			(click)="onCalculateBreaks()" [matTooltip]="calcBreaksDisabledMessage"
			[disabled]="!canViewData">
			Calculate Breaks
		</button>
		<button mat-raised-button class="submit-btn-dark-reverse m-right-10"
			(click)="onViewData()" [matTooltip]="viewDataDisabledMessage"
			[disabled]="!canViewData">
			View Data
		</button>
	</div>
	<!-- Selection Dropdowns -->
	<div *ngIf="importTabData.selectStyleColor || importTabData.selectNeedDate"
		class="g-grid-tab-actions-container">
		<!-- Select Style Color -->
		<!-- <mat-form-field *ngIf="importTabData.selectStyleColor" appearance="standard">
			<mat-label>Select a Style-Color</mat-label>
			<mat-select [(value)]="inputLinesSelectedRows.styc_id" (selectionChange)="setDropdownValueForGrids($event)">
				<mat-option *ngFor="let styleColor of selectableStycIds" [value]="styleColor">{{
					styleColor
				}}</mat-option>
			</mat-select>
		</mat-form-field> -->
		<!-- Select Delivery Date -->
		<mat-form-field *ngIf="importTabData.selectNeedDate" appearance="standard">
			<mat-label>Select a Delivery Date</mat-label>
			<mat-select [(value)]="selectedNeedDate" (selectionChange)="setDropdownValueForGrids($event)">
				<mat-option *ngFor="let needDate of needDates" [value]="needDate">{{
					needDate
				}}</mat-option>
			</mat-select>
		</mat-form-field>
	</div>

<!-- AG Grid -->
<div class="ag-grid-wrapper">
	<ag-grid-angular
		#agGrid
		id="review-orders-grid"
		class="ag-theme-alpine ag-grid-container"
		[gridOptions]="gridOptions"
		[columnDefs]="columnDefs"
		(gridReady)="onGridReady($event)"
		[groupRemoveLowestSingleChildren]="false"
		[treeData]="renderTreeDataGrid"
		[debug]="true"
	></ag-grid-angular>
</div>
</div>


