// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================

@Component({
  selector: 'app-pack-summary-config',
  templateUrl: './pack-summary-config.component.html',
  styleUrls: ['./pack-summary-config.component.scss']
})
export class PackSummaryConfigComponent implements OnInit {

  modalTitle: string = ''
  configData: any[] = []
  data: any;
  total = 0

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    console.log("this.config", this.config)
    this.data = this.config.data
    // console.log("data: ", this.data)
    this.modalTitle = `Carton Configuration: ${this.data.selectedRows['carton_id']}`
    this.configData = this.data.selectedRows.config
    this.getTotal()
  }
  getTotal(){
    this.configData.map(key => {this.total +=  Number(key.inner_carton_qty)  })
  }

  close() {
    console.log("close modal")
    this.ref.close()
  }
}
