import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class SetCartonAndBuyParamsService {
    private api = '/api/setCartonAndBuyParams';

    private allRows = `${this.api}/allRows`;
    private singleRows = `${this.api}/singleRow`;
    private vendorPacks = `${this.api}/vendorPacks`;
    private storeDistros = `${this.api}/storeDistros`;
    private add = `${this.api}/addStoreDistros`;
    private delete = `${this.api}/deleteStoreDistros`;
    private getTestScenariosData = `${this.api}/getRunTestScenariosData`;
    private updateParamSettings = `${this.api}/updateParameterSettings`;
    private update_VendorPacks = `${this.api}/updateVendorPacks`;
    private detail_Changes = `${this.api}/promoteChanges`;
    private testStatus = `${this.api}/checkTestStatus`;
    private removeTests = `${this.api}/removeTestResults`;
    private runTestScenariosInsertJob$ = `${this.api}/runTestScenariosInsertJob`;

    constructor(private http: HttpClient) { }

    scabp_getAllRows() {
        return this.http.get<any>(this.allRows);
    }
    getRowByDimId(dim_id) {
        return this.http.get<any>(`${this.singleRows}/${dim_id}`);
    }
    updateParameterSettings(data) {
        return this.http.put<any>(`${this.updateParamSettings}`, data);
    }
    getVendorPacks(dim_id) {
        return this.http.get<any>(`${this.vendorPacks}/${dim_id}`);
    }
    updateVendorPacks(data){
        return this.http.put<any>(`${this.update_VendorPacks}`, data)
    }
    getStoreDistros(dim_id) {
        return this.http.get<any>(`${this.storeDistros}/${dim_id}`);
    }
    addStoreDistros(data) {
        return this.http.put<any>(`${this.add}`, data)
    }
    deleteStoreDistros(data) {
        return this.http.put<any>(`${this.delete}`, data)
    }
    runTestScenariosInsertJob(dim_id) {
        return this.http.put<any>(`${this.runTestScenariosInsertJob$}`, {dim_id: dim_id});
    }
    getRunTestScenariosData(dim_id: number) {
        return this.http.get<any>(`${this.getTestScenariosData}/${dim_id}`);
    }
    promoteChanges(data) {
        return this.http.put<any>(`${this.detail_Changes}`, data);
    }
    checkTestStatus(data) {
        return this.http.put<any>(`${this.testStatus}`, data);
    }
    removeTestResults(data) {
        return this.http.put<any>(`${this.removeTests}`, data);
    }
}
