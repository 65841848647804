<!-- Custom Text Btn -->
<span tabIndex="1" *ngIf="!groupRow" [ngClass]="{'flex-center-all': true}" style="height: 100%">
  <!-- Text Btn -->
  <button
    *ngIf="!showIcon && btnText !== 'View/Edit' && btnText !== 'View'"
    mat-raised-button
    [ngClass]="{'submit-btn': true,
    'material-icons': labelIsIcon}"
    (click)="EmitBtn($event)"
    matTooltip="{{ btnText }}"
    matTooltipClass="custom-btn-tooltip"
    style="padding: 4px; line-height: 14px !important; margin-top: 8px"
  >
    {{btnText}}
  </button>
      <button
      *ngIf="showIcon && !hideBtn"
      mat-icon-button
      [ngClass]="{'icon-button': !btnDisabled}"
      [disabled]="btnDisabled"
      matTooltip="{{ toolTipMessage }}"
      matTooltipClass="openModal-btn-tooltip"
      (click)="EmitBtn($event)"
    >
      <span [ngSwitch]="iconName" style="height: 100%">
        <!-- open-modal|settings -->
        <span *ngSwitchCase="'settings'" class="material-icons launch-icon align-icon-center"> launch </span>
        <!-- action-button|add -->
        <span  *ngSwitchCase="'add'" class="material-icons library_add-icon align-icon-center"> library_add </span>
        <!-- action-button|delete -->
        <span  *ngSwitchCase="'delete'" class="material-icons delete-icon align-icon-center"> delete </span>
        <!-- action-button|execute -->
        <span *ngSwitchCase="'execute'" class="material-icons sync-icon align-icon-center"> sync </span>
        <!-- action-button|charts -->
        <span *ngSwitchCase="'chart'" class="material-icons chart-icon align-icon-center"> insights </span>
      </span>
    </button>

</span>
<span *ngIf="groupRow" [ngClass]="{'flex-center-all': true}" style="height: 100%">
  <p>--</p>
</span>