
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { ErrModalComponent } from '../../components/03_modals/03_error-modal/err-modal.component';

let modalOpen = false
let ref: DynamicDialogRef

export function handleError(dialog: DialogService, error: any, message: string) {
  console.error(message, ": ", error)

  modalOpen ? (ref.close(), ref.destroy(), modalOpen = false) : null
  const title = error.status && error.statusText ? `${error.status} ${error.statusText}` : `${error.status}` ? error.status : `Error`
    let standardError = {
            title: title,
            errorStatus: `${error.status}`,
            timeStamp: `${new Date().toLocaleString()}`,
            errorMessage: error.message || message,
            logout: error.status === 401 || error.status === 503,
            customMessage: message
    }
    if( message !== 'Modal' && !modalOpen){
        modalOpen = true

        ref = dialog.open(ErrModalComponent, {
            showHeader: false,
            styleClass: "error-modal",
            closeOnEscape: false,
            dismissableMask: false,
            data: { error: standardError }
         });

    }
}


