<span
	style="display: flex; align-items: center; justify-content: space-between"
>
	{{value}}
	<span [ngSwitch]="iconName" style="height: 100%; display: flex">
		<!-- Edit Icon -->
		<span
			*ngSwitchCase="'edit'"
			class="icon-button material-icons edit-icon align-icon-center"
		>
			edit
		</span>
		<!-- Select Icon -->
		<span
			*ngSwitchCase="'select'"
			class="icon-button material-icons arrow_drop_down align-icon-center"
      style="font-size: 36px;"
		>
    arrow_drop_down
		</span>
	</span>
</span>
