<input
  #input
  id="auto-input-delimiter"
  [(ngModel)]="inputValue"
  (ngModelChange)="updateInput($event)"
  (keydown)="onKey($event)"
  (blur)="formatFinalValue($event)"
  style="height: 28px; font-weight: 400; font-size: 12px; height: 100%"
  [style.width]="params.column.actualWidth + 'px'"
/>
<!-- [style.height]="params.column.actualHeight + 'px'" -->
