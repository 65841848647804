// Angular Imports
// =========================================================
import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
// PrimeNg Imports
// =========================================================
import {
	CustomGridOptions,
	DefaultGridContextMenu,
} from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
// JSON Data
// =========================================================
import UploadNewSizeOfferingMainColDefs from '../../../../../core/json/define_size_offerings/colDefs-main-upload_new_size_offerings.json';
// Custom Imports
// =========================================================
import {
	GridReadyEvent,
	GridApi,
	ColumnApi,
	ProcessCellForExportParams,
	CellValueChangedEvent,
	CellEditingStoppedEvent,
	RowDataUpdatedEvent,
} from 'ag-grid-community';
import { UploadNewSizeOfferings_GenerateGridData } from 'src/app/components/02_ag-grid-settings/02_ag-generate-col-Defs/define-size-offerings/upload-new-size-offerings-main-grid-config';
import { FormatKey } from 'src/app/core/utils/global-functions';
import { UploadNewSizeOfferingsService } from 'src/app/core/services/define-size-offerings-services/upload-new-size-offerings-service';
import { FilterService } from 'src/app/core/services/filter.service';
import { LowestLevelFilterInterface } from 'src/app/core/utils/interfaces/lowest-level-filters.interface';

@Component({
	selector: 'app-upload-new-size-offerings',
	templateUrl: './upload-new-size-offerings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./upload-new-size-offerings.component.scss'],
})

export class UploadNewSizeOfferingComponent implements OnInit {
	private unsubscribe$ = new Subject<void>();
	public popupParent: HTMLElement | null = document.querySelector('body');
	// Ag Grid Configuration
	private customGridOptions: any = {
		...CustomGridOptions,
		context: {
			componentParent: this,
			pageTitle: 'Manage Size Offerings',
			deleteSizeOffering: (params) => this.onDeleteRow(params),
		},
    };

    @Input() storedRowData: any = []
    @Input() sumbittingSizeRanges: boolean

	objectKeys = Object.keys;

	// Ag Grid Configuration
	columnDefs;
	gridApi: GridApi;
	gridColumnApi: ColumnApi;
	gridOptions: any = {};
	rowData = [];

	data: any = [];
	headers: any[] = [];
	wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
	fileName: string = 'SheetJS.xlsx';
	dlmOpts: string[] = ['DLM = 1', 'DLM = 2', 'DLM = 3', 'DLM = 4'];

	requiredFields = ['season', 'channel', 'prodId', 'sizeRange'];
	autoSelectOptions: LowestLevelFilterInterface;
	tableResetDisabled: boolean = true;

	constructor(
		public filterService: FilterService,
		public uploadNewSizeOfferingsService: UploadNewSizeOfferingsService
	) {
		// Subscribe to side panel filters
		this.filterService
			.getFilterOptions()
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data: LowestLevelFilterInterface) => {
				this.autoSelectOptions = data;
			});

		this.gridOptions = {
			...this.customGridOptions,
			frameworkComponents: {
				...this.customGridOptions.frameworkComponents,
			},
			rowClassRules: {
                'parent-row-warning': (params) => {
                    const status = params.data?.status && params.data.status.toLowerCase() || null
					return status === 'failed' || (status && status.includes('error'))},
            },
			onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
			getRowNodeId: (data) => data?.id,
			getContextMenuItems: (params) => this.newSizeOfferingsContextMenu(params),
			onCellEditingStopped: (event: CellEditingStoppedEvent) =>
				this.checkRowData(event),
			onRowDataUpdated: (event: RowDataUpdatedEvent) =>
				this.checkRowData(event),
			singleClickEdit: true,
            overlayNoRowsTemplate:
            `
            <div class="ag-overlay-loading-center">
              <div class="far fa-frown">
              Please do one of the following:
                <ol style="text-align: start;
                padding-right: 20px;"
            }>
                    <li> Add A Blank Row </li>
                    <li> Upload a .csv or .xlsx file </li>
                    <li> Download the correctly formatted template </li>
                </ol>
              </div>
            </div>`,
		};
	}

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges) {
        console.log("CHANGES: ", changes);
      }

	// when AG Grid is done with initial processing, run the following functions
	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		this.gridApi.showLoadingOverlay();

		this.renderGrid(UploadNewSizeOfferingMainColDefs[0]);
	}
	@HostListener('keydown', ['$event'])
	onKeydown(event) {
		event.stopPropagation();
		if (event.key == "Escape") {
			return false;
		}
		if (event.key == "Enter" || event.key == "Tab") {
			return false;
		}
		if (event.key == "ArrowUp" || event.key == "ArrowDown") {
			return false;
		}
	}
	newSizeOfferingsContextMenu(params) {
		const actionsToExclude = ['excelExport', 'csvExport'];
		const filteredMenuItems = DefaultGridContextMenu(params).filter(
			(item) =>
				!actionsToExclude.includes(
					typeof item === 'string' ? item : item['name']
				)
		);

		const exportGridData = (fileType: string) => {
			const filePath = fileType === 'excel' ? 'xlsx' : 'csv';
			const fileParams = {
				columnKeys: ['season', 'channel', 'prodId', 'sizeRange', 'status'],
				allColumns: false,
				fileName: 'New Size Offerings.' + filePath,
				skipHeader: false,
			};

			if (fileType === 'excel') return params.api.exportDataAsExcel(fileParams);
			if (fileType === 'csv') return params.api.exportDataAsCsv(fileParams);
		};
		const result = [
			...filteredMenuItems,
			{
				name: 'Excel Export (.xlsx)',
				action: () => exportGridData('excel'),
			},
			{
				name: 'CSV Export',
				action: () => exportGridData('csv'),
			},
		];

		return result;
	}

	//Render AF Grid Column Definitions
	async renderGrid(mainColDefs) {
		const params = {
			mainColDefs: mainColDefs,
		};

		const gridData = await UploadNewSizeOfferings_GenerateGridData(params);

		if (gridData && !this.gridApi['destroyCalled']) {
			console.log(
				'Upload New Size Offerings Column Defs: ',
				gridData.mainColDefs
			);

			this.columnDefs = gridData.mainColDefs;

			this.gridApi.setColumnDefs(this.columnDefs);
			this.gridApi.setRowData(this.storedRowData);
		}
	}

    onAddRow(error?) {
        const setError = error || {}
		this.gridApi.applyTransaction({
			add: [
				{
					season: '',
					channel: '',
					prodId: '',
					sizeRange: '',
					status: '',
                    id: uuidv4(),
                    ...setError
				},
            ],
            addIndex: 0
        });
		this.tableResetDisabled = false;
	}

	onResetTable() {
		this.tableResetDisabled = true;
		this.gridApi.setRowData([]);
	}

 	onDeleteRow(params) {
		console.log('delete a row: ', params);
		this.gridApi.applyTransaction({ remove: [{ id: params.node.data.id }] });
	}

    onFileChange(event: any, form) {
        // Format headers
        const formatHeader = (header) => {
            const tempHeader = FormatKey(header);

            switch (tempHeader) {
                case 'product_id':
                    return 'prodId';
                case 'size_offering':
                    return 'sizeRange';
                default:
                    return tempHeader;
            }
        };
        // format fields for error messages
        const getFieldName = (field) => {
            switch (field) {
                case 'prodId':
                    return 'PRODUCT ID';
                case 'sizeRange':
                    return 'SIZE OFFERING';
                default:
                    return field.toUpperCase();
            }
        }

		/* wire up file reader */
		const target: DataTransfer = <DataTransfer>event;
		Object.keys(event.currentFiles).forEach((key) => {
            const reader: FileReader = new FileReader();

			reader.onload = (e: any) => {
				/* read workbook */
				const bstr: string = e.currentTarget.result;

				const wb: XLSX.WorkBook = XLSX.read(bstr, {
					 type: 'binary',
					 FS: ','
				});

				/* grab first sheet */
				const wsname: string = wb.SheetNames[0];
				const ws: XLSX.WorkSheet = wb.Sheets[wsname];

				/* save data */
				this.data = <any>XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
                /* Transform data  */
				if (this.data) {
					console.log('Form Data: ', this.data);
					// check to make sure that the headers from the file match the required fields for the grid
					const formattedHeaders = this.data[0].map((header) => formatHeader(header));
					const correctHeaders = formattedHeaders.filter((header) => this.requiredFields.includes(header));

                    if (this.requiredFields.length === correctHeaders.length) {
                        // Format data for AG Grid table
						const mappedData = Object.entries(this.data).map(
                            ([key, value], rowI) => {
                                // The headers will always be the first item in the array
								if (key === '0') {
									return { headers: correctHeaders };
								} else {
                                    const tempRow = {};
                                    // Loop through the required fields to make sure that the correct information is present
									this.requiredFields.map((field, i) => {
										const fieldIndex = formattedHeaders.indexOf(field);
                                        const cellValue = value[fieldIndex];
                                        // Function to generate error messages if data is missing or incorrect data is trying to be uploaded
                                        const setRowDataUploadError = () => {
                                            const fieldNm = getFieldName(field)
                                            tempRow[field] = ""
                                            tempRow['status'] = 'Upload Error'
                                             // Set Error Message
                                             if (tempRow['error_reason']) {
                                                tempRow['error_reason'] = tempRow['error_reason'] + `, ${fieldNm} = ${cellValue}`
                                            } else {
                                                tempRow['error_reason'] = `Invalid data for the following field(s): ${fieldNm} = ${cellValue}`
                                            }
                                        }

                                        if (
                                            cellValue === undefined ||
                                            cellValue === '' ||
                                            cellValue === null
                                        ) {
                                            setRowDataUploadError()
                                        } else {
                                            // All required data cells except for sizeRange have a datatype of selectionAutoComplete meaning that their values must be included in their respective key from the autoSelectOptions vairiable
                                            // the autoSelectOptions is populated with data from the backend
                                            const availOptions = this.autoSelectOptions[field] || null

                                            if (field === 'sizeRange' || (availOptions && availOptions.includes(cellValue))) {
                                                tempRow[field] = cellValue;
                                            } else {
                                                setRowDataUploadError()
                                            }
                                        }
										if (i === correctHeaders.length - 1) {
                                            tempRow['id'] = uuidv4();
										}
									});
									return tempRow;
								}
							}
						);
						// filter out extra headers if they are present
						const tableData = mappedData.filter((item, i) => i !== 0);
						console.log('table data: ', tableData);
						// append the imported data to the grid
						this.gridApi.applyTransaction({ add: tableData, addIndex: 0 });

						// Clear Upload form
						form.clear();
					} else {
                        console.error("Unable to process file upload")
                        this.onAddRow({
                            status: 'Upload Error',
                            error_reason: 'Unable to process file. Files can only be read if the delimiter is a comma. Please make sure the column names are presented in the following manner: Season, Channel, Product ID, Size Offering.'
                        })
                        // Clear Upload form
                        form.clear();
					}
				}
			};
			reader.readAsBinaryString(target.files[0]);
		});
	}

	onDownloadTemplate() {
		const JSON2CSV = (data) => {
			const headers = Object.keys(data);
			const values = Object.values(data).join(',');

			const csvRows = [headers.join(','), values, '2,,,', '3,,,'];

			return csvRows.join('\n');
		};

		const download = (data) => {
			// Creating a Blob for having a csv file format
			// and passing the data with type
			const blob = new Blob([data], { type: 'text/csv' });
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');

			a.setAttribute('href', url);
			a.setAttribute('download', 'New Size Offerings Template.csv');
			a.click();
		};
		const template = {
			'Required Data: Complete all of the required data for each row before uploading':
				'1',
			'Season': '',
			'Channel': '',
			'Product Id': '',
			'Size Offering': '',
		};

		const csvData = ` ${JSON2CSV(template)}`;
		console.log('CSV Data: ', csvData);

		download(csvData);
	}

	async checkRowData(event) {
        let rowDataIncomplete = false;
        const updatedRowData = {}
        this.rowData = []
        console.log("check cell: ", event)

        try {
            await this.gridApi.forEachLeafNode((node) => {
                const data = node.data
                if (data) {
                    // Track rowData to be updated
                    updatedRowData[data.id] = data
                    // Track current rowData
                    this.rowData.push({...data})
                }
			})
            // After the most current row data is collected => validate the data
            if (Object.keys(updatedRowData).length > 0) {

                const updatedRows = await Object.entries(updatedRowData).map(([key, value]) => {
                    const id = value['id']
                    let data = value;
                    let incompleteFields = []
                    //
                    this.requiredFields.forEach((field, i) => {
                        // if data is missing from any of the required fields -> rowdata is incomplete
                        if (!data[field] || data[field] === '') {
                            rowDataIncomplete = true;
                            incompleteFields.push(field)
                        }
                        if ((i + 1) === this.requiredFields.length) {
                            // Prevent Duplicate data from being sent to the backend
                            const matches = Object.values(updatedRowData).filter(({ season, channel, prodId, sizeRange, id }) => id !== data['id'] && (season === data['season'] && channel === data['channel'] && prodId === data['prodId'] && sizeRange === data['sizeRange']))

                            if (matches.length > 0) {
                                rowDataIncomplete = true

                                if (incompleteFields.length === 0) {
                                    const ids = matches.map(row => row['id'])
                                    const errorObject = {
                                        duplicate_data: [data['id'], ...ids],
                                        error_reason: 'Duplicated row data',
                                        status: 'Error'
                                    }
                                    errorObject.duplicate_data.forEach(dup => {
                                        Object.assign(updatedRowData[dup], errorObject)
                                    })
                                }
                            } else if (matches.length === 0 && data['duplicate_data'] && data['duplicate_data'].length > 0) {
                                // Remove the duplicate id from all rows
								data['duplicate_data'].forEach(dup => {
									// Check to make sure all duplicate rows still exists (some may have been deleted)
									const index = updatedRowData.hasOwnProperty(dup) ? updatedRowData[dup]['duplicate_data'].indexOf(id) : -1

                                    if (index > -1) {
                                        Object.assign(
                                            updatedRowData[dup],
                                            {
                                                duplicate_data: null,
                                                error_reason: null,
                                                status: null
                                            }
                                        )
                                    }
                                })
                            }
                            // check for updates to upload errors
                            if (
                                (i + 1) === this.requiredFields.length &&
                                updatedRowData[id]['status'] &&
                                updatedRowData[id]['status'] === "Upload Error" &&
                                incompleteFields.length === 0
                            ) {
                                Object.assign(
                                    updatedRowData[id],
                                    {
                                        error_reason: null,
                                        status: null
                                    }
                                )
                            }
                        }
                    })
                    return value
                })

                // Update Ag Grid with the new data
				if (updatedRows) {
                    const updateRows = Object.values(updatedRowData)

                    if (JSON.stringify(this.rowData) !== JSON.stringify(updateRows)) {
                        this.rowData = updateRows
                        this.gridApi.applyTransaction({ update: updateRows });
                    }
                }
            }
		} catch (error) {
        } finally {
            const noRows = this.rowData.length === 0;
			// Disable the sumbit functionality if the rowData is incomplete
			this.tableResetDisabled = noRows
            rowDataIncomplete = noRows ? true : rowDataIncomplete;

            // console.log("rowData incomplete rowData: ", this.rowData)
            // console.log("rowData incomplete: ", rowDataIncomplete)

			this.uploadNewSizeOfferingsService.onUpdateRowDataErrors(
				rowDataIncomplete
			);
		}
	}
}
