// Angular Imports
// =========================================================
import { Component, OnDestroy, OnInit } from "@angular/core";
// rxjs / lodash Imports
// =========================================================
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { cloneDeep } from "lodash";
// AG Grid Imports
// =========================================================
import {
  GridApi,
  GridReadyEvent,
  RowNode,
} from "ag-grid-community";
// Prime NG Imports
// =========================================================
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
// Mock JSON Data
// =========================================================
import CTS_MainColDefs from "../../../core/json/create-test-scenarios/colDefs-main-create_test_scenarios.json"
import CTS_DetailColDefs from "../../../core/json/create-test-scenarios/colDefs-detail-create_test_scenarios.json"
// Types
// =========================================================

// Custom Imports
// =========================================================
import { FilterService } from "src/app/core/services/filter.service";
import { ExpandRowById, GetRowNodeId } from '../../02_ag-grid-settings/g-ag-grid-functions';
import { TestScenarioCreateModalComponent } from "../../03_modals/create-test-scenarios/test-scenario-create-modal.component";
import { CustomGridOptions } from "../../02_ag-grid-settings/g-ag-grid-options";
import { DefaultDetailGridOptions, SideBarPanels } from "../../02_ag-grid-settings/g-ag-grid-variables";
import { CTS_GenerateGridData } from "../../02_ag-grid-settings/02_ag-generate-col-Defs/create-test-scenarios/create-test-scenarios-grid-config";
import { CreateTestScenarioDetailGrid } from './cts-detail-grid/create-test-scenarios-detail-grid.component';
import { CreateTestScenariosService } from 'src/app/core/services/db/create-test-scenarios.service';
import { GlobalServices } from 'src/app/core/services/db/global-services.service';
import { IsExternalFilterPresent, DoesExternalFilterPass } from '../../02_ag-grid-settings/g-ag-grid-functions';
import { handleError } from "src/app/core/utils/error-handling";
@Component({
  selector: "app-create-test-scenarios",
  templateUrl: "./create-test-scenarios.component.html",
  styleUrls: ["./create-test-scenarios.component.scss"],
})

export class CreateTestScenariosComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  // Ag Grid Configuration
  private sideBarPanels: any = SideBarPanels();
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: "Create Test Scenarios",
      createTestScenario: (params) => this.onCreate(params)
    },
  };
  gridApi: GridApi;
  gridOptions: any = {};
  // Grid Data
  columnDefs: any = []
  detailColumnDefs: any = []
  // 'External side panel filters'
  filtersToDisplay: string[] = ['channel', 'product'];
  filtersApplied = {};
  // Update RowData
  updatingRowData: any[] = [];
  loadingMessage: string = '';
  // Global Variables
  dimensions: any[] = []
  store_distros: any[] = []
  ref: DynamicDialogRef;
  initialRender: boolean = true;
  addRowData: any[] = []

  constructor(
    filterService: FilterService,
    public dialogService: DialogService,
    private globalServices: GlobalServices,
    private createTestScenariosService: CreateTestScenariosService
  ) {
    // Subscribe to side panel filters
    filterService.triggerFilterSource$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.filtersApplied = data;
        this.gridApi.onFilterChanged();
      });
    // Subscribe to All Store Distros
    // this.globalServices.getAllStoreDistros()
    // .pipe(takeUntil(this.unsubscribe$))
    // .subscribe({
    //   next: ({store_distros}) => { this.store_distros = store_distros},
    //   complete: () => {},
    //   error: (error) => handleError(this.dialogService, error, 'Error fetching All Store Distros')
    // })

   this.getAllDimensions()

    // Set AgGrid Options
    this.gridOptions = {
      ...this.customGridOptions,
      frameworkComponents: {
        ...this.customGridOptions.frameworkComponents,
        createTestScenarioDetailGrid: CreateTestScenarioDetailGrid,
      },
      sideBar: {
        toolPanels: [this.sideBarPanels.columnPanel],
        ...this.sideBarPanels.defaultLayout,
        defaultToolPanel: ''
      },
      detailCellRenderer: 'createTestScenarioDetailGrid',
      fullWidthCellRenderer: 'rowLoadingCellRenderer',
      getRowNodeId: (data) => data.dim_id,
      isExternalFilterPresent: (event) => IsExternalFilterPresent(this.filtersApplied),
      doesExternalFilterPass: (node) => DoesExternalFilterPass(node, this.filtersApplied),
      isFullWidthCell: (rowNode) => this.updatingRowData.indexOf(`${rowNode.id}`) >= 0 ,
      getRowHeight: (params) => {if (params.node && params.node.detail)  return 330; },
    };

    this.renderGrid(CTS_MainColDefs[0], CTS_DetailColDefs[0])
  }

  ngOnInit(): void {
  }

  // Subscribe to Dimensions
  private getAllDimensions(): void {
    this.globalServices.getAllDimensions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ dimensions }) => { this.dimensions = cloneDeep(dimensions) },
        complete: () => {this.subscribeToRowData()},
        error: (error) => handleError(this.dialogService, error, 'Error fetching dimensions data')
      })
  }
     // Subscribe to Test Scenarios
    private subscribeToRowData(): void {
      if (this.initialRender) {
        this.createTestScenariosService.getAllTestScenarios()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: ({ testScenarios }) => { if (testScenarios) this.transformRowData(testScenarios); },
          complete: () => {},
          error: (error) => handleError(this.dialogService, error, 'Error fetching test scenarios')
        })
      }
      else {
        this.transformRowData(this.addRowData)
      }
    }
  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs, detailColDefs) {
    const params = {
      mainColDefs: mainColDefs,
      detailColDefs: detailColDefs
    }

    const gridData = await CTS_GenerateGridData(params);
    if (gridData) {
      console.log("--- Set Test Scenarios Col Defs --- ")
      // console.log("main: ",  gridData.mainColDefs)
      console.log("detail: ",  gridData.detailColDefs)
      console.log("----------------------------------- ")
      this.columnDefs = gridData.mainColDefs
      this.detailColumnDefs = gridData.detailColDefs
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }
  // Format Row Data
  transformRowData(objData) {
    console.log("Format tests scenarios: ", objData);

    const rowData = objData.map((test, i) => {
      const dim = this.dimensions.find(dim => dim.id === test.dim_id) || {}

      // Final object for the grid row
      const row = {
        dim_id: test.dim_id,
        fit_score: test.fit_score,
        gap_pct: test.gap_pct,
        sales: test.sales,
        test_cases: test.test_cases ? test.test_cases : [],
        augmented_sales: test.augmented_sales,
        prodId: dim.product_id,
        parent_product_id: dim.product_id, // for filter compatibility
        channel: dim.channel,
        sizeRange: dim.size_range_desc,
      };

      return row;
    });
    if (this.initialRender) {
      this.gridApi.setRowData(rowData)
    } else {
      const dim_id = cloneDeep(rowData[0].dim_id)
      let updatedRowData = []

      this.gridApi.forEachNode((rowNode, index) => {
        let existingRowId = null
        const updated = rowData.find((row, i) => { if (row.dim_id === rowNode.data.dim_id) existingRowId = i; return row.dim_id === rowNode.data.dim_id })
          if (existingRowId !== null) return (
          rowData.splice(existingRowId, 1),
          updatedRowData.push(updated)
        );
        return updatedRowData.push(rowNode.data)

      });
      if (updatedRowData.length > 0) {
        this.gridApi.setRowData([...updatedRowData, ...rowData])
        this.resetRowAfterUpdate(dim_id)
      }
    }
  }

  detailCellRendererParams = (parentParams) => {
    const colDefs = this.detailColumnDefs
    console.log("grid api: ", this.gridOptions)
    return {
      detailGridOptions: {
        ...DefaultDetailGridOptions,
        suppressScrollOnNewData: true,
        columnDefs: colDefs,
      },
    }
  };
  // Create new test scenarios
  onCreate(params) {
    console.log("params:", params)
    console.log("params data: ", params.data)
    console.log("params dim id: ", params.data.dim_id)
    this.ref = this.dialogService.open(TestScenarioCreateModalComponent, {
      showHeader: false,
      closeOnEscape: false,
      dismissableMask: false,
      data: {
        data: params.data
        // store_distro: this.store_distros.find(distro => params.data.dim_id === distro.dim_id)
      },
    });
    this.ref.onClose.subscribe((testsToAdd) => {

      if (testsToAdd) {
        this.loadingMessage = "Creating New Test Scenarios"
        this.updatingRowData.push(`${params.node.id}`)
        this.gridApi.redrawRows()

          //  Create Test Scenarios
        this.createTestScenariosService.createTestScenario(testsToAdd).subscribe({
          next:({new_recs}) => {
            console.log("Test Scenario Updated: ", new_recs);
            this.initialRender = false
            this.addRowData = new_recs
            this.getAllDimensions();
          },
          complete: () => {},
          error: (error) => handleError(this.dialogService, error, 'Error Creating Test Scenarios')
        })
      }
    });
  }

  resetRowAfterUpdate(dim_id){
    this.updatingRowData = []
    this.gridApi.redrawRows()
    ExpandRowById(this.gridApi, [dim_id])
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
