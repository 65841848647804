
export const CTS_customCellFormatting = (col, gridToRender) => {
    let updatedCol = col

    // If the grid to render === Main Format the following custom columns
    if (gridToRender === "main") {
         // Create Test Scenario Column
         if (col.field === "create") (
            Object.assign(updatedCol, {
                cellRendererParams: (params) => {
                    console.log("cellrendererparams:", params)
                    if (!params.node.group) {
                        return {
                            onClick: (params) => params.context["createTestScenario"](params)
                        };
                    }
                },
            })
        );
        if (col.field === "num_of_tests") {
            Object.assign(updatedCol, {
                valueGetter: (params) => {

                    if (!params.node.group && params.data){
                        const scenarios = params.data.test_cases
                        const numOfTests = scenarios ? scenarios.length : 0
                        params.data.num_of_tests = numOfTests
                        return params.value = numOfTests
                    }
                },
            })
        }

    }
    // If the grid to render === Detail Format the following custom columns
    if (gridToRender === "detail") {
    }

    return updatedCol
}