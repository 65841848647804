import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Observable, timer, EMPTY } from 'rxjs';
import { retryWhen, tap, delayWhen, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';

export const ENDPOINT = environment.wsEndpoint;
export const RECONNECT_INTERVAL = environment.wsReconnectInterval;

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private socket$: WebSocketSubject<any> | null = null;
  
  // Unique websocket connection identity specific to this connection. This is not the authentication
  // identity. Websockets here are using component specific connections which are not accesible until
  // the user is authenticated. This allows better handling for offline and multiple tab experiences.
  // The identityId is created in the socket and should be included in the call to kickoff the backend
  // service.
  identityId: string = uuidv4();

  private reconnect(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((val) => console.log('[Data Service] Try to reconnect', val)),
          delayWhen((_) => timer(RECONNECT_INTERVAL))
        )
      )
    );
  }

  messages$: Observable<any> | null = null;

  connect(cfg: { reconnect: boolean } = { reconnect: false }): void {
    if (!this.socket$ || this.socket$.closed) {
      this.socket$ = webSocket({
        url: `${ENDPOINT}?identityId=${this.identityId}`,
        openObserver: {
          next: (event) => console.log('[DataService]: connection ok', event),
        },
        closeObserver: {
          next: () => {
            console.log('[DataService]: connection closed');

            this.socket$ = null;
            this.connect({ reconnect: true });
          },
        },
      });

      this.messages$ = this.socket$.pipe(
        cfg.reconnect ? this.reconnect : (o) => o,
        tap({
          error: (error) => console.log(error),
        }),
        catchError((_) => EMPTY)
      );
    }
  }

  close() {
    if (this.socket$ && !this.socket$.closed) {
      this.socket$.complete();
      this.socket$ = null;
    }
  }
}
