<div class="button-container">
	<!-- Reset Row -->
	<button
		mat-button
		[disabled]="tableResetDisabled || sumbittingSizeRanges"
		(click)="onResetTable()"
		class="text-warning"
	>
		Reset
	</button>
	<!-- Add A Row -->
	<button
		mat-raised-button
		id="add-new-size-offering"
		class="submit-btn"
		(click)="onAddRow()"
		[disabled]="sumbittingSizeRanges"
	>
		Add Row
	</button>
	<!-- Upload Files -->
	<p-fileUpload
		#form
		name="myfile[]"
		maxFileSize="1000000"
		multiple="true"
		mode="basic"
		chooseLabel="Upload A File"
		customUpload="true"
		(onSelect)="onFileChange($event, form)"
		styleClass="mat-focus-indicator submit-btn mat-raised-button mat-button-base"
		[disabled]="sumbittingSizeRanges"
	>
	</p-fileUpload>
	<!-- Download Template -->
	<button
		mat-raised-button
		id="download-temp-new-size-offering"
		class="submit-btn"
		(click)="onDownloadTemplate()"
		[disabled]="sumbittingSizeRanges"
	>
		Download Template
	</button>
</div>
<div
	style="
		width: 100%;
		height: calc(100% - 46px);
		min-height: 200px;
		overflow-y: auto;
	"
>
	<ag-grid-angular
		#mainGrid
		style="height: 100%; width: 100%; padding: 5px"
		id="upload-new-size-offerings-grid"
		class="ag-theme-alpine ag-grid-container"
		[gridOptions]="gridOptions"
		[enableRangeSelection]="true"
		[suppressRowClickSelection]="true"
		[debug]="true"
		[popupParent]="popupParent"
	></ag-grid-angular>
</div>
