// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// AG Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'child-cell',
  templateUrl: './button-renderer.html',
  styleUrls: ['./button-renderer.scss'],
})

export class ButtonCellRenderer implements ICellRendererAngularComp {
  public params: any;

  btnText: string = '';
  showIcon: boolean = false;
  labelIsIcon: boolean = false;

  btnDisabled: boolean = false;
  hideBtn: boolean = false;

  openModalBtn: boolean = false;
  actionBtn: boolean = false;
  iconName: string = '';
  toolTipMessage: string = '';

  iconBtnsArray = ["chart", "settings", "delete", "add", "execute"]
  parentName: string = '';
  groupRow: boolean = false

  agInit(params: any): void {

    // console.log("button renderer params", params)
    this.groupRow = ((params.node.group && params.node.level == 0)
      || params.node.level == 2) && !params.value

if (!this.groupRow){
    const dataType = params.colDef.refData.dataType
    const message = params.colDef.refData.toolTipMessage
    this.btnDisabled = params.disabled
    this.parentName = params.context.componentParent

    this.openModalBtn = dataType.includes("open-modal")
    this.actionBtn = dataType.includes("action-button")
    this.iconName = dataType.split('|')[1]
    this.toolTipMessage = message || `View ${this.iconName}`
    this.hideBtn = params.hideBtn || false

// FIXME: applies to review orders charts only
    if (!params.data && !this.actionBtn) {
      params.data = params.node.aggData;
      params.data.numStores = params.node.allChildrenCount;
      params.data.Metric = params.node.key;
    }

    this.params = params;
    this.btnText = params.btnText || params.colDef.headerName

    this.labelIsIcon = params.labelIsIcon;

    this.showIcon = this.iconBtnsArray.includes(this.iconName)}
  }

  public EmitBtn(event) {
    event.stopPropagation();
    if (this.params.onClick) this.params.onClick(this.params)
    else console.log("no button click event")
  }

  refresh(): boolean {
    return false;
  }
}
