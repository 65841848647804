// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const e = {
  production: false,
  serverUrl: "",
  rootUrl: "",
  loginUrl: '/login',
  assets: '../../../assets',
  wsEndpoint: '',
  wsReconnectInterval: 2000,
  refreshInterval: 60000,
  stage: "qwdev",
  apiServiceId: 'lajidu6j3i',
  apiWebSocketId: '7ey7owi687',
  prodLowLvl: 3,
  btl: true,
  colors: { // also defined in _global-style-variables.scss
    primary: '#263043',
    secondary: '#376fd0',
    secondaryRGB: '55,11,208',
    tertiary: '#5582d4',
    accentWarning: '#dd393c',
    accentWarningRGB: '221,57,60',
    chartDarkBlue: '#263043', // same as primary
    chartDarkBlueRGB: '38,48,67', // same as primary
    chartLightBlue: '#5582d4', // same as tertiary
    chartLightBlueRGB: '85,130,212', // same as tertiary
    chartGreen: '#22c55f',
    chartGreenRGB: '34,197,95',
    chartPink: '#ff6283', // same as accent-success
    chartPinkRGB: '255,98,131', // same as accent-success
    textColorPrimary: '#606264',
    textColorSecondary: '#b0b4ba'
  }
};

e.wsEndpoint = `wss://${e.apiWebSocketId}.execute-api.us-east-1.amazonaws.com/${e.stage}/`

export const environment = e;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
