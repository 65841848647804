<!-- Modal Header -->
<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<!-- Modal Body -->
<div class="flex-center-all modal-content">
  <!-- Size to Add -->
  <span class="modal-form-container-full-width">
    <h4 class="modal-form-label">Size:</h4>

    <span class="modal-form-input">
        <p>{{sizeToAdd}}</p>
    </span>
  </span>
  <!-- New Cont. -->
  <span class="modal-form-container-full-width">
    <h4 class="modal-form-label">New Cont (%):</h4>

    <mat-form-field class="modal-form-input">
      <input
        matInput
        type="text"
        name="newradc"
        [(ngModel)]="recoContribPct"
      />
    </mat-form-field>
  </span>
</div>
<!-- Modal Footer -->
<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Add Size"
  (update)="onSubmit()"
  (closeModal)="close()"
  [disabled]="!recoContribPct"
>
</app-modal-footer>
