import { FormatKey } from "../../../../core/utils/global-functions";
import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"

export interface VendorPacks_GridDataParams {
    mainColDefs: any[];
    detailColDefs: any[];
}

export async function VendorPacks_GenerateGridData(params: VendorPacks_GridDataParams) {
    const { mainColDefs, detailColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {
            const colId = FormatKey(colKey)

            let obj = {
                ...GenerateDefaultColDefs(colKey, colVal),
            }

            Object.assign(mappedCols, {
                [colId]: {
                    ...VendorPacks_CustomColFormatting(obj, gridToRender),
                    headerName: colId !== "eligible" ? obj.headerName : colKey,
                }
            })
        })

        return Object.values(mappedCols)
    }

    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")
        let detailCols = await GenerateCols(detailColDefs, "detail")

        let results = await Promise.all([mainCols, detailCols]);
        const m = results.indexOf(mainCols)
        const d = results.indexOf(detailCols)

        const data = {
            mainColDefs: results[m],
            detailColDefs: results[d]
        }
        return data
    }
    return getResults()
}

export const VendorPacks_CustomColFormatting = (col, gridToRender) => {
    let updatedCol = col

    if (col.colId === "pack_config") {
        Object.assign(updatedCol, {
            cellRendererParams: {
                innerRenderer: (params) => { return `Sizes: ${params.value.length}` },
            },
        })
    };
    // if (col.colId === "eligible") {
    //     Object.assign(updatedCol, {
    //         cellRendererParams: {
    //             // innerRenderer: (params) => { return `Sizes: ${params.value.length}` },
    //         },
    //     })
    // }
    return col
}