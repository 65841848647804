// Angular Imports
// =========================================================
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Custom Imports
// =========================================================
import { DashboardPageComponent } from './components/01_pages/02_dashboard/dashboard-page.component';
import { ReviewOrdersComponent } from './components/01_pages/03_review-orders/review-orders.component';
import { SetCartonAndBuyParamsComponent } from './components/01_pages/05_set-carton-and-buy-params/set-carton-and-buy-params.component';
import { DefineSizeOfferingsComponent } from './components/01_pages/04_define-size-offerings/define-size-offerings.component';
import { TuneMLComponent } from './components/01_pages/06_tune-ml/tune-ml.component';
import { CreateTestScenariosComponent } from './components/01_pages/07_create-test-scenarios/create-test-scenarios.component';
import { LoginComponent } from './components/01_pages/01_login/login.component';
import { AuthGuard } from './core/services/auth/auth-guard';

export const routes: Routes = [
 	{
		path: '',
		component: DashboardPageComponent,
	},
	{
		path: 'size-intelligence-modules-dashboard',
		component: DashboardPageComponent,
		canActivate: [AuthGuard],
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'review-orders',
		component: ReviewOrdersComponent,
	},
	{
		path: 'define-size-offerings',
		component: DefineSizeOfferingsComponent,
	},
	{
		path: 'carton-and-buy-parameters',
		component: SetCartonAndBuyParamsComponent,
	},
	{
		path: 'tune-ml',
		component: TuneMLComponent,
	},
	{
		path: 'create-test-scenarios',
		component: CreateTestScenariosComponent,
	},
  	{
		path: '**',
		redirectTo: '/',
		pathMatch: 'full',
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
