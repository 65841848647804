import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class GlobalServices {

	private allDimensions = '/api/dimensions';
	private sizeOrder = '/api/sizeOrder';
	private seasonFilters = '/api/seasonFilters';
	private locationFilters = '/api/locationFilters';
	private productFilters = '/api/productFilters';
	private allStoreDistros = '/api/storeDistros';
	private storeDistrosByDimId = '/api/storeDistros';

	constructor(private http: HttpClient) { }

	getAllDimensions() {
		return this.http.get<any>(this.allDimensions);
	};
	getSizeOrder() {
		return this.http.get<any>(this.sizeOrder);
	};
	//---------> Filters
	getSeasonFilters() {
		return this.http.get<any>(this.seasonFilters);
	};
	getChannelFilters() {
		return this.http.get<any>(this.locationFilters);
	};
	getProductFilters() {
		return this.http.get<any>(`${this.productFilters}/${environment.prodLowLvl}`);
	};
	//---------> Filters
	getAllStoreDistros() {
		return this.http.get<any>(this.allStoreDistros);
	};
	getStoreDistrosByDimId(dim_id) {
		return this.http.get<any>(`${this.storeDistrosByDimId}/${dim_id}`);
	}
}
