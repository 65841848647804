// Angular Imports
// =========================================================
import { Component, OnInit, AfterViewInit, SimpleChanges, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UploadNewSizeOfferingsService } from 'src/app/core/services/define-size-offerings-services/upload-new-size-offerings-service';
import { FilterService } from 'src/app/core/services/filter.service';
import { UploadNewSizeOfferingComponent } from './upload-new-size-offerings/upload-new-size-offerings.component';
import { DefineSizeOfferingsService } from 'src/app/core/services/db/define-size-offerings.service';
import { handleError } from 'src/app/core/utils/error-handling';
// JSON Data
// =========================================================
// Custom Imports
// =========================================================


@Component({
    selector: 'app-manage-size-offerings',
    templateUrl: './manage-size-offerings.component.html',
    styleUrls: ['./manage-size-offerings.component.scss']
})

export class ManageSizeOfferingComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();

    modalTitle = "Manage Size Offerings";
    objectKeys = Object.keys
    tabs: string[] = ['Create New Size Offerings', 'Review Requests']
    activeTab: any = 0;
    disableSubmit: boolean = false;
    storedRowData: any = [];
    sumbittingSizeRanges: boolean = false;

    @ViewChild("bulkUploadSizeRange") bulkUploadSizeRange: UploadNewSizeOfferingComponent;

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        public uploadNewSizeOfferingsService: UploadNewSizeOfferingsService,
        private dso_services: DefineSizeOfferingsService,
        public dialogService: DialogService,
    ) {

        this.uploadNewSizeOfferingsService.getRowDataErrors().subscribe(res => {
            console.log("disable submit: ", res)
            this.disableSubmit = res
        })

        console.log("Manage Size Offerings Configs: ", this.config)
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("changes: ", changes)
        const monitorChanges = ['disabled', 'loading']



		monitorChanges.map(key => {

			if (changes[key] && changes[key].previousValue !== changes[key].currentValue) {
				const currentVal = changes[key]?.currentValue
				// switch (key) {
				// 	case 'loading':
				// 		this.loading = currentVal
				// 		break;
				// 	case 'disabled':
				// 		this.loading = currentVal
				// 		break;
				// }
			}
		})
	}

    ngOnInit(): void {

    }

    // Function to track and update tab changes
    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        console.log("active tab: ", tabChangeEvent)

        if (this.tabs[this.activeTab] === 'Create New Size Offerings') {
            this.storedRowData = this.bulkUploadSizeRange?.rowData
        }

        this.activeTab = tabChangeEvent
	}

   async onSubmit() {
       let rowsToSubmit = [];
       let successfulRows = [];
       this.sumbittingSizeRanges = true;

       console.log("submit bulk size upload: ", this.bulkUploadSizeRange)
       try {
           this.bulkUploadSizeRange.gridApi.showLoadingOverlay()
           this.bulkUploadSizeRange.gridApi.forEachLeafNode(node => {
               if (node.data) {
                   if (!node.data.status || (node.data.status && node.data.status.toLowerCase() === 'failed')) {
                    rowsToSubmit.push({
                        channel: node.data?.channel,
                        prodId: node.data?.prodId,
                        season: node.data?.season,
                        sizeRange: node.data?.sizeRange,
                    })
                   } else {
                    successfulRows.push(node.data)
                  }
               }
           })
       } catch (error) {

       } finally {
        //    console.log("rowsToSubmit: ", rowsToSubmit)
        //    console.log("successfulRows: ", successfulRows)
        // this.sumbittingSizeRanges = false;

           this.dso_services.bulkUploadSizeRanges(rowsToSubmit)
           .subscribe({
               next: (results) => {
                   this.sumbittingSizeRanges = false;
                   console.log("resultssssss: ", results)

                   const rowData = [...results, ...successfulRows].map((row, i) => {
                       if (!row || row === null) {
                           return {
                               id: uuidv4(),
                               channel: '',
                                prodId: '',
                                season: '',
                               sizeRange: '',
                               error_reason: 'Unknown error. Please contact an administrator.',
                               status: 'Error'
                           }
                       } else {
                            return { ...row, id: uuidv4() }
                       }
                   })
                   this.bulkUploadSizeRange.gridApi.setRowData(rowData)
               },
            complete: () => { console.log("bulk size range results recieved")},
            error: (error) => handleError(this.dialogService, error, 'Error fetching seasons data')
          })

       }

    }

    close() {
        this.ref.close()
        this.unsubscribe$.complete();
    }

}
