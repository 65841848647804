// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
// Ag Grid Imports
// =========================================================
import { GridApi, GridReadyEvent } from 'ag-grid-community';
// Mock JSON Data
// =========================================================
import ESD_MainColDefs from '../../../../core/json/set-carton-and-buy-params/colDefs-main-edit-store-distro.json';
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
import {
  SideBarPanels,
} from "src/app/components/02_ag-grid-settings/g-ag-grid-variables";
import { ESD_GenerateGridData } from "../../../02_ag-grid-settings/02_ag-generate-col-Defs/set-carton-and-buy-params/edit-store-distro-grid-config"
import { GetFormErrorMessage } from 'src/app/core/utils/form-error-messages';
import { FormatKey } from 'src/app/core/utils/global-functions';
import { SetCartonAndBuyParamsService } from 'src/app/core/services/db/set-carton-and-buy-params.service';
import { handleError } from 'src/app/core/utils/error-handling';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-store-distro-modal',
  templateUrl: './edit-store-distro-modal.component.html',
  styleUrls: ['./edit-store-distro-modal.component.scss']
})

export class EditStoreDistroModalComponent implements OnInit {

    // Ag Grid Configuration
  private sideBarPanels: any = SideBarPanels();
   private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: "Set Carton And Buy Parameters | Edit Store Distro",
      deleteDistro: (params) => this.deleteDistro(params),
      sortGridRows: (rows) => this.sortGridRows(rows)
    },
  };

  modalTitle: string = 'Edit Store Distro'
  addStoreForm: FormGroup;

  gridApi: GridApi;
  gridOptions: any = {};
  dim_id: number = null;
  resetRow = {
    parameter_id: null,
    store: null,
    distro: null,
    delete:null
  }
  panelOpenState;
  columnDefs = []
  rowIdsList: any[] = []
  // Accessed by the manage changes tool panel
  deletedDistros: any[] = [];
  addedDistros: any[] = []
  environment = environment;

  constructor(
    private formBuilder: FormBuilder,
    public dialogService: DialogService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private SCABP_Service: SetCartonAndBuyParamsService,
  ) {
    // Set the dim id from the parent row
    this.dim_id = this.config.data.rowNode.data.dim_id
    // initialize add a new store distro from
    this.addStoreForm = this.formBuilder.group({
			'parameter_id': [{value: null, disabled: true}, []],
			'store': ['', [Validators.required]],
			'distro': ['', [Validators.required]],
    });
    const {columnPanel, filterPanel, ManageDistroChanges, defaultLayout} = this.sideBarPanels
    // Set AgGrid Options
    this.gridOptions = {
      rowData: this.transformRowData(this.config.data.store_distro),
     ...this.customGridOptions,
     sideBar: {
      toolPanels: [columnPanel, filterPanel, ManageDistroChanges],
      ...defaultLayout,
      },
   };
   this.renderGrid(ESD_MainColDefs[0])
  }

  ngOnInit(): void {
    console.log("Edit Store Distro Modal: ", this.config.data)
  }
  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs) {
    const params = {
      mainColDefs: mainColDefs,
    }

    const gridData = await ESD_GenerateGridData(params);
    if (gridData) {
      console.log("Run Test Scenarios col defs: ", gridData.mainColDefs)
      this.columnDefs = gridData.mainColDefs
    }
  }
   // Format Row Data
   transformRowData(objData) {
     console.log("----- Transforming Row Data-------")
     if (objData && objData.length > 0) {
       return objData.map((key) => {
         const id = `${key["store"]}-${key["distro"]}`
         this.rowIdsList.push(FormatKey(id))
        return {
          parameter_id: `${key["store"]}-${key["distro"]}`,
          dim_id: this.dim_id,
          store: key["store"],
          distro: key["distro"],
          delete: "delete"
        }
     }).sort((a, b) => a.parameter_id > b.parameter_id ? 0 : -1);
     } else {
       return [];
     }
  }

	// convenience getter for easy access to form fields
	get form() {
		return this.addStoreForm.controls;
  }
  setFormError(controlName){
    const params = {
      controlName: controlName,
      form:  this.addStoreForm,
      customMessages: {}
    }
    return GetFormErrorMessage(params)
  }

  addNewDistro(form: NgForm, formDirective: FormGroupDirective) {
    const id = `${form['store']}-${form['distro']}`
    const f_id = FormatKey(id)

    if (this.rowIdsList.includes((f_id))) {
      this.addStoreForm.controls['distro'].setErrors({'duplicateValue': true});
    } else {
      const distroToAdd = {
        parameter_id: id,
        dim_id: this.dim_id,
        ...form,
        delete: "delete"
      }
      this.rowIdsList.push(f_id)
      this.addedDistros.push(distroToAdd)
      this.gridOptions.rowData.push(distroToAdd)
      this.gridApi.setRowData(this.sortGridRows(this.gridOptions.rowData))
      this.resetAddStoreForm(formDirective)
    }
  }

  resetAddStoreForm(formDirective: FormGroupDirective){
    console.log("reset add store form: ", event)
    this.addStoreForm.reset()
    formDirective.resetForm()
  }
   // On Grid Ready
   onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
     this.gridApi.closeToolPanel();
  }

  // On save and close -> update the db
  onSaveAndClose(){
    // console.log("save store distro changes: added -", this.addedDistros)
    // console.log("save store distro changes: deleted - ", this.deletedDistros)

    // Update the overlay message
    this.gridOptions.loadingOverlayComponentParams.loadingMessage = 'updating'
    this.gridApi.showLoadingOverlay();

    const runDeleteStoreDistros = () => this.SCABP_Service.deleteStoreDistros(this.deletedDistros).subscribe({
        next: (res) => { console.log("store distros deleted successfully: ", res)},
      complete: () => {
        this.gridApi.hideOverlay();
        this.ref.close()
      },
        error: (error) =>  handleError(this.dialogService, error, 'Error Deleting Store Distros')
    })

    if (this.addedDistros.length > 0) {
      this.SCABP_Service.addStoreDistros(this.addedDistros).subscribe({
          next: (res) => { console.log("store distros added successfully: ", res)},
          complete: () => { runDeleteStoreDistros() },
          error: (error) =>  handleError(this.dialogService, error, 'Error Adding Store Distros')
      })
    } else if (this.deletedDistros.length > 0) {
      runDeleteStoreDistros()
    } else {
      this.ref.close()
    }
  }

  deleteDistro(params){
    console.log("delete button: ", params)
    this.deletedDistros.push(params.data)

    const index =  this.gridOptions.rowData.findIndex((row, i ) => row.parameter_id === params.data.parameter_id)
    this.gridOptions.rowData.splice(index, 1)
    this.gridApi.setRowData(this.gridOptions.rowData)
  }

  undoAddedStoreDistro(event, deleteDistro, index) {
    console.log("undo Delete Store Distro: ", deleteDistro)
    this.addedDistros.splice(index, 1)
   const listI =  this.rowIdsList.indexOf(FormatKey(deleteDistro.parameter_id))
   this.rowIdsList.splice(listI, 1)
    const i =  this.gridOptions.rowData.findIndex((row, i ) => row.parameter_id === deleteDistro.parameter_id)
    this.gridOptions.rowData.splice(i, 1)
    this.gridApi.setRowData(this.gridOptions.rowData)

  }

  undoDeleteStoreDistro(event, addDistro, index) {
    console.log("undo Delete Store Distro: ", addDistro)
    this.deletedDistros.splice(index, 1)
    this.gridOptions.rowData.push(addDistro)
    this.gridApi.setRowData(this.sortGridRows(this.gridOptions.rowData))
  }

  sortGridRows(rows) {
  return rows.sort((a, b) => a.parameter_id > b.parameter_id ? 0 : -1)
}
  close() {
    console.log("close modal")
    this.ref.close()
  }
}
