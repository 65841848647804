// Angular Imports
// =========================================================
import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { FilterService } from 'src/app/core/services/filter.service';
import { FiltersPanelComponent } from 'src/app/components/04_filters/filters-panel/filters-panel.component';

@Component({
  selector: 'apsp-hierarchy-level-select',
  templateUrl: './hierarchy-level-select.component.html',
  styleUrls: ['./hierarchy-level-select.component.scss']
})
export class HierarchySelectComponent implements OnInit {

  private unsubscribe$ = new Subject<void>();
  
  modalTitle = "Select a Product Node";
  subTitle = 'For best results, select nodes at the lowest level';
  filtersToDisplay = ['product']; 
  data: any;
  options: any;
  currentValue: any;
  valueSelected: any = {};
  disabled = true;

  // TODO: I think this ca be deleted because the service was added 
@ViewChild(FiltersPanelComponent) filterPanel: FiltersPanelComponent

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private filterService: FilterService
  ) {
    this.data = config.data;
    this.currentValue = config.data.defaultValue

    this.filterService.triggerFilterReviewOrders$ 
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      console.log("hierarchy data: ", data)
      this.currentValue = data.parent_product_id
      // this.submit();
    });
  }
  

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) { 
    console.log('filters changed', event)
    
  }

  onValueSelected(event) {
    console.log('value selected', event)
    this.currentValue = event;
    this.disabled = !event.length;
  }

  submit() {
    console.log("final value: ", this.currentValue)
    console.log("filter panel: ", this.filterPanel)
    this.ref.close(this.currentValue);
  }

  close() {
    // console.log("filter panel: ", this.filterPanel)

    this.ref.close();
  }

  ngOnDestroy() {
    // this.ref.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
