<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
  [disabled]="sumbittingSizeRanges"
></app-modal-header>
<div class="flex-center-all modal-content">

    <mat-tab-group
    class="flex-center-all"
    [selectedIndex]="activeTab"
    (selectedIndexChange)="tabChanged($event)"
    #changeEvent
    style="height: 100%; width: 100%"
  >

    <mat-tab
      *ngFor="let tab of tabs; let index = index"
      [label]="tab"
      [disabled]="sumbittingSizeRanges"
    >
      <ng-template matTabContent>

        <div class="tab-body" style="overflow-y: hidden !important; height: inherit">
          <!-- Create New Size Offerings -->
          <div
            *ngIf="tab === 'Create New Size Offerings' && index === activeTab"
            class="flex-center-all"
            style="height: inherit; width: 100%;"
          >
            <app-upload-new-size-offerings
            #bulkUploadSizeRange
            style="height: inherit; width: inherit"
            [storedRowData]="storedRowData"
            [sumbittingSizeRanges]="sumbittingSizeRanges"
            ></app-upload-new-size-offerings>
          </div>

          <!-- Review Requests -->
          <div
            *ngIf="tab === 'Review Requests' && index === activeTab"
            class="flex-center-all;"
            style=" width: 100%; height: 100%"
          >
           <app-review-requests
            style="height: inherit; width: inherit"
            ></app-review-requests>
          </div>

        </div>

      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<app-modal-footer
  [showUpdateBtn]="tabs[activeTab] === 'Create New Size Offerings'"
  confirmBtnText="Submit"
  [disabled]="disableSubmit || sumbittingSizeRanges"
  [closeDisabled]="sumbittingSizeRanges"
  closeBtnText="Close"
  (update)="onSubmit()"
  (closeModal)="close()"
></app-modal-footer>