// Angular Imports
// =========================================================
import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-source-dest-carton-tooltip',
  templateUrl: './source-dest-carton-tooltip.component.html',
  styleUrls: ['./source-dest-carton-tooltip.component.scss'],
})

export class SourceDestCartonTooltip implements ITooltipAngularComp {
  sizes: any[] = [];
  value: any;
  toolTipMessage = 'message!'

  constructor() {}

  agInit(params: ITooltipParams): void {
    this.value = params.valueFormatted;
    this.sizes = params.data?.config.sort((a, b) => a.size_order - b.size_order);
  }
}
