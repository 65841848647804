import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadNewSizeOfferingsService {
  private rowDataErrors = new Subject<any>();
  public rowDataErrors$ = this.rowDataErrors.asObservable();

  constructor() {}

  onUpdateRowDataErrors(filters) {
    return this.rowDataErrors.next(filters);
  }

  getRowDataErrors(){
      return this.rowDataErrors$;
  }
}
