// Angular Imports
// =========================================================
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
// Prime NG (UI) Imports
// =========================================================
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
// NG Idle Imports
// =========================================================
import { Idle } from '@ng-idle/core';
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service';
import { Subject, takeUntil } from 'rxjs';
// Custom Imports
// =========================================================
// import { AuthService } from 'src/app/core/authentication/auth.service';
@Injectable({
	providedIn: 'root',
})
@Component({
	selector: 'app-notify-modal',
	templateUrl: './notify-modal.component.html',
	styleUrls: ['./notify-modal.component.scss'],
	providers: [DialogService],
})
export class NotifyModalComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	message: string;
	title: string;


	constructor(
		public ref: DynamicDialogRef,
		public dialogService: DialogService,
		public config: DynamicDialogConfig
	) {
		this.message = this.config.data.message
		this.title = this.config.data.title
	}

	ngOnInit(): void {
	}
	// Close the modal
	close(event) {
		this.ref.close();
	}
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.ref.close();
	}
}
