import { Component, type OnInit } from '@angular/core'
import * as ApplicationSections from '../../../core/json/application-sections.json'

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  $ApplicationSections = ApplicationSections

  ngOnInit () {
    console.log('Dashboard Data: ', this.$ApplicationSections[0])
  }
}
