import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private triggerFilterSource = new Subject<any>();
  public triggerFilterSource$ = this.triggerFilterSource.asObservable();

  private triggerSetFilters =  new BehaviorSubject({});

  private triggerFilterReviewOrders = new Subject<any>();
  public triggerFilterReviewOrders$ = this.triggerFilterReviewOrders.asObservable();

  public lowestLevelFilters = new BehaviorSubject({});

  constructor() { }

  filters = {}

  onSetFilters(filters) {
    return this.triggerSetFilters.next(filters);
  }

  getFilterOptions() {
    return this.triggerSetFilters.asObservable();
  }

  onUpdateFilters(filters) {
    return this.triggerFilterSource.next(filters);
  }

  getLowestLevelFilters(){
      return this.lowestLevelFilters.asObservable();
  }

  updateLowestLevelFilters(filterName, filters) {
    this.filters[filterName] = filters[filterName]
    console.log('UPDATING LOWEST LEVEL FILTERS', JSON.stringify(this.filters))
      return this.lowestLevelFilters.next(this.filters)
  }
  // For review orders only
  onUpdateFiltersReviewOrders(filters) {
    return this.triggerFilterReviewOrders.next(filters);
  }
}
