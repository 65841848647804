// Angular Imports
// =========================================================
import {
	Component,
	OnInit,
	AfterViewInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Message, createMessage } from '../message';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
	selector: 'app-chat-window',
	templateUrl: './chat-window.component.html',
	styleUrls: ['./chat-window.component.scss'],
})
export class ChatWindowComponent implements OnInit, AfterViewInit {

    display = false;
    isMinimized = true;
    isEnded = true;
    chatIsDisabled = true;
    chatTextInput = new FormControl()
    messages: Message[] = []

	constructor(
        private chatService: ChatService
    ) {}

	ngOnInit() {
        this.sendInitialMessage();
    }

	ngAfterViewInit(): void { }

    sendInitialMessage() {
        this.chatService.sendMessagesToApi().then(data => {
            console.log("data", data);
            data.messages[1] = createMessage(data.messages[1].content, 'assistant');
            this.messages = data.messages;
            this.chatIsDisabled = false;
        });

    }

    minimize() {

    }

    expand() {

    }

    toggleMinimize() {
        this.isMinimized = !this.isMinimized;
        console.log('new minimize:',this.isMinimized)
        if (!this.isMinimized && this.isEnded) {
            this.isEnded = false;
            this.sendInitialMessage();
        }
    }

    onChangeInput() {

    }

    onSubmit() {
        console.log('message text:', this.chatTextInput.value);
        const message = createMessage(this.chatTextInput.value);
        this.addMessage(message);
        this.submitMessageToBot()
        this.chatTextInput.setValue('')
    }
    
    addMessage(message: Message) {
        this.messages.push(message)
    }

    submitMessageToBot() {
        this.processResponse(this.chatService.submitMessages(this.messages))
    }

    processResponse(response) {
        response.then(data => {
            console.log("response data", data)
            var latestMessage = data.messages[data.messages.length-1]
            console.log('latest message', latestMessage)
            const messageResponse = createMessage(latestMessage.content, latestMessage.role);
            this.addMessage(messageResponse);
        })
    }

    endSession() {
        this.messages = [];
        this.chatService.wipeMessages();
        this.isMinimized = true;
        this.isEnded = true;
        this.chatIsDisabled = true;
    }
}
