// Grid Functions
// =========================================================
import { CustomGridOptions } from './g-ag-grid-options'

// Default Detail grid settings
export const DefaultDetailGridOptions = {
    defaultColDef: CustomGridOptions.defaultColDef,
    columnTypes: CustomGridOptions.columnTypes,
    aggFuncs: CustomGridOptions.aggFuncs,
    frameworkComponents: CustomGridOptions.frameworkComponents,
    rowSelection: 'multiple',
    suppressRowClickSelection: false,
    enableRangeSelection: true,
    columnDefs: [],
    onCellClicked: (params) => console.log("detail grid cell clicked: ", params),
    rowClassRules: CustomGridOptions.rowClassRules,

     // pagination: true,
    // paginationAutoPageSize: true,
}
// Grid side panels
export const SideBarPanels = () => {
    const filters = {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        toolPanelParams: {
            suppressExpandAll: true,
            // suppressFilterSearch: true,
        },

    };
    const columns = {
        id: 'columns',
        labelDefault: 'Table Config',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
            suppressColumnExpandAll: true,
            contractColumnSelection: true,
            suppressValues: true,
            suppressColumnSelectAll: true,
            suppressPivotMode: true,
        },
    };
    const ManageDistroChanges = {
        id: 'ManageDistroChanges',
        labelDefault: 'Manage Changes',
        labelKey: 'ManageDistroChanges',
        iconKey: 'menu',
        toolPanel: 'ManageDistroChanges',
    };

    return {
        defaultLayout: {
            position: 'left',
            defaultToolPanel: 'columns',
        },
        filterPanel: filters,
        columnPanel: columns,
        ManageDistroChanges: ManageDistroChanges,

    }
}