import { RowNode } from 'ag-grid-community';

export function GetRowNodeId(data) {
	// console.log('data',data);
	return (<any>(
		(data.sizeRange +
			'-' +
			data.prodId +
			'-' +
			data.season +
			'-' +
			data.channel)
	))
		.replaceAll('/', '-')
		.replaceAll("'", '')
		.replaceAll(' ', '')
		.replaceAll('.', '')
		.replaceAll('|', '-');
}

// AG Grid external filter function
export const IsExternalFilterPresent = (filtersApplied) => {
	// console.log("isexternalfitleprresent", filtersApplied)
	if (filtersApplied !== null) {
		const filters = Object.keys(filtersApplied).filter(
			(key) => filtersApplied[key].length > 0
		);
		console.log('filters', filters);
		return filters.length > 0;
	}
	return false;
};

// AG Grid external filter function
export const DoesExternalFilterPass = (node, filtersApplied) => {
	// console.log("doesexternalfilterpass")
	// console.log('with node', node)
	// console.log('and filters', filtersApplied)
	const allFilters = Object.keys(filtersApplied).filter(
		(key) => filtersApplied[key].length > 0
	);
	const filters = allFilters.filter((key) => {
		const isGroupRow = node.group;
		const isTrue =
			!isGroupRow &&
			filtersApplied[key].map((e) => e.data).includes(node.data[key]);
		return isTrue;
  });

	return filters.length === allFilters.length;
};

export const ExpandRowById = (gridApi, rowIds) => {
	gridApi.forEachNode((node: RowNode) => {
		if (rowIds.includes(node.id)) return node.setExpanded(true);
		return node.setExpanded(false);
	});
};

export const CollapseRowById = (gridApi, rowIds) => {
	gridApi.forEachNode((node: RowNode) => {
		if (rowIds.includes(node.id)) return node.setExpanded(false);
		return node.expanded;
	});
};

export const CollapsedAllRows = (gridApi) => {
	gridApi.forEachNode((node: RowNode) => {
		return node.setExpanded(false);
	});
};
