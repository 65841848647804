import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(
	'CompanyName=Quantworks, Inc.,LicensedGroup=quantworks,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=4,AssetReference=AG-026488,ExpiryDate=18_May_2023_[v2]_MTY4NDM2NDQwMDAwMA==97a8bf81f41b876c816b0239c2708a4b'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
