import { FormatKey, IsKeyInObj } from "../../../../core/utils/global-functions";
import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"
import { CTS_customCellFormatting } from "../../03_ag-cellFormatters/create-test-scenarios-cell-formatters";

export interface CTS_GridDataParams {
    mainColDefs: any[];
    detailColDefs: any[];
}

export async function CTS_GenerateGridData(params: CTS_GridDataParams ) {
    const { mainColDefs, detailColDefs} = params

    const GenerateCols = async (data: any[], gridToRender: string) => {

         const mapCols = (colData) => Object.entries(colData).map(([colKey, colVal]) => {
            const isGroupCol = IsKeyInObj(colVal, "children")

            let obj = {
                ...GenerateDefaultColDefs(colKey, colVal),
                width: colVal['refData'].dataType === "checkbox" ? 48 : 100,
                flex: colVal['refData'].dataType === "checkbox" ? 0 : 1,
            }

             if (isGroupCol) {
                return { ...CTS_customCellFormatting(obj, gridToRender), children: mapCols(obj["children"])}
             } else {
                 return CTS_customCellFormatting(obj, gridToRender)
            }
         })
        // mapCols(data)

        return mapCols(data)
    }


    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")
        let detailCols = await GenerateCols(detailColDefs, "detail")

        let results = await Promise.all([mainCols, detailCols]);
        const m = results.indexOf(mainCols)
        const d = results.indexOf(detailCols)

        const data = {
            mainColDefs: results[m],
            detailColDefs: results[d]
        }

        return data
    }
    return getResults()

}