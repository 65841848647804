// Angular, RJX and lodash Imports
// =========================================================
import { Component } from "@angular/core";
import { cloneDeep } from 'lodash';
// Prime NG Imports
// =========================================================
import { DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
// Ag Grid Imports
// =========================================================
import { ICellRendererAngularComp } from "ag-grid-angular";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AddNewSizeModalComponent } from "src/app/components/03_modals/define-size-offerings/add-size-recommendation/add-size-recommendation.component";
// Custom Imports
// =========================================================
import { Size } from "src/app/core/utils/types";
import { AddSizeRecommendationModalComponent } from "src/app/components/03_modals/define-size-offerings/add-new-size/define-size-offerings-add-size.component";
import {
  IsKeyInObj,
  GenerateRandomColor,
  SortSizes,
} from "src/app/core/utils/global-functions";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-detail-cell-renderer",
  templateUrl: `./define-size-offerings-detail-grid.component.html`,
})
export class DefineSizeOfferingsDetailGrid implements ICellRendererAngularComp {
  gridApi: GridApi;
  gridOptions: any = {};

  ref: DynamicDialogRef;
  params;
  parentData: any = {};
  masterGridApi;
  rowId;
  defaultColDef;

  // Chart Variables
  lineChartData: any;
  lineChartOptions: any;
  chartColors: string[] = [
    environment.colors.chartDarkBlue,
    environment.colors.chartLightBlue,
    environment.colors.chartPink,
    environment.colors.chartGreen
];

  constructor(public dialogService: DialogService) {}

  // called on init
  agInit(params: any): void {
    console.log("define size detail grid opened: ", params);
    const {data, api, detailGridOptions} = cloneDeep(params)
    const rowData = params.data.radcs
    this.lineChartData = null
    this.lineChartOptions = null;

    this.params = params;
    this.parentData = cloneDeep(data);
    this.masterGridApi = api;
    this.rowId = params.node.id;
    // Grid configuration (row, data, coldefs, default settings)
    this.gridOptions = {
      rowData: typeof rowData === "object" ? params.data.radcs : [],
      columnDefs: detailGridOptions.columnDefs,
      ...detailGridOptions,
      context: {
        ...detailGridOptions.context,
        deleteSize: (params) => this.deleteSize(params),
        addRecommendation: (params) =>
          this.openAddSizeRecommendationModal(params),
      },
    };
    // Configure Chart Details
    this.configChart(rowData);
  }

  async configChart(chartData: any) {
    const sizeRange = this.params?.data?.sizeRange
    const chartLabels = sizeRange && sizeRange.includes('|') ? sizeRange.split('|') : [sizeRange];

    let colsToMap = {
      sales: {
        label: "Raw Sales",
        data: [],
        fill: false,
        borderColor: this.chartColors[0],
      },
      augmented_sales: {
        label: "BADC",
        data: [],
        fill: false,
        borderColor: this.chartColors[1],
      },
      radc: {
        label: "RADC",
        data: [],
        fill: false,
        borderColor: this.chartColors[2],
      },
      final_c: {
        label: "Final Curve",
        data: [],
        fill: false,
        borderColor: this.chartColors[3],
      },
    };
    try {
     chartData.map((pct, i) => {
        const sizeAdded = pct.delete;
        // Add size name to chart labels
        // if (sizeAdded) chartLabels.push(pct.sizeNm);

        // Map through radcs keys to create the chart data / generate chart settings
        Object.keys(pct).map((key) => {
          const showVal = IsKeyInObj(colsToMap, key);
          if (showVal && sizeAdded) {
            if (Number(pct[key]) !== 0)
              colsToMap[key].data.push({
                y: Number(pct[key]) * 100,
                x: pct.sizeNm,
              });
          }
          // Create chart settings for each key only during the first loop of the radcs
          if (i === 0 && showVal) {
            Object.assign(colsToMap[key], {
              order: Object.keys(colsToMap).indexOf(key),
            });
          }
        });
      });
    } catch (error) {

    } finally {
      console.log("grid data: ", Object.values(colsToMap))
      console.log("chart labels: ", chartLabels)

      // Set Chart Data
      this.lineChartData = {
        datasets: Object.values(colsToMap),
      };
      // Set Chart Options
      this.lineChartOptions = {
        animation: {
          duration: 0,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
              type: "category",
              labels: chartLabels,
            },
          y: {
              ticks: {
                beginAtZero: true,
              },
            },
        },
        plugins: {
        tooltip: {
          mode: "point",
          intersect: false,
          axis: "y",
          position: "nearest",
          backgroundColor: "#ffffff",
          borderWidth: 2,
          displayColors: false,
          titleColor: "#4e4e4e",
          borderColor: "#011c26",
          padding: 10,
          callbacks: {
            title: (context) => {
              // console.log("title: ", context)
                return `Size: ${context[0].raw.x}`
            },
            label: (context) => {
              // console.log("label: ", context)
              // console.log("this.lineChartData: ", this.lineChartData)
              return `${context.dataset.label}: ${context.formattedValue}%`
            },
            labelTextColor: (tooltipItem) => {
              // console.log("label color: ", tooltipItem)
              return this.lineChartData.datasets[tooltipItem?.datasetIndex]
                .borderColor;
            },
          },
        },
      },
      };
    }
  }
  // Delete a size from the detail grid
  async deleteSize(row) {
    // console.log("rowData 1: ", this.gridOptions.rowData);
    await this.updateSizeRange(row, "delete").then((res) => {
      this.params.context.rebalance(this.gridOptions.rowData, this.parentData);
      console.log("Deleted size: ", row.data.sizeNm + this.gridOptions.rowData);
    });
  }
  // Define Size Offerings Add Size Modal
  openAddSizeRecommendationModal(params) {
    this.ref = this.dialogService.open(AddSizeRecommendationModalComponent, {
      showHeader: false,
      closeOnEscape: false,
      dismissableMask: false,
      data: params.data,
    });
    // Modal Closed
    this.ref.onClose.subscribe((data) => {
      if (data) {
        const {
          data: { sizeNm },
        } = data;
        console.log("Size Recomendation Added: ", data)

        this.updateSizeRange(sizeNm, "add");

        this.gridOptions.rowData.find((obj) => {
          if (obj.sizeNm === sizeNm) {
            obj.final_c = parseFloat(`${data.recoContribPct}`);
            obj.delete = 1;
            obj.locked = true;
            obj.userAddedSrc = "QSP";
          }
        });

        this.params.context.rebalance(this.gridOptions.rowData, this.parentData);
      }
    });
  }
  // Update the size range for the detail grid and parent row
  async updateSizeRange(item: any, action: string) {
    let updated_sizeRange = this.params.data.sizeRange.split("|");
    // Add a size
    if (action === "add") {
      updated_sizeRange.push(item);
      this.params.data.add_sizes = this.params.data.add_sizes.slice(0, -1);
    }
    // Delete a size
    if (action === "delete") {
      const i = updated_sizeRange.indexOf(item.data.sizeNm);
      updated_sizeRange.splice(i, 1);
      this.gridOptions.rowData.splice(item.rowIndex, 1);
      this.gridApi.setRowData(this.gridOptions.rowData);
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    return false;
  }

  onGridReady(event: GridReadyEvent) {
    console.log("Grid Ready: ", this.params);

    this.gridApi = event.api;
    let gridInfo = {
      id: this.rowId,
      api: event.api,
      columnApi: event.columnApi,
    };

    console.log("adding detail grid info with id: ", this.rowId);
    this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
  }

  // Open New Size Modal
  openAddNewSizeModal(event) {
    console.log("add new size modal: ", this.gridOptions.rowData);
    this.ref = this.dialogService.open(AddNewSizeModalComponent, {
      showHeader: false,
      dismissableMask: false,
      data: {
        rowData: this.gridOptions.rowData,
        context: this.params.context.componentParent,
      },
    });
    // Modal Closed
    this.ref.onClose.subscribe((newSize: Size) => {
      if (newSize) {
        console.log("new size: ", newSize);
        const sorted = SortSizes(
          this.params.context.componentParent.sizeOrder,
          [...this.gridOptions.rowData, newSize]
        );
        this.gridOptions.rowData = sorted
        this.gridApi.setRowData(sorted);
        this.params.context.rebalance(sorted, this.parentData);
      }
    });
  }

  ngOnDestroy(): void {
    // detail grid is automatically destroyed as it is an Angular component
    console.log("removing detail grid info with id: ", this.rowId);
    this.masterGridApi.removeDetailGridInfo(this.rowId);
  }
}
