// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
// AG Grid Imports
// =========================================================
import { GridApi, ColumnApi, GridReadyEvent } from 'ag-grid-community';
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog';
// JSON Data
// =========================================================
import ReviewRequestsMainColDefs from '../../../../../core/json/define_size_offerings/colDefs-main-review_requests.json'
// Custom Imports
// =========================================================
import { CustomGridOptions } from 'src/app/components/02_ag-grid-settings/g-ag-grid-options';
import { ReviewRequests_GenerateGridData } from 'src/app/components/02_ag-grid-settings/02_ag-generate-col-Defs/define-size-offerings/review-requests-main-grid-config';
import { DefineSizeOfferingsService } from 'src/app/core/services/db/define-size-offerings.service';
import { Subject, takeUntil } from 'rxjs';
import { handleError } from 'src/app/core/utils/error-handling';

@Component({
    selector: 'app-review-requests',
    templateUrl: './review-requests.component.html',
    styleUrls: ['./review-requests.component.scss']
})

export class ReviewRequestsComponent implements OnInit {
    private unsubscribe$ = new Subject<void>();
     // Ag Grid Configuration
     private customGridOptions: any = {
        ...CustomGridOptions,
        context: {
            componentParent: this,
            pageTitle: "Review Requests",
        },
    };

    objectKeys = Object.keys

    // Ag Grid Configuration
    columnDefs;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    gridOptions: any = {};
    rowData = []

    constructor(
        private dso_services: DefineSizeOfferingsService,
        public dialogService: DialogService,
    ) {
        this.gridOptions = {
            ...this.customGridOptions,
            frameworkComponents: {
                ...this.customGridOptions.frameworkComponents,
            },
            onGridReady: (event: GridReadyEvent) => this.onGridReady(event),
            rowClassRules: {
                'parent-row-warning': (params) => params.data?.status && params.data.status.toLowerCase() === 'failed'
            },
            // getRowNodeId: (data) => data?.id,
        };
        this.dso_services.getBulkSizeRangesToReview()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: data => { this.gridApi.setRowData(data) },
          complete: () => {},
          error: (error) => handleError(this.dialogService, error, 'Error fetching Bulk Size Ranges')
        })
    }

    ngOnInit(): void {

    }

    // when AG Grid is done with initial processing, run the following functions
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.showLoadingOverlay();

        this.renderGrid(ReviewRequestsMainColDefs[0])
    }

      //Render AF Grid Column Definitions
      async renderGrid(mainColDefs) {
        const params = {
            mainColDefs: mainColDefs,
        };

        const gridData = await ReviewRequests_GenerateGridData(params);

        if (gridData && !this.gridApi["destroyCalled"]) {
            console.log('Review Requests Column Defs: ', gridData.mainColDefs);

            this.columnDefs = gridData.mainColDefs;
            this.gridApi.setColumnDefs(this.columnDefs);
        }
    }
}
