// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { cloneDeep } from "lodash";

// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
// Custom Imports
// =========================================================
import { GetFormErrorMessage } from 'src/app/core/utils/form-error-messages';
import { FilterService } from 'src/app/core/services/filter.service';
import {ErrorStateMatcher} from '@angular/material/core';

export class FormGroupErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control &&
      (control.parent.invalid &&
        (control.parent.dirty || control.parent.touched || isSubmitted))
      || (control.invalid &&
        (control.dirty || control.touched || isSubmitted))
    );
  }
}

@Component({
  selector: 'app-save-size-offerings',
  templateUrl: './save-size-offerings.component.html',
  styleUrls: ['./save-size-offerings.component.scss']
})

export class SaveSizeOfferingComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  modalTitle = "Save Size Offering";
  saveSizeOfferingForm: FormGroup;
  selectionOptions: any = {}
  objectKeys = Object.keys
  rowData: any = []
  singleRow: boolean = true
  noFutureSeasons: boolean = false
  currentSeason: string = ''
  currentProdId: any = {}
  currentChannel: any = {}
  newSet = new Set()


  matcher = new FormGroupErrorMatcher();

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    public filterService: FilterService,
  ) {
    const data = this.config.data
    console.log("data is", data)
    this.rowData = data.rowData
    this.singleRow = this.rowData.length === 1
    this.currentSeason = data.selectedSeason
    this.currentProdId = data.selectedProdId
    this.currentChannel = data.selectedChannel

    // Subscribe to filter options
    this.filterService.getLowestLevelFilters()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters: any) => {
        this.selectionOptions = { ...filters }
        console.log('RETURNING LOWEST LEVEL FITLERS', filters)

        if (filters?.season) {
          const currentSeason = data.seasonDates.find(seasonDate => seasonDate.season === data.selectedSeason)
          const currentDate = data.currentDate.getTime()
          const currentEnd = new Date(currentSeason.end_date).getTime()

          this.selectionOptions.seasonId = {...filters}.season?.filter(season => {
            const futureSeason = data.seasonDates.find(seasonDate => seasonDate.season === season.key)
            const futureStart = new Date(futureSeason.start_date).getTime()
            return data.selectedSeason == season.key || (futureStart > currentDate && futureStart > currentEnd)
          })
        }
      })

    console.log('this.selectionoptions', this.selectionOptions)
  }

  ngOnInit(): void {
    this.noFutureSeasons = !this.selectionOptions.seasonId ? true : this.selectionOptions.seasonId.length === 1 // selection options will always have the current season

    console.log("init save size offerings modal: ", this.config);

    // sort product ids

    this.selectionOptions.parent_product_id?.sort((a, b) => {
      return a.label.localeCompare(b.label)
    })


    // this.saveSizeOfferingForm.valueChanges.subscribe(selectedValue => {
    //   console.log('form value changed')
    //   console.log(selectedValue)
    //   if (!selectedValue.selectSeason || selectedValue.selectSeason == this.currentSeason) {
    //     // require prod id and/or channel change
    //     console.log("setting error")
    //     this.setFormError("selectSeason")
    //   } else {
    //     // do not require another change
    //   }
    // })

    var formValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const selectSeason = control.get('selectSeason');
      const selectProdId = control.get('selectProdId');
      const selectLocation = control.get('selectLocation');

      console.log("selectSeason", selectSeason)
      console.log("currentSeason", this.currentSeason)
      console.log("selectprodid",selectProdId.value)
      console.log("currentProdId", this.currentProdId)
      console.log("selectlocation",selectLocation.value)
      console.log("currentChannel", this.currentChannel)
      if (!selectSeason.value || selectSeason.value == this.currentSeason) {
        console.log("in if!")
        // is at least one other value selected AND changed?
        var result = !!selectProdId?.value && selectProdId.value != this.currentProdId
        result = result || (!!selectLocation?.value && selectLocation.value != this.currentChannel)
        console.log("result is", result)
        if (result) return null
        else return {
          noChangesError: true
        }
      }
      else return null
    };

    this.saveSizeOfferingForm = this.formBuilder.group({
      selectSeason: [{ value: null, disabled: this.noFutureSeasons }],
      selectProdId: [{ value: null, disabled: !this.singleRow }],
      selectLocation: [{ value: null, disabled: !this.singleRow }],
    }, { validators: formValidator })



    this.saveSizeOfferingForm.get("selectSeason").setValue(this.currentSeason)
    this.saveSizeOfferingForm.get("selectProdId").setValue(this.currentProdId)
    this.saveSizeOfferingForm.get("selectLocation").setValue(this.currentChannel)
    console.log("this row", this.rowData[0].data)
    // If a single row of data is being saved  update the default values for prodId and channel
    if (this.singleRow && !this.noFutureSeasons) {
      const prodIdData = this.selectionOptions.parent_product_id.find(prod => prod.parent_product_id === this.rowData[0].data.parent_product_id)
      const channelData = this.selectionOptions.channel.find(prod => prod.channel === this.rowData[0].data.channel)

      //
      this.currentProdId = prodIdData;
      this.currentChannel = channelData;
      this.saveSizeOfferingForm.get("selectProdId").setValue(prodIdData);
      this.saveSizeOfferingForm.get("selectLocation").setValue(channelData);
    }
  }
  // convenience getter for easy access to form fields
  get form() {
    return this.saveSizeOfferingForm.controls;
  }

  setFormError(controlName) {
    const params = {
      controlName: controlName,
      form: this.saveSizeOfferingForm,
      customMessages: {}
    }
    // console.log("calling setformerror", params)
    return GetFormErrorMessage(params)
  }

  onSubmit(addSize) {

    console.log("addSize", addSize)

    const loc = this.singleRow ? addSize.selectLocation : null
    const prod = this.singleRow ? addSize.selectProdId : null

    this.ref.close({
      form: {
        selectSeason: addSize.selectSeason,
        selectLocation: loc,
        selectProdId: prod
      },
      ...this.config
    })
  }

  close() {
    this.ref.close()
    this.unsubscribe$.complete();

  }

}
