import { OM_customCellFormatting } from "../03_ag-cellFormatters/review-orders-cell-formatters"
import { GenerateDefaultColDefs } from "../03_ag-cellFormatters/global-cell-formatters"

export interface OM_GridDataParams {
    tabKey: string;
    mainColDefs: any[];
    detailColDefs?: any[];
}
export async function OM_GenerateGridData(params: OM_GridDataParams ) {
    console.log("om generategriddata")
    const { tabKey, mainColDefs, detailColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = []
        console.log("generating cols for tab", gridToRender)
        Object.entries(data).map(([colKey, colVal]) => {
            // console.log("colKey", colKey)
            // console.log("colVal", colVal)
            let obj = {
               ...GenerateDefaultColDefs(colKey, colVal),
                width: colVal.refData.colSize ? colVal.refData.colSize : 100,
                flex: colVal.refData.colSize ? 0 : 1,
            }
            mappedCols.push(OM_customCellFormatting(obj, gridToRender, false))
        })
        // console.log("6", mappedCols)
        return Object.values(mappedCols)
    }

    const GenerateDynamicCols = async (data: any[], gridToRender: string) => {
        let mappedCols = []
        // console.log("detailColDefData", detailColDefData)
        // console.log('data', data)
        data.map((element) => {
            let obj = {
                ...GenerateDefaultColDefs(element, {
                    "editable": false,
                    "refData": {
                        "dataType": gridToRender === 'store_size_breaks' ? 'percentage' : 'number',
                        "selectAll": false
                    },
                    "hide": false,
                })
            }
            mappedCols.push(OM_customCellFormatting(obj, gridToRender, true))
        })
        console.log("mapped dynamic cols", mappedCols)
        return Object.values(mappedCols)
    }

    const getResults = async (tabKey) => {
        let mainCols = await GenerateCols(mainColDefs, tabKey)
        let detailCols = await GenerateDynamicCols(detailColDefs, tabKey)

        let results = await Promise.all([mainCols, detailCols]);
        const m = results.indexOf(mainCols)
        const d = results.indexOf(detailCols)

        const data = {
            mainColDefs: results[m].concat(results[d])
        }
        return data
    }
    return getResults(tabKey)

}