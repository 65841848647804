<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>

<div class="flex-center-all modal-content">
  <ag-grid-angular
    #mainGrid
    id="vendorPacksModalGrid"
    class="ag-theme-alpine ag-modal-grid-large"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [masterDetail]="true"
    [detailCellRendererParams]="detailCellRendererParams"
    [groupRemoveLowestSingleChildren]="true"
  ></ag-grid-angular>
</div>
<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Save"
  [disabled]="objectKeys(vendorPacksUpdated).length === 0"
  (update)="onSave()"
  (closeModal)="close()"
></app-modal-footer>
