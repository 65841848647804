import {
	Component,
    Input,
    OnInit
} from '@angular/core';
import { Message } from '../message';

@Component({
	selector: 'app-chat-message',
	templateUrl: './chat-message.component.html',
	styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
    @Input() message: Message
    className: string = 'message ';
    contentText: string;
    contentArray: string[];
    dimsFormatted = [];
    isAssistant: boolean;

    constructor() {
    }

    ngOnInit(): void {
        this.message.timestampFormat = (new Date(this.message.timestamp)).toLocaleString();
        this.isAssistant = this.message.role == 'assistant';
        this.className += this.isAssistant ? 'message-chatbot' : 'message-user'
        if (this.isAssistant) {
            this.contentText = this.message.contentObj.response;
            this.dimsFormatted = this.message.contentObj.dims?.map(dim => {
                const dimObj = {};
                const dimSplit = dim.split('|');
                dimObj['productID'] = dimSplit[0];
                dimObj['sizeRange'] = dimSplit.slice(1,-1).join('|');
                dimObj['channel'] = dimSplit[dimSplit.length - 1];
                return dimObj;
            })
        } else {
            this.contentText = this.message.content;
        }
        setTimeout(() => {
            var messages = document.getElementsByClassName('message');
            var messageElement = messages[messages.length - 1]
            messageElement.scrollIntoView({block: 'end'});
        });
    }
}
