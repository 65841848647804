

  <!-- Modal Header -->
  <div class="notify-modal-header">
    <h2 ><span class="material-icons notify-icon" >error_outline</span></h2>
    <h2 style="margin: 0px!important">Info</h2>
  </div>
  <!-- Modal Body -->
  <div class="modal-content notify-modal-body">
      <span style="height: 34px">
        <p class="error-title"></p>
      </span>
      <div class="notify-message-container">
        <h3><p [ngStyle]="{'text-align': 'center'}">{{ message }}</p></h3>
      </div>
  </div>
  <!-- Modal Footer -->
<app-modal-footer
  [showUpdateBtn]="false"
  (closeModal)="close($event)"
  closeBtnText="Close"
></app-modal-footer>