
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { LoadingModalComponent } from '../../components/03_modals/04_loading-modal/loading-modal.component';

let modalOpen = false
let ref: DynamicDialogRef

export function showLoadingModal(dialog: DialogService, header: string, message: string) {

  modalOpen ? (ref.close(), ref.destroy(), modalOpen = false) : null
    if( message !== 'Modal' && !modalOpen){
        modalOpen = true

        ref = dialog.open(LoadingModalComponent, {
            showHeader: false,
            styleClass: "loading-modal",
            closeOnEscape: false,
            dismissableMask: false,
            data: { header: header, message: message }
         });

    }
    return ref;
}


