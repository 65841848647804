// Custom Imports
// =========================================================

import { FormatKey } from 'src/app/core/utils/global-functions'
import { GenerateDefaultColDefs } from '../../03_ag-cellFormatters/global-cell-formatters'



export interface ReviewRequests_GridDataParams {
    mainColDefs: any;
    // detailColDefs: any;
}

export async function ReviewRequests_GenerateGridData(params: ReviewRequests_GridDataParams) {
    const { mainColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {
            const colId = colVal['refData'].customField || FormatKey(colKey)

            let obj = {
                ...GenerateDefaultColDefs(colKey, { colId: colId, ...colVal }),
            }
            Object.assign(mappedCols, {
                [obj.colId]: ReviewRequests_customCellFormatting(obj, gridToRender)
            })
        })

        return Object.values(mappedCols)
    }

    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")

        let results = await Promise.all([mainCols]);
        const m = results.indexOf(mainCols)

        const data = {
            mainColDefs: results[m],
        }
        return data
    }
    return getResults()

}

export const ReviewRequests_customCellFormatting = (col: any, gridToRender: string) => {
    const getParams = () => {
        if (gridToRender === 'main') {
            switch (col.colId) {
                case '': 
                return {}
                default: 
                return {}
            }
        }
    }
    const updatedCol = { ...col, ...getParams() }
    return updatedCol
}
