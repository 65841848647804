// Angular Imports
// =========================================================
import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, SimpleChanges } from '@angular/core';

@Component({
	selector: 'app-modal-footer',
	templateUrl: './modal-footer.component.html',
	styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent implements OnInit, AfterViewInit {

	@Output('closeModal') close: EventEmitter<any> = new EventEmitter<any>();
	@Output() update: EventEmitter<any> = new EventEmitter<any>();

	@Input() showUpdateBtn: boolean = true;
	@Input() confirmBtnText: string = 'OK';
	@Input() disabled: boolean = false;
	@Input() closeBtnText: string = 'Cancel';
	@Input() closeDisabled: boolean = false;
	@Input() loading: boolean = false;

	constructor() {}

	ngOnInit() {}

	ngAfterViewInit(): void { }

	ngOnChanges(changes: SimpleChanges) {
        console.log("changes: ", changes)

		const monitorChanges = ['disabled', 'loading']

		monitorChanges.map(key => {

			if (changes[key] && changes[key].previousValue !== changes[key].currentValue) {
				const currentVal = changes[key]?.currentValue
				switch (key) {
					case 'loading':
						this.loading = currentVal
						break;
					case 'disabled':
						this.loading = currentVal
						break;
				}
			}
		})
	}

	closeModal() {
		this.close.emit()
	}
	updateModal() {
		this.update.emit()
	}

}
