<!-- Modal Header -->
<div
	class="modal-header"
	style="display: flex; align-items: center; justify-content: space-between"
>
	<div>
		<h2 class="text-header">{{ updatedTitle }}</h2>
		<h4 *ngIf="updatedsubTitle">{{ updatedsubTitle }}</h4>
	</div>
	<button
		(mouseenter)="hover = true"
		(mouseleave)="hover = false"
		tabindex="0"
		type="button"
		[ngStyle]="{
			opacity: !showCloseBtn ? '0' : '1',
			color: hover ? '#011C26' : 'grey'
		 }"
		class="close-modal-btn"
		(click)="closeModal()"
		[disabled]="disabled"
	>
		<span class="material-icons hover">close</span>
	</button>
</div>
