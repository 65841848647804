// Angular Imports
// =========================================================
import { Component, ViewEncapsulation } from '@angular/core';
// AG Grid Imports
// =========================================================
import { ITooltipAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-custom-tooltip',
  templateUrl: './grid-custom-tooltip.component.html',
  styleUrls: ['./grid-custom-tooltip.component.scss']
})

export class GridCustomTooltipComponent implements ITooltipAngularComp {
   params: any;
   data: any;

  packName: string = ''
  configData: any[] = []

  agInit(params): void {
    this.params = params;
    console.log("tool tip params: ", params)
    this.packName = params.value
    this.configData = this.params.data['Pack Config']

    this.data = params.api.getDisplayedRowAtIndex(params.rowIndex).data;
    this.data.color = this.params.color || '#eeeeee';
 
  }
}