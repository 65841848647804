import { SortSizes } from 'src/app/core/utils/global-functions';
import { FormatPercentage } from './global-cell-formatters';

export const DSO_customCellFormatting = (col, gridToRender) => {

    const getParams = () => {
        // If the grid to render === Main Format the following custom columns
		if (gridToRender === 'main') {
			switch (col.field) {
                case "add_sizes":
                    return {
                        tooltipValueGetter: (params) => {
                            const vLength = params.value.length
                            if (vLength > 0) {
                                return `${vLength} Size Recommendation${vLength === 1 ? "" : "s"}`
                            }
                        }
                    };
                // Execute Column
                case "run_qsor":
                    return {
                        cellRendererParams: (params) => {
                            params.colDef.refData.toolTipMessage = `Re-Run:  ${params.data.prodId}`;

                            return {
                                disabled: params.context.componentParent.updatingRowData.length > 0,
                                onClick: (params) => params.context["runQSOR"](params)
                            };
                        },
                    };
                case "sizeRange":
                    return {
                        valueGetter: (params) => {
                            if (params.data) {
                                const { sizeOrder } = params.context.componentParent
                                const sortedSizes = SortSizes(sizeOrder, params.data.radcs)
                                let availablesizes = []
                                const sizeRangeNms = sortedSizes.map(size => { if (size?.delete) {availablesizes.push(size.sizeNm)};  return size.sizeNm}).join('|')
                                if (sizeRangeNms) {
                                    const availsizesFormatted = availablesizes.join('|')
                                    params.data['actualSizeRange'] = availsizesFormatted
                                    params.data.sizeRange = sizeRangeNms
                                    params.data.radcs = sortedSizes
                                    params.value = availsizesFormatted
                                    return params.value
                                 }

                            }
                        }
                    }
                default:
					return {};
            }
        // If the grid to render === Detail Format the following custom columns
        } else if (gridToRender === 'detail') {
            // handle null values
            const cellFormatter = () => {
                if (col.refData?.hideNullVal) {
                    return {
                        valueFormatter: (params) => {
                            if (params.value != 0) {
                                const value = parseFloat((params.value * 100).toFixed())
                                params.value = value

                                return FormatPercentage(params)
                            }
                            return "--";
                        }
                    };
                };
                return {};
            };

            switch (col.field) {
                // Recommendation Column
                case "reco":
                    return {
                        cellRendererParams: (params) => {
                            params.colDef.refData.toolTipMessage = `Add Size ${params.data.sizeNm}`;
                            const deleteBtn = params.data.delete
                            const hideBtn = deleteBtn || deleteBtn === 1 ? true : false;
                            return {
                                hideBtn: hideBtn,
                                disabled: hideBtn,
                                onClick: (params) => params.context["addRecommendation"](params)
                            };
                        },
                    };
                    // Delete Column
                case "delete":
                    return {
                            cellRendererParams: (params) => {
                                params.colDef.refData.toolTipMessage = `Delete Size ${params.data.sizeNm}`;
                                const deleteBtn = params.data.delete
                                const hideBtn = deleteBtn || deleteBtn === 1 ? false : true;

                                return {
                                    hideBtn: hideBtn,
                                    disabled: hideBtn,
                                    onClick: (params) => params.context["deleteSize"](params)
                                };
                            },
                        };
                default:
                    return cellFormatter()
            };
        } else {
			return {};
		}
	};
    // return updatedCol
    const updatedCol = { ...col, ...getParams() };

	return updatedCol;
}

