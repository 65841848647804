import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})

export class ReviewOrdersService {
	private api = '/api/reviewOrders';

	private ro_allRows = `${this.api}/allRows`;
    private ro_inputLinesAllRows = `${this.api}/inputLines/allRows`;
	private ro_settingsByDimId = `${this.api}/settings`;
	private ro_approve = `${this.api}/approve`;
	private ro_pending = `${this.api}/pending`;
	private ro_export = `${this.api}/export`;
	private ro_storeSizeBreaksAllRows = `${this.api}/storeSizeBreaks/allRows`;


	constructor(private http: HttpClient) {}

	ro_getAllRows(tab: string, body?: any) {
		console.log("get all rows")
		console.log(tab)
		console.log(body)
		switch (tab) {
			case 'userInput':
				console.log("user input case")
				return this.http.get<any>(`${this.api}/${tab}/allRows`);
			case 'storeSizeBreaks':
				console.log("ssb case")
				return this.http.put<any>(`${this.api}/${tab}/allRows`, body);
			case 'buySummary':
				console.log("buy summary case")
				return this.http.put<any>(`${this.api}/${tab}/allRows`, body);
			default:
				console.log("default case", body.channels)
				if (body) {
					console.log("has body")
					return this.http.put<any>(`${this.api}/${tab}/allRows`, body.channels);
				}
				else return this.http.get<any>(`${this.api}/${tab}/allRows`);
		}
	}

	ro_getAllStycIds() {
		return this.http.get<any>(`${this.api}/stycIds`)
	}

	ro_getFilteredStycIds(filtersApplied) {
		return this.http.post<any>(`${this.api}/stycIds`, filtersApplied)
	}

	ro_inputLinesGetAllRows() {
		return this.http.get<any>(this.ro_inputLinesAllRows);
	}

	ro_getSettingsByDimId(dimId: number) {
		return this.http.get<any>(`${this.ro_settingsByDimId}/${dimId}`)
	}

	ro_setSettings(settings: any, dimId: number) {
		return this.http.put<any>(`${this.ro_settingsByDimId}/${dimId}`, settings)
	}
	
	ro_approveRows(rows: any[]) {
		return this.http.put<any>(`${this.ro_approve}`, rows)
	}

	ro_markRowsPending(rows: any[]) {
		return this.http.put<any>(`${this.ro_pending}`, rows)
	}

	ro_storeSizeBreaksGetAllRows() {
		return this.http.get<any>(this.ro_storeSizeBreaksAllRows);
	}
}
