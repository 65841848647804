// Angular Imports
// =========================================================
import { Component } from '@angular/core';
// Ag Grid Imports
// =========================================================
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';// Prime NG Imports
// Custom Imports
// =========================================================

@Component({
  selector: 'app-detail-cell-renderer',
  templateUrl: `./vendor-packs-modal-detail-grid.component.html`,
})

export class VendorPackModalDetailGrid implements ICellRendererAngularComp {

    gridApi: GridApi;
    gridOptions: any = {};
    // Grid Data
    params;
    masterGridApi;
    rowId;

    // called on init
    agInit(params: any): void {
      this.params = params;

      this.masterGridApi = params.api;
      this.rowId = params.node.id;

      this.gridOptions = {
        rowData: params.data.pack_config,
        columnDefs: params.detailGridOptions.columnDefs,
        ...params.detailGridOptions,
      }
    }

    // called when the cell is refreshed
    refresh(params: any): boolean {
      return false;
    }

    onGridReady(event: GridReadyEvent) {
      this.gridApi = event.api;
      let gridInfo = {
        id: this.rowId,
        api: event.api,
        columnApi: event.columnApi,
      };
      console.log('adding detail grid info with id: ', this.rowId);
      this.masterGridApi.addDetailGridInfo(this.rowId, gridInfo);
    }
    ngOnDestroy(): void {
      // detail grid is automatically destroyed as it is an Angular component
      console.log('removing detail grid info with id: ', this.rowId);
      this.masterGridApi.removeDetailGridInfo(this.rowId);
    }
}