import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})

export class TuneMLService {
    private api = '/api/tuneMl';

    private getAllIds = `${this.api}/getRecordIds`;
    private getRecById = `${this.api}/getRecordById`;
    private submitRecord = `${this.api}/submitRecord`;

    constructor(private http: HttpClient) { }

    getRecordIds() {
        return this.http.get<any>(this.getAllIds);
    }

    getRecordById(rec_id) {
        return this.http.get<any>(`${this.getRecById}/${rec_id}`)
    }

    submitRecordById(data) {
        return this.http.put<any>(`${this.submitRecord}`, data)
    }
}
