import { Component } from '@angular/core';

import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'filter-cell',
    templateUrl: './row-loading-renderer.html',
    styleUrls: ['./row-loading-renderer.scss'],
})

export class RowLoadingCellRenderer implements ICellRendererAngularComp {

    loadingMessage: string;

    agInit(params: any): void {
        const data = params.node.data;
        const loadingM = params.context.componentParent.loadingMessage;
        if(loadingM !== '') {
            this.loadingMessage = loadingM
        } else {this.loadingMessage = "Loading"};
    }

    refresh(params: any): boolean {
        throw new Error("Method not implemented.");
    }
    afterGuiAttached?(params?: import("ag-grid-community").IAfterGuiAttachedParams): void {
        throw new Error("Method not implemented.");
    }
      mouseWheelListener(event) {
        event.stopPropagation();
      }

}