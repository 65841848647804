import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() loadingMessage: string;

  isLoading: boolean = true
  isExporting: boolean = false

  constructor() {
  }

  ngOnInit() {
    this.isLoading = this.loadingMessage === "loading"
    this.isExporting = this.loadingMessage === "exporting"
    console.log("loading render: ", this.loadingMessage)
  }

}
