//  ----------------------------
//  Order Management Formatters
//  ----------------------------

import { FormatPercentage } from './global-cell-formatters';
import { FormatKey, GetValue } from 'src/app/core/utils/global-functions';

// Conditional cell Formatting
export const OM_customCellFormatting = (col: any, tabKey: string, isDynamicCol?: boolean) => {
	const getParams = () => {
		switch (tabKey) {
			case 'buy_summary':
				return GetBuySummaryParams(col, isDynamicCol);
			case 'user_input':
				return GetUserInputParams(col, isDynamicCol);
			case 'carton_summary':
				return GetCartonSummaryParams(col, isDynamicCol);
			case 'store_size_breaks':
				return GetStoreSizeBreaksParams(col, isDynamicCol);
			case 'store_sku_plan':
				return GetStoreSkuPlanParams(col, isDynamicCol);
		}
	};
	const updatedCol = { ...col, ...getParams() };

	return updatedCol;
};

export const GetBuySummaryParams = (col: any, isDynamicCol?: boolean) => {
	if (col.colId === 'chart') {
		return {
			cellRendererParams: (params) => {
				// params.colDef.refData.toolTipMessage = `Add Size ${params.data.sizeNm}`;
				return {
					onClick: (params) => params.context['openMismatchModal'](params),
				};
			},
		};
  }
  else if (isDynamicCol) {
    return {
		aggFunc:  (params) => {
			console.log("Custom Buy Sum Agg Func Params: ", params);
			// console.log("Custom Buy Sum Agg Func Values: ", params.values);
			const data = params.rowNode.data ? params.rowNode.data : params.rowNode.aggData

			let qty_over_total = 0;
			let qty_under_total = 0;
			let qty_bought_total = 0;

			params.values.forEach((obj) => {

				if (obj) {
					qty_over_total += obj.qty_over;
					qty_under_total += obj.qty_under;
					qty_bought_total += obj.qty_bought;
				}
			});

			const value = data?.metric === "Buy" ? qty_bought_total : qty_over_total + qty_under_total

			return {
				qty_over: qty_over_total,
				qty_under: qty_under_total,
				qty_bought: qty_bought_total,
				value: data ? value : 0,
				toString: () => {
					return data ? value : 0
				},
			};
		},
		valueGetter: (params) => {
			const data = params.node.data ? params.node.data : params.node.aggData
			// console.log("buySummSizeSum -----------: ", params)

			if (params.node.group) {
				return params.value
			}
			if (data && data.hasOwnProperty(col.colId)) {

				if (!params.node.group) {
				if (data.metric === "Buy") {
					return {
						qty_over: 0,
						qty_under: 0,
						qty_bought: data[col.colId]?.qty_bought,
						value: data[col.colId]?.qty_bought,
					};
					  } else {
						return {
							qty_over: data[col.colId].qty_over,
							qty_under: data[col.colId].qty_under,
							qty_bought: 0,
							value: data[col.colId].qty_over + data[col.colId].qty_under,
						};
					  }
				}
			}
	  }
    }
  } else {
		return {};
	}
};

export const GetUserInputParams = (col: any, isDynamicCol?: boolean) => {
	switch (col.field) {
		//
		case 'settings':
			return {
				cellRendererParams: (params) => {
					// params.colDef.refData.toolTipMessage = `Add Size ${params.data.sizeNm}`;
					return {
						onClick: (params) => params.context['openConfigModal'](params),
					};
				},
			};
		case 'gap_pct':
			return {
				valueFormatter: (params) => {
					const value = GetValue(params.value)
					return isNaN(value) ? value : FormatPercentage({ ...params, value: value * 100 })
				}
			}
		default:
			return {};
	}
};

export const GetCartonSummaryParams = (col: any, isDynamicCol?: boolean) => {
	switch (col.field) {
		//
		case 'config':
			return {
				cellRendererParams: (params) => {
					return {
						onClick: (params) => params.context['openPackConfigModal'](params),
					};
				},
			};
		default:
			return {};
	}
};
export const GetStoreSizeBreaksParams = (col: any, isDynamicCol?: boolean) => {
	switch (col.field) {

		case 'select':
			return GenerateChartBn;
		case 'calc_breaks_run':
			return {
				valueFormatter: (params) => {
					if (params.value) {
						return 'RUNNING';
					} else {
						return 'DONE';
					}
				},
			}
		case 'chart':
			return {
				cellRendererParams: (params) => {
					// params.colDef.refData.toolTipMessage = `Add Size ${params.data.sizeNm}`;
					return {
						onClick: (params) => params.context['viewCharts'](params),
					};
				},
			};
		default:
			// format % cells
			if (isDynamicCol) {
				return {
					valueGetter: (params) => {
						if (params.node.group) {
							if (params.node.key === 'Total') {
								return '--';
							} else {
								const keysArray = params.node.key.split('|');
								const channel = FormatKey(keysArray[keysArray.length - 1]);
								if (channel) {
									const extraData = params.context.componentParent.extraData;
									const value = extraData[channel][col.colId];
									return value ? value.rad_c : '';
								} else {
									return '';
								}
							}
						} else {
							params.value = params.data[col.colId];
							return params.value;
						}
					},
					valueFormatter: (params) => {
						if (params.node.key !== 'Total' && params.value != 0) {
							const value = params.value * 100;
							params.value = value;
							return FormatPercentage(params);
						}
						return '--';
					},
				};
			} else {
				return {};
			}
	}
};
export const GetStoreSkuPlanParams = (col: any, isDynamicCol?: boolean) => {
  switch (col.field) {
    //
    case 'select':
      return GenerateChartBn;
    default:
      if (isDynamicCol) {
        return {
          aggFunc: "sum",
        }
      } else {

        return {
        // TODO: is this code still needed
        // valueGetter: (params) => {
        //   if (params.data) {
        //     if (params.data.id && params.data.id === 'totals-row') {
        //       let total = 0;
        //       switch (params.colDef.field) {
        //         case 'store':
        //           return params.data[params.colDef.field];
        //         default:
        //           params.context.componentParent.gridOptions.rowData.forEach(
        //             (row) => (total += Number(row[params.colDef.field]))
        //           );
        //           params.data[params.colDef.field] = total;
        //           return total;
        //       }
        //     } else {
        //       return params.data[params.colDef.field];
        //     }
        //   }
        // },
      };
  }
	}
};

export const GenerateChartBn = {
	cellRenderer: (params: any) => {
		// if (params.node.id === "t-0") {
		if (params?.data?.store === 'All Store Totals') {
			const element = document.createElement('div');
			const imageElement: string = `<span class="flex-center-all" style="height: 36px"> <mat-icon  role="img" class="mat-icon notranslate mat-tooltip-trigger material-icons icon-button mat-icon-no-color" aria-hidden="true" matTooltip="View Chart" matTooltipClass="chart-btn-tooltip" aria-label="View Chart">
        <span class="material-icons chart-icon"> insights </span>
     </mat-icon></span>`;

			element.innerHTML = imageElement;
			element.appendChild(document.createTextNode(params.value));
			return element;
		}
		return undefined;
	},
	onCellClicked: (params) => {
		if (params.node.id === 't-0') params.context['viewCharts'](params);
	},
};
