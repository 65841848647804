// Angular Imports
// =========================================================
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
// Amplify
// =========================================================
import { Auth } from 'aws-amplify';
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service';
// Custom Imports
// =========================================================
import { environment } from '../../../../environments/environment';
import { routes } from '../../../app-routing.module';
@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
	environment = environment;
	@Input('accountType') accountType: string;
	@Input() pageTitle: string;

	active = this.router.url;
	dashboardActive: boolean = true;


	constructor(
		private router: Router,
		public cognitoService: CognitoService
	) {}

	ngOnInit() {
		// after authentication a temporary url was triggering the dashboard active flag to false
		const paths = routes.length > 0 ? routes.map(route => route.path) : null
		if (this.active.includes('size-intelligence-modules-dashboard') || this.active === '/') {
			return (this.dashboardActive = true);
		} else {
			this.dashboardActive = false
		}
	}

	onLogout() {
		this.cognitoService.signOut();
	}

	onClickNav(path: string) {
		this.router.navigateByUrl(path);
	}
}
