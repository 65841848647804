export class Message {
    role: string;
    timestamp: number;
    timestampFormat: string;
    content: string;
    contentObj?: {
        intent: string,
        response: string,
        keyword: string,
        dims: string[],
        product_id: string,
        channel: string,
        parameter_key: string,
        parameter_value: string,
        size_range: string
    }
}

export function createMessage(text: string, role = "user"): Message {
    const messageObj = {
        role: role,
        content: text,
        timestamp: Date.now(),
        timestampFormat: '',
    }
    if (role == 'assistant') {
        messageObj['contentObj'] = JSON.parse(text);
    }
    return messageObj;
}

export class ClaudeResponse {
    "id": string;
    "type": string;
    "role": string;
    "content": [
        {
            "type": string,
            "text": string
        }
    ];
    "stop_reason": string;
    "stop_sequence": any;
    "usage": {
      "input_tokens": number,
      "output_tokens": number
    };
}