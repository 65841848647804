<!-- Modal Header -->
<div class="modal-footer">
	<button
		*ngIf="showUpdateBtn"
		mat-raised-button
		class="submit-btn-dark-reverse"
		type="button"
		class="submit-btn"
		style="margin-right: 6px"
		(click)="updateModal()"
		[disabled]="disabled"
	>
		{{confirmBtnText}}
	</button>
	<button
		mat-button
		class="text-warning"
		type="button"
		(click)="closeModal()"
		[disabled]="closeDisabled"

	>
		<span [ngSwitch]="showUpdateBtn">
			<span *ngSwitchCase="true"> {{ closeBtnText }}</span>
			<span *ngSwitchCase="false">{{ closeBtnText === 'Cancel' ? 'Close' : closeBtnText }}</span>
		</span>
	</button>
</div>
