import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"

export interface ESD_GridDataParams {
    mainColDefs: any[];
}

export async function ESD_GenerateGridData(params: ESD_GridDataParams ) {
    const { mainColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {

            let obj = {
                ...GenerateDefaultColDefs(colKey, colVal),
            }
            Object.assign(mappedCols, {
                [obj.colId]:  ESD_customCellFormatting(obj, gridToRender)
            })
        })

        return Object.values(mappedCols)
    }


    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")

        let results = await Promise.all([mainCols]);
        const m = results.indexOf(mainCols)
        const data = {
            mainColDefs: results[m],
        }
        return data
    }
    return getResults()

}

export const ESD_customCellFormatting = (col, gridToRender) => {
    let updatedCol = col
    // Delete Column
    if (col.field === "delete") (
        Object.assign(updatedCol, {
            cellRendererParams: (params) => {
                if (!params.node.group) {
                    params.colDef.refData.toolTipMessage = `Delete ${params.data.parameter_id}`;

                return {
                    onClick: (params) => params.context["deleteDistro"](params)
                };
                }

            },
        })
    );
    return updatedCol

}