import { IsKeyInObj } from './global-functions'

export interface ErrorParams {
    controlName: string,
    form: any,
    customMessages?: any,
}

export const GetFormErrorMessage = (formDetails: ErrorParams) => {
    // console.log("calling getformerrormessage", formDetails)
    const {controlName, form, customMessages } = formDetails
    const errors = form.get(controlName).errors || form.errors || {}
    let message = []

    Object.keys(errors).map(error => {
      // console.log("error is", error)
      const getDefault = () => {
        switch (error) {
          case "required":
           return "Please enter a value.";
          case "min":
            return `The value must be greater than ${errors[error].min}.`;
          case "max":
            return `The value must be less than ${errors[error].max}.`;
          case "duplicateValue":
            return `The ${(form.controls[controlName].value)} already exists. The value must be unique.`;
          case "pattern":
            return "Value is not valid.";
          case "noChangesError":
            return "At least one value must be changed";
          default:
            return "Value is not valid. Please enter a new value.";
        }
      }
      const customM = IsKeyInObj(customMessages, error) && IsKeyInObj(customMessages[error], controlName)
      const defaultM = customM ? customMessages[error][controlName] : getDefault();

      message.push(defaultM)
    })
    // console.log("message",message)
    return message.join(" ")

}