// Angular Imports
// =========================================================
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
// Amplify Imports
// =========================================================
import Amplify, { Cache, Auth, Hub, Storage, Logger } from 'aws-amplify';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import awsconfig from '../../../../aws-exports';
// Custom Imports
// =========================================================
import { redirectSignIn, redirectSignOut } from 'src/app/core/utils/global-functions';

@Injectable({
	providedIn: 'root',
})
export class CognitoService {
	private authenticationSubject: BehaviorSubject<any>;
	private triggerUserUpdate = new Subject<any>();
	public triggerUserUpdate$ = this.triggerUserUpdate.asObservable();
	// processingSignIn: boolean = false;
	loading: boolean = true;
	isUserIdle: boolean = false;
	tokenExpiration: number;
	tempFlag: boolean = true;
	user: string;
	callbackUrl: string;
	// Track Auth State
	logger = new Logger('Logger', 'INFO');

	constructor(private router: Router) {
		this.authenticationSubject = new BehaviorSubject<boolean>(false);
	}

	public hubListener() {
		// Listen for authentication updates
		Hub.listen('auth', async ({ payload, payload: { event, data } }) => {
			// console.log('hub listener: ', event);
			// console.log('payload: ', payload);

			switch (event) {
				case 'signIn':
				case 'cognitoHostedUI':
					if(!this.user) {
						this.tempFlag = false;
						this.checkUser();
					}
					this.logger.info('user signed in');
					this.router.navigateByUrl('/size-intelligence-modules-dashboard');
					break;
				case 'signIn_failure':
				case 'cognitoHostedUI_failure':
					this.logger.info('user sign in failed');
					break;
				case 'signUp':
					this.logger.info('user signed up');
					break;
                case 'configured':
                    this.logger.info('the Auth module is configured');
					// console.log('Payload Data: ', data);
                    this.checkUser();
					break;
				case 'oAuthSignOut':
					this.logger.info("oAuthSignOut: ", data);
					break;
				case 'signOut':
					this.logger.info('user signed out');	
					this.clearUserDetails();
					break;
				case 'cognitoHostedUI':
					this.logger.info("cognitoHostedUI: ", data);
					this.checkUser();
					break;
				case 'implicitFlow':
					// console.log('implicit flow: ', payload);
					break;
				case 'tokenRefresh':
					// console.info('token refresh succeeded');
					break;
				case 'tokenRefresh_failure':
					// console.error('token refresh failed');
					this.signOut();
					break;
				case 'parsingCallbackUrl':
					this.logger.info("Parsing Callback Url: ", event)
					break;
				case 'codeFlow':
					this.logger.info("codeFlow: ", data)
					break;
				default:
					this.logger.error('Something went wrong, look at data object', event);
			}
		});
	}
	async parseUrlAuth(data) {
		this.callbackUrl = data.url;
		// let userSignedIn;
		// console.log("in parse url");
		// try {
			// const userSignedIn = await Auth.currentAuthenticatedUser() || null;
			// console.log("in parse url, user signed in", userSignedIn);
			

			
		// } catch (error){
			// console.error("parseurlauth catch")
			// console.error(error)
			// console.error(error == 'The user is not authenticated')
			// if(error == 'The user is not authenticated') {
			// 	console.log("in user signe in parse", this.router.url)	
			// 	console.log(window.location.href)
			// 	if(this.router.url !=='/') {		
			// 		console.log("parse url, no user found, fetching token")
					// ignore error due to oauth handler being private
					// @ts-ignore
					// await Auth._oAuthHandler.handleAuthResponse(this.callbackUrl).then((resp) =>{
					// 	console.log("aith handle code")
					// 	console.log(resp)
					// 	if (resp.accessToken && typeof  resp.accessToken === 'string' ) {
					// 		const AccessToken = new 
					// 		AmazonCognitoIdentity.CognitoAccessToken({ 
					// 			AccessToken: resp.accessToken, 
					// 		});
					// 		const IdToken = new AmazonCognitoIdentity.CognitoIdToken({    
					// 			IdToken: resp.idToken, 
					// 		});
					// 		const RefreshToken = new    
					// 		AmazonCognitoIdentity.CognitoRefreshToken({ 
					// 			RefreshToken: resp.refreshToken, 
					// 		});
					// 		const sessionData = { 
					// 			IdToken: IdToken, 
					// 			AccessToken: AccessToken, 
					// 			RefreshToken: RefreshToken, 
					// 		};
				
					// 		const session = new AmazonCognitoIdentity.CognitoUserSession(     
					// 			sessionData 
					// 		);
				
					// 		var poolData = { 
					// 			UserPoolId: awsconfig.aws_user_pools_id, 
					// 			ClientId: awsconfig.aws_user_pools_web_client_id, 
					// 		};
					// 		var userPool = new AmazonCognitoIdentity.CognitoUserPool( 
					// 			poolData 
					// 		);
					// 		var userData = { 
					// 			Username: AccessToken.payload.username, 
					// 			Pool: userPool, 
					// 		};
					// 		var cognitoUser = new AmazonCognitoIdentity.CognitoUser( 
					// 			userData 
					// 		);
					// 		cognitoUser.setSignInUserSession(session);
							
					// 		// Auth.currentAuthenticatedUser()
					// 		// .then((user) => {
					// 		// 	localStorage.setItem("currentUser", "true")
					// 		// 	this.user = user;
					// 		// 	console.log("Checking for user in cognito service: ", user)
					// 		// 	this.processingSignIn = false;

					// 		// 	return this.triggerUserUpdate.next(user.username);
					// 		// })
					// 		// .catch(async () => {
					// 		// 	this.user = null;
					// 		// 	console.log('Hub listener - No user found');
					// 		// 	this.processingSignIn = false;

					// 		// 	return this.triggerUserUpdate.next(null);
					// 		// });
					// 	}
					// 	else {
					// 		if(this.tempFlag) {
					// 			this.tempFlag = false;
					// 			this.authFederatedSignIn();
					// 			// setTimeout(() => { this.authFederatedSignIn();}, 600)
					// 		}
					// 	} 
					// })
			// 	}
			// }
		// 	this.authFederatedSignIn();
		// 	// this.triggerUserUpdate.next(null);
		// } finally {
			
		// }
		
	}

	async authCodeFlow() {
		// console.log("codeeeeeeeeeeeee flowwwwwwwwwwwwwwwwwwwwwwwwww",this.callbackUrl)
		// Auth.currentAuthenticatedUser()
		// 	.then((user) => {
		// 		localStorage.setItem("currentUser", "true")
		// 		this.user = user;
		// 		console.log("Checking for user in cognito service: ", user)
		// 		// this.processingSignIn = false;

		// 		return this.triggerUserUpdate.next(user.username);
		// 	})
		// 	.catch(async () => {
		// 		this.user = null;
		// 		console.log('Hub listener - No user found');
		// 		// this.processingSignIn = false;

		// 		return this.triggerUserUpdate.next(null);
		// 	});
	}

	async checkUser(test?: boolean) {
		// console.log("in check user")

		// const redirectUrl = redirectSignIn(awsconfig);
	
		// const userHasSignedIn = localStorage.getItem("currentUser");
		// let  userHasSignedIn; 
		// try {
		// 	userHasSignedIn = await Auth.currentAuthenticatedUser()
		// } catch (error){
		// 	console.log(error)
		// } finally {
			// console.log("userrrrrrr: ", userHasSignedIn, typeof userHasSignedIn);
			// console.log(window.location.href)
			// const testUrl = window.location.href.split("code=")[1] || null;
			// console.log("testurlllll",testUrl)
			// const testUrl = window.location.href.split("code=")[1] || null;
			// const testUrl2 = redirectSignIn + (testUrl ? `?code=${testUrl}` : this.router.url);
			// URL has to be = redirect URL in Cognito User Pool

			// const testUrl2 = redirectUrl + (testUrl ? `?code=${testUrl}` : this.router.url.substring(1));
			// console.log(testUrl2)
			// console.log(this.user)
			// console.log("routerrrr url",this.router.url)
			// console.log(awsconfig)
			// // Only call auth flow if user is not authenticated
			// // Required because code cannot be exchanged for token multiple times
			// if(!userHasSignedIn) {
			// 	// if(!userHasSignedIn && !this.processingSignIn) {
			// 	console.log(Auth)
			// 	// this.processingSignIn = true;
			// 	// ignore error due to oauth handler being private
			// 	// @ts-ignore
			// 	// Auth._oAuthHandler.handleAuthResponse(testUrl2).then((resp) =>{
			// 	// 	console.log("aith handle code")
			// 	// 	console.log(resp)
			// 	// 	if (resp.accessToken && typeof  resp.accessToken === 'string' ) {
			// 	// 		const AccessToken = new 
			// 	// 		AmazonCognitoIdentity.CognitoAccessToken({ 
			// 	// 			AccessToken: resp.accessToken, 
			// 	// 		});
			// 	// 		const IdToken = new AmazonCognitoIdentity.CognitoIdToken({    
			// 	// 			IdToken: resp.idToken, 
			// 	// 		});
			// 	// 		const RefreshToken = new    
			// 	// 		AmazonCognitoIdentity.CognitoRefreshToken({ 
			// 	// 			RefreshToken: resp.refreshToken, 
			// 	// 		});
			// 	// 		const sessionData = { 
			// 	// 			IdToken: IdToken, 
			// 	// 			AccessToken: AccessToken, 
			// 	// 			RefreshToken: RefreshToken, 
			// 	// 		};

			// 	// 		const session = new AmazonCognitoIdentity.CognitoUserSession(     
			// 	// 			sessionData 
			// 	// 		);
			
			// 	// 		var poolData = { 
			// 	// 			UserPoolId: awsconfig.aws_user_pools_id, 
			// 	// 			ClientId: awsconfig.aws_user_pools_web_client_id, 
			// 	// 		};
			// 	// 		var userPool = new AmazonCognitoIdentity.CognitoUserPool( 
			// 	// 			poolData 
			// 	// 		);
			// 	// 		var userData = { 
			// 	// 			Username: AccessToken.payload.username, 
			// 	// 			Pool: userPool, 
			// 	// 		};
			// 	// 		var cognitoUser = new AmazonCognitoIdentity.CognitoUser( 
			// 	// 			userData 
			// 	// 		);
			// 	// 		cognitoUser.setSignInUserSession(session);
						

			// 	// 		Auth.currentAuthenticatedUser()
			// 	// 		.then((user) => {
			// 	// 			localStorage.setItem("currentUser", "true")
			// 	// 			this.user = user;
			// 	// 			console.log("Checking for user in cognito service: ", user)
			// 	// 			this.processingSignIn = false;

			// 	// 			return this.triggerUserUpdate.next(user.username);
			// 	// 		})
			// 	// 		.catch(async () => {
			// 	// 			this.user = null;
			// 	// 			console.log('Hub listener - No user found');
			// 	// 			this.processingSignIn = false;

			// 	// 			return this.triggerUserUpdate.next(null);
			// 	// 		});
			// 	// 	}
			// 	// 	else {
			// 	// 		if(this.tempFlag) {
			// 	// 			this.tempFlag = false;
			// 	// 			this.authFederatedSignIn();
			// 	// 			// setTimeout(() => { this.authFederatedSignIn();}, 600)
			// 	// 		}
			// 	// 	} 
			// 	// })
			// }
			// else {
				Auth.currentAuthenticatedUser()
					.then((user) => {
						this.user = user;
						console.log("Checking for user in cognito service: ", user)
						return this.triggerUserUpdate.next(user.username);
					})
					.catch(async () => {
						this.user = null;
						console.log('Hub listener - No user found');
						return this.triggerUserUpdate.next(null);
					});
			// }
		
		// }
	}
	// Sign out of the application
	public async signOut(): Promise<any> {
		console.log('Signing out');
		// this.clearUserDetails()
		return Auth.signOut().then(() => {
			// this.checkUser();
			this.router.navigateByUrl('/');
			this.authenticationSubject.next(false);
		});
	}
	// Verify that the user is authenticated
	public isAuthenticated(): Promise<boolean> {
		if (this.authenticationSubject.value) {
			return Promise.resolve(true);
		} else {
			return this.getUser()
				.then((user: any) => {
					if (user) {
						return true;
					} else {
						return false;
					}
				})
				.catch(() => {
					return false;
				});
		}
	}
	// Get the current user details
	public getUser(): Promise<any> {
		return Auth.currentSession();
		// return Auth.currentUserInfo();
	}
	// First step to user login -> authenticate the account ID
	public async authUIStateChange(authState, authData) {}

	// Refresh access tokens
	refreshToken = async () => {
		// refresh the token here and get the new token info
		const cognitoUser = await Auth.currentAuthenticatedUser();

		await Auth.currentSession().then((session) => {
			// console.log('current session: ', session);

			const cognitoUserSession = session;
			const refresh = cognitoUserSession.getRefreshToken();
			// console.log('refresh: ', refresh);

			cognitoUser.refreshSession(refresh, (err, success) => {});
		});
	};

	// TODO: add functionality to update user credentials
	public updateUser(user): Promise<any> {
		return Auth.currentUserPoolUser().then((cognitoUser: any) => {
			return Auth.updateUserAttributes(cognitoUser, user);
		});
	}
	// When the user signs out or the session times put -> reset the data from cognito and session storage
	public async clearUserDetails() {
		try {
			// console.log('Clearing User Details');
			await Cache.removeItem('accountId');
			await Cache.removeItem('accountConfig');
			await Cache.clear();
			await localStorage.clear();
			await sessionStorage.clear();
		} catch (error) {
			console.log('error clearing user details');
        } finally {
			location.reload();
		}
	}

	public async authFederatedSignIn(): Promise<any> {
        console.log("---- Federate Login ---- ")
        
            return Auth.federatedSignIn(
            ).then(async (cred) => {
                // If success, you will get the AWS credentials
                // console.log("credentials: ", cred);
                return await Auth.currentAuthenticatedUser();
            }).then(user => {
                // If success, the user object you passed in Auth.federatedSignIn
                console.log("success", user);
				return user
            }).catch(e => {
                console.log(e)
            });
    }
}
