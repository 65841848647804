import { Component, ViewChild } from '@angular/core';
import { IToolPanel, IToolPanelParams } from '@ag-grid-community/all-modules';
import { MatAccordion } from '@angular/material/expansion';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'ManageDistroChanges',
	templateUrl: `./manage-distro-changes.html`,
	styleUrls: ['./manage-distro-changes.scss'],
})
export class ManageDistroChanges implements IToolPanel {
	private params: IToolPanelParams;
	masterGridApi;
	parentGridContext;

	addedDistros: any[] = [];
	deletedDistros: any[] = [];
	environment = environment;

	@ViewChild(MatAccordion) accordion: MatAccordion;

	agInit(params: IToolPanelParams): void {
		this.params = params;
		console.log('manage deleted rows panel: ', params);
		this.masterGridApi = params.api;
		this.parentGridContext = {
			...params.context.componentParent,
			...params.context,
		};

		// calculate stats when new rows loaded, i.e. onModelUpdated
		this.params.api.addEventListener(
			'modelUpdated',
			this.updateTotals.bind(this)
		);
	}

	updateTotals(): void {
		// console.log('model data changed: ', this.parentGridContext);
		this.deletedDistros = this.parentGridContext.sortGridRows(
			this.parentGridContext.deletedDistros
		);
		this.addedDistros = this.parentGridContext.sortGridRows(
			this.parentGridContext.addedDistros
		);
	}

	refresh(): boolean {
		return false;
	}
}
