// Angular Imports
// =========================================================
import { Component, OnInit, OnDestroy, Injectable } from '@angular/core';
// Prime NG (UI) Imports
// =========================================================
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
// NG Idle Imports
// =========================================================
import { Idle } from '@ng-idle/core';
import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service';
import { Subject, takeUntil } from 'rxjs';
// Custom Imports
// =========================================================
// import { AuthService } from 'src/app/core/authentication/auth.service';
@Injectable({
	providedIn: 'root',
})
@Component({
	selector: 'app-loading-modal',
	templateUrl: './loading-modal.component.html',
	styleUrls: ['./loading-modal.component.scss'],
	providers: [DialogService],
})
export class LoadingModalComponent implements OnInit, OnDestroy {
	private unsubscribe$ = new Subject<void>();

	timeStamp: string = '';
	title: string = '';
	loadingMessage: string = '';
	customMessage: string = '';
	countdown: number = 60;

	constructor(
		public ref: DynamicDialogRef,
		public dialogService: DialogService,
		public config: DynamicDialogConfig,
		private idle: Idle,
		public cognitoService: CognitoService
	) {}

	ngOnInit(): void {
		// Data
		let data = this.config.data;
		this.title = data.header;
		this.loadingMessage = data.message;
	}
	// Close the modal
	close(event) {
		this.ref.close();
	}
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		this.ref.close();
	}
}
