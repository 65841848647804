<!-- <header class="p-grid"> -->
<header class="g-flex-center-between">
  <div class="logo-container">
    <img
      class="sizeo-logo"
      alt="sizeo Logo"
      [src]="environment.assets + '/images/Sizeo_logo_no_hanger.svg'"
    />
  </div>
  <!-- Title -->
  <h1 class="p-col dashboard-title m-0-all">{{ pageTitle }}</h1>
  <!-- Nav Bar Buttons -->
  <div class="nav-btn-container g-flex-center-right">
    <!-- Logout Button -->
    <button
      class="logout-btn"
      (click)="onLogout()"
      mat-raised-button
      aria-label="Log Out"
    >
      Logout
    </button>
  </div>
</header>
<!-- Spacer -->
<div style="height: 74px; width: 100%"></div>

<!-- Dashboard Navigation -->
<div
  class="dash-btn-nav-container"
>
  <h4 
  *ngIf="!dashboardActive"
	(click)="onClickNav('/size-intelligence-modules-dashboard')"
	class="dash-btn-nav-text"
  >
    <mat-icon aria-hidden="false" aria-label="Back to Dashboard"
      >keyboard_arrow_left</mat-icon>
    Dashboard
  </h4>
  <h2
  *ngIf="dashboardActive"
	class="title"
  >
    Dashboard
  </h2>
</div>
