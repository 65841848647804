// Angular Imports
// =========================================================
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterViewInit,
} from '@angular/core';

@Component({
	selector: 'app-modal-header',
	templateUrl: './modal-header.component.html',
	styleUrls: ['./modal-header.component.scss'],
})
export class ModalHeaderComponent implements OnInit, AfterViewInit {
	@Output('closeModal') close: EventEmitter<any> = new EventEmitter<any>();

	@Input('modalTitle') updatedTitle: string;
	@Input('subTitle') updatedsubTitle: string;
	@Input('showCloseBtn') showCloseBtn: boolean;
	@Input() disabled: boolean = false;

	@Input('numOfSizes') numOfSizes: boolean;
	
	hover: boolean = false;
	constructor() {}

	ngOnInit() {}

	ngAfterViewInit(): void { }

	mouseEnter() {
		this.hover = true;
		console.log("hover")
	}
	closeModal() {
		this.close.emit()
	}
}
