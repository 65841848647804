<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content">
  <form
    class="w-100"
    style="
      padding: 30px 0px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    "
    [formGroup]="addNewSizeForm"
    (ngSubmit)="onSubmitSize(addNewSizeForm.value)"
  >
    <!-- Add New Size -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">New Size:</h4>
      <mat-form-field class="modal-form-input mat-accent">
        <mat-label>Select a size</mat-label>
        <mat-select formControlName="newSize" panelClass="mat-accent">
          <mat-option [value]="size.product_size" *ngFor="let size of newSizes">{{
            size.product_size
          }}</mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            addNewSizeForm.get('newSize').touched &&
            addNewSizeForm.get('newSize').errors
          "
          >{{ setFormError("newSize") }}</mat-error
        >
      </mat-form-field>
    </span>
    <!-- Set the target -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">Method:</h4>

      <mat-form-field class="modal-form-input mat-accent">
        <mat-label>Select a Method</mat-label>
        <mat-select formControlName="setMethod" panelClass="mat-accent">
          <mat-option [value]="method" *ngFor="let method of methodOptions">{{
            method
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </span>

    <!-- Add Model Size -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">Model Size:</h4>

      <mat-form-field class="modal-form-input mat-accent">
        <mat-label>Select a Model Size</mat-label>
        <mat-select formControlName="modelSize" panelClass="mat-accent">
          <mat-option
            [value]="size.sizeNm"
            *ngFor="let size of modelSizes"
            >{{ size.sizeNm }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            addNewSizeForm.get('modelSize').touched &&
            addNewSizeForm.get('modelSize').errors
          "
        >
          {{ setFormError("modelSize") }}</mat-error
        >
      </mat-form-field>
    </span>
    <!-- Add Percentage of Model -->
    <span class="modal-form-container-full-width">
      <h4 class="modal-form-label">Target Percentage:</h4>

      <mat-form-field class="modal-form-input mat-accent">
        <input
          step="0.01"
          value="0.00"
          type="number"
          matInput
          placeholder="Enter a Percentage"
          formControlName="percentage"
        />
        <mat-error
          *ngIf="
            addNewSizeForm.get('percentage').touched &&
            addNewSizeForm.get('percentage').errors
          "
        >
          {{ setFormError("percentage") }}</mat-error
        >
      </mat-form-field>
    </span>
  </form>
</div>
<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Add Size"
  (update)="onSubmitSize(addNewSizeForm.value)"
  [disabled]="!addNewSizeForm.valid"
  (closeModal)="close()"
></app-modal-footer>
