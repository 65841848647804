<div style="row-gap: 10px;" class="detail-grid-container">
  <div
    style="
      display: flex;
      column-gap: 10px;
      row-gap: 10px;
      justify-content: center;
      max-height: 340px;
      overflow: auto;
      flex-wrap: wrap;
      padding: 4px
    "
  >
   <div style="flex: 2; padding: 4px;max-width: 600px;
       width: 100%;
       min-width: 300px
       ; margin-bottom: 5px" class="mat-card">
      <p-chart 
        class="chart" 
        type="line"
        model="chartView.horizontalBarModel" 
        [data]="lineChartData_1" 
        [options]="lineChartOptions_1"
    >
    </p-chart>
    </div>
    <div style="flex: 2; padding: 4px; max-width: 600px;
    width: 100%;
    min-width: 300px;  margin-bottom: 5px" class="mat-card">
      <p-chart 
        class="chart" 
        type="line"
        model="chartView.horizontalBarModel" 
        [data]="lineChartData_2" 
        [options]="lineChartOptions_2"
    >
    </p-chart>
    </div>
    <div style="flex: 2; padding: 4px;max-width: 600px;
    width: 100%;
    min-width: 300px; margin-bottom: 5px" class="mat-card">
      <p-chart 
        class="chart" 
        type="bar" 
        model="chartView.horizontalBarModel" 
        [data]="barChartData" 
        [options]="barChartOptions"
    >
    </p-chart>
    </div>
    
   
  </div>
</div>
