

  <!-- Modal Header -->
  <div class="error-modal-header">
    <h2 ><span class="material-icons error-icon" >error_outline</span></h2>
    <h2 style="margin: 0px!important">Warning</h2>
  </div>
  <!-- Modal Body -->
  <div class="modal-content error-modal-body">
    <!-- <div> -->
      <span style="height: 34px">
        <p class="error-title">{{timeStamp}}: <b class="warning">{{title}}</b></p>
      </span>
      <div class="error-message-container">
        <p [ngStyle]="{'text-align': endSession ? 'center' : 'left'}">{{ errorMessage }} {{inactiveUser ? countdown + ' seconds' : ''}} </p>
      </div>
  </div>
  <!-- Modal Footer -->
<app-modal-footer
  [showUpdateBtn]="false"
  (closeModal)="endSession ? logout($event) : close($event)"
  closeBtnText="{{ endSession ? 'Log Out': inactiveUser ? 'Refresh Session' : 'Close'}}"
></app-modal-footer>