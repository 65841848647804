<app-modal-header
  [modalTitle]="modalTitle"
  [subTitle]="subTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="modal-content">
  <app-filters-panel
    [filtersToDisplay]="filtersToDisplay"
    [modalStyling]="true"
    [selectedData]="valueSelected"
    (valueSelected)="onValueSelected($event)"
  ></app-filters-panel>
  <!-- (valueSelected)="onValueSelected($event)" -->

  <!-- <mat-select>
    <mat-option *ngFor="let option of hierLevelOptions" [value]="option.prodLvl"
      [(value)]="currentValue">
      {{option.prodLvl}} - {{option.prodNm}}
    </mat-option>
  </mat-select> -->
</div>

<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Select"
  (update)="submit()"
  (closeModal)="close()"
  closeBtnText="Back"
  [disabled]="disabled"
></app-modal-footer>
