// Angular Imports

// =========================================================

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';

// Amplify Imports

// =========================================================

import Amplify, { Cache, Auth, Hub, Storage, Logger } from 'aws-amplify';

import { AuthenticatorService } from '@aws-amplify/ui-angular';

import awsconfig from '../../../../aws-exports';

// Custom Imports

// =========================================================

import { environment } from '../../../../environments/environment';

import { CognitoService } from 'src/app/core/services/auth/auth-cognito.service';
import { redirectSignIn, redirectSignOut } from 'src/app/core/utils/global-functions';


@Component({

    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LoginComponent implements OnInit, OnDestroy {

    environment = environment;
    user;

    constructor(
        public authenticator: AuthenticatorService,
        private router: Router,
        private cognitoService: CognitoService
    ) {}

    // isLocalhost = Boolean(

    //     window.location.hostname === "localhost" ||
    //       // [::1] is the IPv6 localhost address.
    //       window.location.hostname === "[::1]" ||
    //       // 127.0.0.1/8 is considered localhost for IPv4.
    //       window.location.hostname.match(
    //         /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    //       )
    //   );

    //   // Assuming you have two redirect URIs, and the first is for localhost and second is for production

    //   localRedirectSignIn = awsconfig.oauth.redirectSignIn.split(",")[0];
    //   productionRedirectSignIn = awsconfig.oauth.redirectSignIn.split(",")[1];
    //   localRedirectSignOut = awsconfig.oauth.redirectSignOut.split(",")[0];
    //   productionRedirectSignOut = awsconfig.oauth.redirectSignOut.split(",")[1];

    ngOnInit(): void {
        // const obj = {

        //     ...awsconfig,

        //     oauth: {

        //         ...awsconfig.oauth,

        //         redirectSignIn: redirectSignIn(awsconfig),

        //         redirectSignOut: redirectSignOut(awsconfig),

        //       },

           

        //     endpoints: [

        //         {

        //             name: 'OrchestratorApiGateway',

        //             endpoint:

        //                 'https://01heippmeb.execute-api.us-east-1.amazonaws.com/dev',

        //             custom_header: async () => {

        //                 return {

        //                     Authorization: 'token',

        //                 };

        //             },

        //         },

        //     ],

        // }

        // console.log("objjjjjj", obj)

        // Amplify.configure({
        //     ...awsconfig,
        //     oauth: {
        //         ...awsconfig.oauth,
        //         redirectSignIn: this.isLocalhost ? this.localRedirectSignIn : this.productionRedirectSignIn,
        //         redirectSignOut: this.isLocalhost ? this.localRedirectSignOut : this.productionRedirectSignOut,
        //       },
        //     endpoints: [
        //         {
        //             name: 'OrchestratorApiGateway',
        //             endpoint:
        //                 'https://lajidu6j3i.execute-api.us-east-1.amazonaws.com/qwdev',
        //             custom_header: async () => {
        //                 return {
        //                     Authorization: 'token',
        //                 };

        //             },

        //         },

        //     ],

        // });

                        // `https://${environment.apiServiceId}.execute-api.us-east-1.amazonaws.com/${environment.stage}`,


        // this.cognitoService.triggerUserUpdate$.subscribe((user) => {

        //     console.log('user: ', user);

        //     this.user = user;

        //     if (!this.user) {

        //         this.router.navigateByUrl('/');
        //         this.cognitoService.authFederatedSignIn();


        //     } else {

        //         this.router.navigateByUrl('/size-intelligence-modules-dashboard');

        //     }

        // });



        // console.log('authenticator: ', this.authenticator);



        // if (this.authenticator.route === 'authenticated' && this.router.url === '/') {

        //  console.log("current route: ", this.router.url)

        //  location.reload();

        //  this.router.navigateByUrl('/size-intelligence-modules-dashboard');

        // }

    }



    ngOnDestroy(): void {
    }

}
