// Custom Imports
// =========================================================

import { CellClickedEvent } from 'ag-grid-community'
import { ColumnEventName } from 'ag-grid-community/dist/lib/entities/column'
import { NavigateToNextCellParams, SuppressKeyboardEventParams } from 'ag-grid-enterprise'
import { FormatKey, ReverseFormatKey } from 'src/app/core/utils/global-functions'
import { GenerateDefaultColDefs } from '../../03_ag-cellFormatters/global-cell-formatters'
import { CellClassRules } from '../../g-ag-grid-cellClassRules'



export interface UploadNewSizeOfferings_GridDataParams {
    mainColDefs: any;
    // detailColDefs: any;
}

export async function UploadNewSizeOfferings_GenerateGridData(params: UploadNewSizeOfferings_GridDataParams) {
    const { mainColDefs } = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        Object.entries(data).map(([colKey, colVal]) => {
            const colId = colVal['refData'].customField || FormatKey(colKey)

            let obj = {
                ...GenerateDefaultColDefs(colKey, { colId: colId, ...colVal }),
            }
            Object.assign(mappedCols, {
                [obj.colId]: UploadNewSizeOfferings_customCellFormatting(obj, gridToRender)
            })
        })

        return Object.values(mappedCols)
    }

    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")

        let results = await Promise.all([mainCols]);
        const m = results.indexOf(mainCols)

        const data = {
            mainColDefs: results[m],
        }
        return data
    }
    return getResults()

}

export const UploadNewSizeOfferings_customCellFormatting = (col: any, gridToRender: string) => {
    const hasValue = (val) => val && (val !== '' && val !== undefined || val !== null)
    const getBlankMessage = (action, params) => {
        return hasValue(params.value) ? '' : `${action} a ${ReverseFormatKey(col.colId)}`
    }

    const getParams = () => {
        if (gridToRender === 'main') {
            switch (col.colId) {
                case 'season':
                return {
                        cellEditorParams: {
                            'propertyRendered': 'season',
                            'returnObject' : false,
                            'rowData': [],
                            'columnDefs': [
                                {
                                    headerName: 'Select A Season',
                                    field: 'season',
                                    suppressMenu: true,
                                },
                            ]
                        },
                    valueFormatter: (params) => {
                        if (params.value) return typeof params.value === 'object' ? params.value.season : params.value;
                        return "Select A Season";
                    },
                }
                case 'channel':
                return {
                    cellEditorParams: {
                        'propertyRendered': 'channel',
                        'returnObject' : false,
                        'rowData': [],
                        'columnDefs': [
                            {
                                headerName: 'Select A Channel',
                                field: 'channel',
                                suppressMenu: true,
                            },
                        ]
                    },
                    valueFormatter: (params) => {
                        if (params.value) return typeof params.value === 'object' ? params.value.channel : params.value;
                        return "Select A Channel";
                    },
                }
                case 'prodId':
                    return {
                    cellEditorParams: {
                        'propertyRendered': 'prodId',
                        'returnObject' : false,
                        'rowData': [],
                        'columnDefs': [
                            {
                                headerName: 'Select A Product',
                                field: 'prodId',
                                suppressMenu: true,
                            },
                        ]
                    },
                    valueFormatter: (params) => {
                        if (params.value) return typeof params.value === 'object' ? params.value.prodId : params.value;
                        return "Select A Product";
                    },
                }
                case 'sizeRange':
                    return {
                        valueFormatter: (params) => {
                            if (params.value) return params.value;
                            return "Create A New Size Offering";
                        },
                    };
                case "delete":
                    return {
                        cellRendererParams: (params) => {
                            params.colDef.refData.toolTipMessage = `WARNING: This cannot be reversed!`;
                            return {
                                disabled: false,
                                onClick: (params) => params.context["deleteSizeOffering"](params)
                            };
                        },
                    }
            }
        }
    }
    const updatedCol = {
        ...col,
        ...getParams(),
        cellClassRules: {
            ...CellClassRules,
        'highlight-cell': (params) => {
                // console.log("highlight cell: ", params)
                return params.context.componentParent.requiredFields.includes(col.colId) && !hasValue(params.value)
            }
        }
    }
    return updatedCol
}