// Angular Imports
// =========================================================
import { Component, OnInit } from '@angular/core';
// rxjs Imports
// =========================================================
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
// AG Grid / Chart js Imports
// =========================================================
import {
  GridReadyEvent,
  GridApi
} from 'ag-grid-community';
// Prime NG Imports
// =========================================================
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
// Mock JSON Data
// =========================================================
import RTS_MainColDefs from '../../../../core/json/set-carton-and-buy-params/colDefs-main-run-test-scenarios.json';
// Custom Imports
// =========================================================
import { DefaultDetailGridOptions } from '../../../02_ag-grid-settings/g-ag-grid-variables';
import { CustomGridOptions } from '../../../02_ag-grid-settings/g-ag-grid-options';
import { RTS_GenerateGridData } from "../../../02_ag-grid-settings/02_ag-generate-col-Defs/set-carton-and-buy-params/run-test-scenarios-grid-config"
import { RunTestScenariosDetailGrid } from "./rts-detail-grid/run-test-scenarios-detail-grid.component"
import { SetCartonAndBuyParamsService } from 'src/app/core/services/db/set-carton-and-buy-params.service';
import { handleError } from 'src/app/core/utils/error-handling';
import { DataService } from 'src/app/core/services/data.service';

@Component({
  selector: 'app-run-test-scenarios',
  templateUrl: './run-test-scenarios.component.html',
  styleUrls: ['./run-test-scenarios.component.scss']
})

export class RunTestScenariosComponent implements OnInit {
  // private unsubscribe$ = new Subject<void>();
  modalTitle = 'Test Scenario Results';
  onPromoteChanges: Function;
  onDiscardChanges: Function;

  // Ag Grid Configuration
  private customGridOptions: any = {
    ...CustomGridOptions,
    context: {
      componentParent: this,
      pageTitle: 'Test Case Results',
    },
  };

  gridApi: GridApi;
  gridOptions: any = {};
  // Grid Data
  columnDefs = []
  messages: Subscription;
  rowData: any[] = [];
  isLoading: boolean = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public dialogService: DialogService,
    private SCABP_Service: SetCartonAndBuyParamsService,
    private dataService: DataService

  ) {
    console.log("run test scenarios data: ", this.config.data)

    // Set AgGrid Options
    this.gridOptions = {
      ...this.customGridOptions,
      frameworkComponents: {
        ...this.customGridOptions.frameworkComponents,
        runTestScenariosDetailGrid: RunTestScenariosDetailGrid
      },
      detailCellRenderer: 'runTestScenariosDetailGrid',
      onRowGroupOpened: (event) => this.onRowGroupOpened(event),
      getRowHeight: (params) => { if (params.node && params.node.detail) return 380; },
    }

    this.onPromoteChanges = config.data.onPromoteChanges;
    this.onDiscardChanges = config.data.onDiscardChanges;

    this.renderGrid(RTS_MainColDefs[0])
  }

  ngOnInit(): void {
    this.dataService.connect();

    this.messages = this.dataService.messages$.subscribe((message: any) => {
      console.log('received message from server: ', message);
      if (message !== 'connection closed' && !message.error){
        if (this.config.data.updatedRows.length > 0) {
          this.SCABP_Service.updateParameterSettings(this.config.data.updatedRows)
            .subscribe({
              next: (data) => { },
              complete: () => { this.getRunTestScenariosData() },
              error: (error) => handleError(this.dialogService, error, 'Error updating parameter settings')
            })
        } else if (!message.error){
          this.getRunTestScenariosData()
        }
      } else if (message.error) {
        this.gridApi.redrawRows();
        handleError(this.dialogService, {statusText: "Error processing state machine", status: 404}, `Error: ${message.error}`)
      }
    });
  }

  getRunTestScenariosData = () => {
    this.SCABP_Service.getRunTestScenariosData(Number(this.config.data.row.dim_id))
      .subscribe({
        next: ({ data }) => {
          console.log("return data: ", data)
          // this.gridOptions.rowData = data.map(test => test.data) || []
          if (this.rowData.length === 0){
            this.rowData = data.map(test => test.data) || []
            this.gridApi.setRowData(this.rowData)
          }
        },
        complete: () => { },
        error: (error) => handleError(this.dialogService, error, 'Error fetching set carton and buy params: get run test scenarios data')
      });
  }
  // Render AG Grid row Data and Column Definitions
  async renderGrid(mainColDefs) {
    const params = {
      mainColDefs: mainColDefs,
    }

    const gridData = await RTS_GenerateGridData(params);
    if (gridData) {
      console.log("Run Test Scenarios col defs: ", gridData.mainColDefs)
      this.columnDefs = gridData.mainColDefs
    }
  }
  // On Grid Ready
  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  detailCellRendererParams = (parentParams) => ({
    detailGridOptions: {
      ...DefaultDetailGridOptions,
      context: {
        pageTitle: 'Test Scenario Results',
      },
    },
  })

  onRowGroupOpened(event: any) { }

  close() {
    this.ref.close();
  }

}
