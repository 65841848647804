// Angular Imports
// =========================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
// Prime NG Imports
// =========================================================
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
// Column Definition JSON
// =========================================================
import reviewOrdersInputLinesColDefs from '../../../core/json/review-orders/colDefs-main-review_orders.json';
import reviewOrdersSSBColDefs from '../../../core/json/review-orders/colDefs-ssb-review_orders.json';
import reviewOrdersSSPColDefs from '../../../core/json/review-orders/colDefs-ssp-review_orders.json';
import reviewOrdersBuySummcolDefs from '../../../core/json/review-orders/colDefs-buy-summ-review_orders.json';
import reviewOrderSDSColDefs from '../../../core/json/review-orders/colDefs-source-dest-summ-review_orders.json';
import reviewOrderCartonSummColDefs from '../../../core/json/review-orders/colDefs-carton-summ-review_orders.json';
// Custom Imports
// =========================================================
import { ReviewOrdersService } from 'src/app/core/services/db/review-orders.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { SelectedRowService } from 'src/app/core/services/selectedRows.service';
import { HierarchySelectComponent } from '../../03_modals/review-orders/hierarchy-level-select/hierarchy-level-select.component';

@Component({
  selector: 'app-review-orders',
  templateUrl: './review-orders.component.html',
  styleUrls: ['./review-orders.component.scss'],
})

export class ReviewOrdersComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  loadingData: boolean = true;
  disabled: boolean = true
  // Default settings
  position: number
  activeTab: number;
  // rowData;
  loadTree = false;

  selectedIndex: number;
  selectedStycId: string;
  selectableStycIds: string[] = [];
  inputLinesSelectedRows: any = {
    store_distro: null,
    styc_id: null,
    channels: [],
    rows: [],
    selectedNeedDate: null
  }
  rowsSelected = false;
  canViewData = false;

  // Output data for the different tab components
  displayData: boolean = true;
  tabs;
  ref: DynamicDialogRef;
  filtersApplied: {
    parent_product_id?: any[],
    season?: string[],
    channel?: string[]
  } = {
    parent_product_id: [],
    season: [],
    channel: []
  };

  value;

  constructor(
    private selectedRowService: SelectedRowService,
    private dialogService: DialogService,
    private ro_services: ReviewOrdersService,
    private filterService: FilterService,
    private router: Router

   ) {

    this.filterService.triggerFilterSource$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        console.log('review order filters applied', data)
        this.filtersApplied = data;
        this.ro_services.ro_getFilteredStycIds(data.parent_product_id.map(e => e.data)).subscribe(data => {
          this.selectableStycIds = data.plans.map(e => e.style_color_id)
        });
      });

    this.selectedRowService.getSelectedRowId().subscribe((id) => {this.selectedStycId = id });
    // this.selectedRowService.getSelectedRowsArray().subscribe((array) => this.selectableStycIds = array);
  }

  ngOnInit(): void {
    console.log("main row id: ", this.selectedStycId)

    this.ref = this.dialogService.open(HierarchySelectComponent, {
      showHeader: false,
      width: '30%',
      height: '70%',
      // closeOnEscape: false,
      dismissableMask: false,
      data: {
        defaultValue: {}
      },
    });

    this.ref.onClose.subscribe(value => {
      this.value = value
      if (this.value) {
        console.log('value from modal', this.value)
        this.refresh()
      }
      else {
        this.router.navigateByUrl('/')
      }
    })

    this.tabs = [

       ]
  }

  refresh() {
    // this.selectableStycIds = [];
    this.inputLinesSelectedRows = {
      store_distro: null,
      styc_id: null,
      channels: [],
      rows: [],
      selectedNeedDate: null
    }
    this.filtersApplied = {
      parent_product_id: this.value
    };
    this.ro_services.ro_getFilteredStycIds(this.value.map(e => e.data)).subscribe(data => {
      this.selectableStycIds = data.plans.map(e => e.style_color_id)
      console.log('got selectable stycs', this.selectableStycIds)
    });
    this.loadTree = true;
    this.tabs = [{
      field: ('User Input').toUpperCase(),
      id: 'userInput',
      tabKey: 'user_input',
      isTreeDataGrid: true,
      treeDataParams: {
        groupLevelExpanded: 0,
        auto_group_name: 'STATUS',
        hideLowestLevel: true
      },
      data: [],
      columnDefs: reviewOrdersInputLinesColDefs[0],
      selectStyle: false,
    },
    {
      field: ('Store Size Breaks').toUpperCase(),
      id: 'storeSizeBreaks',
      tabKey: 'store_size_breaks',
      isTreeDataGrid: true,
      treeDataParams: {
        groupLevelExpanded: 1,
        auto_group_name: 'CHANNEL',
        hideLowestLevel: true
      },
      data: [],
      columnDefs: reviewOrdersSSBColDefs[0],
      selectStyleColor: true,
      selectNeedDate: true,
    },
    {
      field: ('Store SKU Plan').toUpperCase(),
      id: 'storeSkuPlan',
      tabKey: 'store_sku_plan',
      isTreeDataGrid: true,
      treeDataParams: {
        groupLevelExpanded: 1,
        auto_group_name: 'CHANNEL',
        hideLowestLevel: true
      },
      data: [],
      columnDefs: reviewOrdersSSPColDefs[0],
      selectStyleColor: true,
      selectNeedDate: true
    },
    {
      field: ('Buy Summary').toUpperCase(),
      id: 'buySummary',
      tabKey: 'buy_summary',
      isTreeDataGrid: true,
      treeDataParams: {
        groupLevelExpanded: 1,
        auto_group_name: 'CHANNEL',
        hideLowestLevel: true
      },
      columnDefs: reviewOrdersBuySummcolDefs[0],
      selectStyleColor: true
    },
    {
      field: ('Source to Dest Summary').toUpperCase(),
      id: 'sourceDestSummary',
      tabKey: 'source_to_dest_summary',
      isTreeDataGrid: true,
      treeDataParams: {
        groupLevelExpanded: 2,
        auto_group_name: 'CHANNEL',
        hideLowestLevel: true
      },
      data:[],
      columnDefs: reviewOrderSDSColDefs[0],
      selectStyleColor: true,
      selectNeedDate: true
    },
    {
      field: ('Carton Summary').toUpperCase(),
      id: 'cartonSummary',
      tabKey: 'carton_summary',
      isTreeDataGrid: false,
      data: [],
      columnDefs: reviewOrderCartonSummColDefs[0],
      selectStyleColor: true,
      selectNeedDate: true
    }
  ]
  }

  onSelectedIndex(event) {
    this.selectedIndex = event;
    console.log(event);
    if (event === 0) {
      this.inputLinesSelectedRows.styc_id = null;
      this.inputLinesSelectedRows.store_distro = null;
    }
  }

  onRowsSelectedEvent(selected) {
    if (selected) {
      this.rowsSelected = true;
      this.inputLinesSelectedRows = selected;
      console.log("inputlinesselectedrows", this.inputLinesSelectedRows);
      const selectedRows = this.inputLinesSelectedRows.rows;
      console.log("selectedrows", selectedRows);
      const firstStycId = selectedRows[0][3];
      console.log("first styc id", firstStycId);
      let multipleStycIds = false;
      selectedRows.forEach(row => {
        console.log("row styc id", row[3]);
        if (row[3] != firstStycId) {
          console.log("multiple styc ids true");
          multipleStycIds = true;
        }
      });
      console.log("multiple styc ids", multipleStycIds);
      this.canViewData = !multipleStycIds;
      
      console.log("canviewdata", this.canViewData);
    } else {
      this.rowsSelected = false;
      this.inputLinesSelectedRows = {
        store_distro: null,
        styc_id: null,
        channels: [],
        row_ids: [],
        selectedNeedDate: null
      };
    }
  }

  onSelectedId(inputLinesSelectedRows: any) {
    this.inputLinesSelectedRows = inputLinesSelectedRows
    console.log('on selected id', this.inputLinesSelectedRows)
  //  if (!this.selectableStycIds.includes(stycId)) {
  //     this.selectableStycIds.push(stycId);
      this.selectedRowService.updateSelectedRowsArray(this.inputLinesSelectedRows)
    // }

    // this.inputLinesSelectedRows = stycId;
    //output tab selection
    this.selectedIndex = 1;
    console.log('selected index', this.selectedIndex);
  }

  ngOnDestroy() {
    this.ref.close()
  }
}
