import { CheckIsNumber, IsKeyInObj } from "src/app/core/utils/global-functions"
import { GenerateDefaultColDefs } from "../../03_ag-cellFormatters/global-cell-formatters"

export interface SDVPS_GridDataParams {
    mainColDefs: any[];
    detailColDefs: any[];
}

export async function SDVPS_GenerateGridData(params: SDVPS_GridDataParams ) {
    const { mainColDefs, detailColDefs} = params

    const GenerateCols = async (data: any[], gridToRender: string) => {
        let mappedCols = {}

        const mapCols = (colData) => Object.entries(colData).map(([colKey, colVal]) => {
            const isGroupCol = IsKeyInObj(colVal, "children")

            let obj = {
                ...GenerateDefaultColDefs(colKey, colVal),
            }
            Object.assign(mappedCols, {
                [obj.colId]:  SDVPS_customCellFormatting(obj, gridToRender)
            })
            if (isGroupCol) {
                return { ...SDVPS_customCellFormatting(obj, gridToRender), children: mapCols(obj["children"])}
             } else {
                 return  SDVPS_customCellFormatting(obj, gridToRender)
            }
        })
       // mapCols(data)
       return mapCols(data)
    }


    const getResults = async () => {
        let mainCols = await GenerateCols(mainColDefs, "main")
        let detailCols = await GenerateCols(detailColDefs, "detail")

        let results = await Promise.all([mainCols, detailCols]);
        const m = results.indexOf(mainCols)
        const d = results.indexOf(detailCols)

        const data = {
            mainColDefs: results[m],
            detailColDefs: results[d]

        }
        return data
    }
    return getResults()

}

export const SDVPS_customCellFormatting = (col, gridToRender) => {
    let updatedCol = col
      //
    if (gridToRender === 'main') {
        // Add onclick buttons to the vendor packs and edit store distro columns
        if (col.colId === "vendor_packs" || col.colId === "edit_store_distro") (
            Object.assign(updatedCol, {
                cellRendererParams: (params) => {
                    const vendorCol = params.colDef.colId === 'vendor_packs'
                    const vendor_packs = vendorCol && params.data.vendor_packs_available
                    return {
                        disabled: vendorCol && !vendor_packs,
                        onClick: (params) => params.context[params.colDef.colId](params)
                    };
                },
            })
        );
        // if (col.colId === "gap" || col.colId === "fit_score") (
        //     Object.assign(updatedCol, {
        //         valueGetter: (params) => {
            
        //             const testScenarios = params.data.test_scenarios
        //             if (testScenarios) {
        //                 const colId = params.colDef.colId
        //                 let sumOfGapCalc = 0
        //                 let sumOfFitCalc = 0
        //                 let sumOfBuy = 0
        //                 let total = null

        //                 testScenarios.map(({ fit_score, gap_pct, size_of_buy }, i) => {
        //                     // Size of Buy
        //                     const s = CheckIsNumber(size_of_buy);
        //                     sumOfBuy += s

        //                     if (colId === "fit_score") {
        //                         // Fit Score * Size of Buy
        //                         sumOfFitCalc += (CheckIsNumber(fit_score) * s)
        //                     };
        //                     if (colId === "gap") {
        //                         // Gap % * Size of Buy
        //                         sumOfGapCalc += (CheckIsNumber(gap_pct) * s)
        //                     }
        //                     // console.log("fit_score: ", sumOfFitCalc)
        //                     // console.log("gap_pct: ", sumOfGapCalc)
        //                     // console.log("size_of_buy: ", sumOfBuy)

        //                     if (i + 1 === testScenarios.length) {
        //                         if (colId === "fit_score") {
        //                             total = sumOfFitCalc / sumOfBuy
        //                         };
        //                         if (colId === "gap") {
        //                             total = sumOfGapCalc / sumOfBuy
        //                         };
        //                     }
        //                 })
        //                 console.log("gap value getter: ", total)
        //                 if (total !== null) return total === 0 ? '--' : total;
        //             } else {
        //                 return '--'
        //             }


        //         },
        //     })
        // );
    };
    if (gridToRender === 'details'){}


    return updatedCol

}