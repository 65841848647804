<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div
  class="flex-center-all modal-content pack-sum-modal"
  style="max-height: 80vh"
>
<app-loader *ngIf="isLoading" [loadingMessage]="'Promoting Changes'"></app-loader>

  <div *ngIf="!isLoading" class="grid-container ag-grid-wrapper" style="width: 100%">
    <ag-grid-angular
      #testCaseResultsGrid
      id="testCaseResultsGrid"
      class="ag-theme-alpine ag-grid-container"
      [columnDefs]="columnDefs"
      [gridOptions]="gridOptions"
      (gridReady)="onGridReady($event)"
      [masterDetail]="true"
      [groupRemoveLowestSingleChildren]="true"
    >
    <!-- [detailCellRenderer]="detailCellRenderer" -->
    <!-- [detailCellRendererParams]="detailCellRendererParams" -->
    </ag-grid-angular>
  </div>
</div>
<div class="modal-footer" style="margin-right: 4px">
  <button
    mat-raised-button
    class="submit-btn-dark-reverse"
    type="button"
    class="submit-btn"
    style="margin-right: 6px"
    (click)="onPromoteChanges()"
    matTooltip="All changes will be saved"
  >
  <!-- TODO: can promote changes be disabled? -->
  <!-- [disabled]="disabled" -->

    Promote Changes
  </button>
  <button
    mat-raised-button
    type="button"
    class="secondary-btn"
    style="margin-right: 6px"
    matTooltip="All changes will be discarded"
    (click)="onDiscardChanges()"
  >
    Discard Changes
  </button>
  <button
    mat-button
    class="text-warning"
    type="button"
    (click)="close()"
    matTooltip="Close and Maintain Current Changes"
  >
    Close
  </button>
</div>
