// Angular Imports
// =========================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
// Prime NG Imports
// =========================================================
import { DialogService } from 'primeng/dynamicdialog';
// Amplify Imports
// =========================================================
import { Auth } from "aws-amplify";
// Custom Imports
// =========================================================
import obj from '../../../core/json/tune-ml.json';
import { handleError } from 'src/app/core/utils/error-handling';
import { TuneMLService } from 'src/app/core/services/db/tune-ml.service';
import { environment } from 'src/environments/environment';
import { CheckIsNumber, SortSizes } from 'src/app/core/utils/global-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalServices } from 'src/app/core/services/db/global-services.service';

@Component({
  selector: 'app-tune-ml',
  templateUrl: './tune-ml.component.html',
  styleUrls: ['./tune-ml.component.scss']
})
export class TuneMLComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  allScenarios = obj;
  scenario: any;
  counter: number;
  environment = environment;

   // Chart Variables
   lineChartData: any;
   lineChartOptions: any;

   modalSummary: any = null;
   previous_2_ids: any[] = [];
   currentRecId: number = null;
   allRecIds: any[] = []

   currentUser: string = null;
   isLoading: boolean = true;
   sizeOrder: any = [];
  constructor(
    private tuneMLService: TuneMLService,
    public dialogService: DialogService,
    private globalServices: GlobalServices,

  ) {

  }

  ngOnInit(): void {
    this.subscribeTotSizeOrder()
    // Get the current user
    Auth.currentUserInfo().then(results => {
      // console.log("User info: ", results)
      this.currentUser = results.username
    })
  }
  private subscribeTotSizeOrder(): void {
    this.globalServices.getSizeOrder()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ({ sizeOrder }) => { this.sizeOrder = sizeOrder },
        complete: () => {  this.getRecordIds() },
        error: (error) => handleError(this.dialogService, error, 'Error fetching size order data')
      })
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  getRecordIds() {
    this.tuneMLService.getRecordIds()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: (rec_ids) => {
        console.log("all rec ids: ", rec_ids)
        let options = [];
        this.allRecIds = rec_ids.data.map(item => {
          if(!this.previous_2_ids.includes(item.rec_id)) options.push(item.rec_id);
          return item.rec_id
        })
        console.log("all rec ids: ", this.allRecIds)

        if (options.length > 0 ){
          this.currentRecId = options[Math.floor(Math.random() * options.length)]
          this.updatePreviousIds(this.currentRecId)
        }
      },
      complete: () => { this.getRecordById(this.currentRecId)},
      error: (error) => handleError(this.dialogService, error, 'Tune Ml - Error fetching All record ids ')
    })
  }

  updatePreviousIds(currentId){
    if (this.previous_2_ids.length === 2){
      this.previous_2_ids.unshift(currentId)
      this.previous_2_ids.pop()
      console.log("updated id's 1: ", this.previous_2_ids)
    } else {
      this.previous_2_ids.unshift(currentId)
      console.log("updated id's 2: ", this.previous_2_ids)
    }
  }

  getRecordById(rec_id){
    this.tuneMLService.getRecordById(rec_id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: ({record}) => {
        this.generateModelSummary(
          {
            ...record,
            size_detail_recs: SortSizes(this.sizeOrder, record.size_detail_recs, 'size_name')
            // size_detail_recs: record.size_detail_recs.sort((a, b) =>  a.size_name > b.size_name  ? 0 : -1)
          }
          )
        console.log("record: ", record)
      },
      complete: () => {},
      error: (error) => handleError(this.dialogService, error, 'Tune Ml - Error fetching record ')
    })
  }

  generateModelSummary(record){
    // console.log("record: ", record);
    const getModalDetails = () => {
      let recoDemandCoverage = 0;
      console.log("record.size_detail_recs: ", record.size_detail_recs)

      const reco_sizes = record.size_detail_recs.filter((reco, i) => {

        Object.assign(record.size_detail_recs[i], {
          checked: reco.ml_recommended || reco.ml_recommended === null,
          stdSizeCd: i,
          user_id: this.currentUser,
          rec_id: this.currentRecId
        })

       if(reco.ml_recommended) {
        recoDemandCoverage += CheckIsNumber(reco.norm_c)

         return reco.ml_recommended
        } else {
          recoDemandCoverage += CheckIsNumber(reco.rad_c)
        }
      })
      console.log("reco_size_adds: ", reco_sizes)
      return {
        totalAdds: reco_sizes.length,
        reco_demand: recoDemandCoverage
      }
    }
    const modalDetails = getModalDetails()

    // Recommended demand coverage = Fitted demand coverage = sum(norm_c for ml_recommended=Yes, or existing size)
    this.modalSummary = {
      ...record,
      // current_demand_coverage: data.current_demand_coverage,
      fitted_demand_coverage: modalDetails.reco_demand,
      // macro_size_range: data.macro_size_range,
      total_size_adds: modalDetails.totalAdds
      // TODO: missing recommended coverage
    }
    console.log("model summary: ", this.modalSummary)
    if (this.modalSummary){
      this.configChart();
    }
  }

  onSubmit(event) {
    console.log("submit: ", this.modalSummary.size_detail_recs)

    this.tuneMLService.submitRecordById(this.modalSummary.size_detail_recs)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe({
      next: () => { },
      complete: () => { console.log('Record submitted successfully '); this.getRecordIds() },
      error: (error) => handleError(this.dialogService, error, 'Tune Ml - Error updating record')
    })
  }

  configChart() {
    const chartLabels = this.modalSummary.macro_size_range.split('|')
    // Columns based on ml recommendations
    let colsToMap = {
      no: {
        label: 'Size Recommendation To Ignore',
        backgroundColor: environment.colors.chartPink,
        borderColor: environment.colors.chartPink,
        categoryPercentage: 1.0,
        barPercentage: 0.80,
        minBarLength: 5,
        data: [],
        order: 1
      },
      yes: {
        label: 'Size Recommendation To Add',
        backgroundColor: environment.colors.chartGreen,
        borderColor: environment.colors.chartGreen,
        categoryPercentage: 1.0,
        barPercentage: 0.80,
        minBarLength: 5,
        data: [],
        order: 1
      },
      null: {
        label: 'Based on History',
        backgroundColor: environment.colors.chartLightBlue,
        borderColor: environment.colors.chartLightBlue,
        categoryPercentage: 1.0,
        barPercentage: 0.80,
        minBarLength: 5,
        data: [],
        order: 1,
      },
      norm_c: {
        label: 'Expected Distribution',
        borderColor: "black",
        data: [],
        type: 'line',
        order: 0,
        fill: 'none',
        borderDash: [10,5]
      }
    }

    this.modalSummary.size_detail_recs.map((item, i) => {
      const reco = item.ml_recommended
      const convert_pct = (value) => Number(value) * 100

      colsToMap.norm_c.data.push(convert_pct(item.norm_c))

      switch(`${reco}`){
        case 'true':
        return colsToMap["yes"].data.push({x: item.size_name, y: convert_pct(item.norm_c)})
        // break;
        case 'false':
       return colsToMap["no"].data.push({x: item.size_name, y: convert_pct(item.norm_c)})
        // break;
        case 'null':
        return colsToMap["null"].data.push({x: item.size_name, y: convert_pct(item.rad_c)})
        // break
      }
    })
    this.isLoading = false
    console.log("cols to map: ", colsToMap)

    this.lineChartData = {
      datasets: Object.values(colsToMap)
    }
    this.lineChartOptions = {
      animation: {
        duration: 0
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'category',
          labels: chartLabels,
          stacked: true,
          beginAtZero: true,
        },
        y: {
          ticks: {
            beginAtZero: true
          }
        }
      },
      legend: {
        display: false,
      },
      plugins: {
        tooltip: {
          mode: 'point',
          intersect: false,
          axis: 'y',
          position: 'nearest',
          backgroundColor: '#ffffff',
          borderWidth: 2,
          displayColors: false,
          titleColor: "#4e4e4e",
          borderColor: "#011c26",
          padding: 10,
          callbacks: {
            title: (context) => {
                console.log("title: ", context)

              return `Size: ${context[0].label}`
            },
            label: (context) => {
              return `${context.dataset.label}: ${context.parsed.y.toPrecision(2)}%`
            },
            labelTextColor: (tooltipItem, chart) => {
              return this.lineChartData.datasets[tooltipItem?.datasetIndex]
              .borderColor;
            },
          }
        }
      }
    }
  }

}
