<app-modal-header
  [modalTitle]="modalTitle"
  [showCloseBtn]="true"
  (closeModal)="close()"
></app-modal-header>
<div class="flex-center-all modal-content">

  <mat-expansion-panel
    id="edit-store-distro-add-store-panel"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title style="justify-content: flex-end">
        Add New Store
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="p-grid" style="border-top: 1px solid lightgrey">
      <form
        #formDirective="ngForm"
        [formGroup]="addStoreForm"
        (ngSubmit)="addNewDistro(addStoreForm.value, formDirective)"
        style="flex: 2; display: flex; align-items: center; padding-bottom: 20px"
      >
        <span class="p-col-3" style="display: flex; align-items: flex-end;">
          <mat-form-field
          appearance="standard" style="width: 100%">
            <!-- <p>{{addStoreForm.value.store}}-{{addStoreForm.value.distro}}</p> -->
            <!-- <mat-label>Parameter ID</mat-label> -->
            <input
              matInput
              type="text"
              value="{{addStoreForm.value.store}}-{{addStoreForm.value.distro}}"
              formControlName="parameter_id"
              [style]="'color: ' + environment.colors.primary"
            />
            <mat-label>Parameter ID</mat-label>
          </mat-form-field>
        </span>
        <span class="p-col-3">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Store</mat-label>
            <input matInput type="text" formControlName="store" required />
            <mat-error
            *ngIf="
            addStoreForm.get('store').touched &&
            addStoreForm.get('store').errors
            "
            >{{ setFormError("store") }}</mat-error>
          </mat-form-field>
        </span>
        <span class="p-col-3">
          <mat-form-field appearance="standard" style="width: 100%">
            <mat-label>Distro</mat-label>
            <input matInput type="text" formControlName="distro" required />
            <mat-error
            *ngIf="
            addStoreForm.get('distro').touched &&
            addStoreForm.get('distro').errors
            "
            >{{ setFormError("distro") }}</mat-error>
          </mat-form-field>
        </span>

        <!-- Add or Reset Buttons -->
        <span
          class="p-col-3"
          style="
            text-align: center;
            display: flex;
            column-gap: 10px;
            justify-content: flex-end;
          "
        >
          <button mat-button type="reset" class="text-warning" (click)="resetAddStoreForm(formDirective)">Reset</button>
          <button
            mat-raised-button
            [disabled]="!formDirective.form.valid"
            type="submit"
            class="submit-btn"
            >
            Add
          </button>
        </span>
      </form>
    </div>
  </mat-expansion-panel>
  <ag-grid-angular
    #mainGrid
    id="editStoreDistroModalGrid"
    class="ag-theme-alpine ag-modal-grid-large"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [masterDetail]="true"
  ></ag-grid-angular>
  <!-- [detailCellRendererParams]="detailCellRendererParams" -->

</div>
<app-modal-footer
  [showUpdateBtn]="true"
  confirmBtnText="Save & Close"
  [disabled]="addedDistros.length === 0 && deletedDistros.length === 0"
  (update)="onSaveAndClose()"
  (closeModal)="close()"
></app-modal-footer>
