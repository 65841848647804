import { NumberFilter } from "ag-grid-community";

// export interface ImportTabData {
//     id: string;
//     field: string;
//     selectStyle: boolean;
//     data: any[];
//     detailColDefs: any[];
//     getDetailRowData: Function;
//     colDefs: any[];
// }

export interface Size {
  stdSizeCd: number;
  sizeNm: string;
  sales?: number;
  demand?: number;
  final_c?: number;
  radc?: number;
  recoContribPct?: number;
  locked?: boolean;
  delete?: number;
  userAddedSrc?: string;
}

// export interface SizeStratStudioData {
//     prodHierSk: number;
//     prodId: string;
//     location: string;
//     season: string;
//     sizeRange: string;
//     sales: number;
//     demand: number;
//     add_sizes: string;
//     sizeRecords: {
//         stdSizeCd: number,
//         sizeNm: string,
//         radc: number,
//         sales: number,
//         demand: number,
//         delete?: number,
//         recoContribPct?: number,
//         recoSizes?: {
//             stdSizeCd: number,
//             sizeNm: string;
//             modelradc: number
//         }[];
//     }[];
// };

export interface DefineSizeOfferingsData {
  add_sizes: string;
  prodHierSk: number;
  prodId: string;
  channel?: string, 
  location?: string;
  season: string;
  sizeRange: string;
  sales: number;
  demand: number;
  radcs: Size[];
  delete?: number;
  recoContribPct?: number;
}

// export interface ProdIdNode {
//     prodId: string;
//     prodHierSk: number;
//     children?: ProdIdNode[];
//     model?: any;
// }

export interface PackSettingsData {
  dim_id: number;
  highlight: boolean;
  location: string;
  product_id: string;
  settings: {
    og_val?: number;
    setting_group: string;
    setting_name: string;
    setting_value: number;
  }[];
  size_range: string;
  test_executed?: boolean;
  test_scenarios: {
    fit_score: number;
    gap_pct: number;
    size_of_buy: number;
    test_scenario_name: string;
  }[];
  vendor_packs_available: boolean;
}

export interface TestCaseResultsData {
  prodHierSk: number;
  location: string;
  sizeRange: string;
  testCase: string;
  currentGapPct: number;
  proposedGapPct: number;
  currentFitScore: number;
  proposedFitScore: number;
  notes: string;
  sizeTestRecords: {
    stdSizeCd: number;
    sizeNm: string;
    currentPct: number;
    demandPct: number;
    proposedPct: number;
    currentError: number[];
    proposedError: number[];
  }[];
}

// export interface TestScenarioCreationData {
//   prodId: string;
//   prodHierSk: number;
//   location: string;
//   sizeRange: string;
//   sales: number;
//   demand: number;
//   gapPct: number;
//   fitScore: number;
//   num_of_tests: number;
//   testCases: {
//     name: string;
//     sizeOfBuy: string;
//     sellingLocation: string;
//   }[];
// }
