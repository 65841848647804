// Angular Imports & rxjs
// =========================================================
import { Component, OnInit } from '@angular/core';
// PrimeNg Imports
// =========================================================
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
    selector: 'app-define-size-offerings-add-size-modal',
    templateUrl: './define-size-offerings-add-size.component.html',
    styleUrls: ['./define-size-offerings-add-size.component.scss']
})

export class AddSizeRecommendationModalComponent implements OnInit {

    modalTitle = "Add Size Recommendation";
    sizeToAdd: string = ''
    recoContribPct = null;

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig
    ) {}

    ngOnInit(): void {
        console.log("Define size offereings -> add size: ", this.config.data)
        this.sizeToAdd = this.config.data.sizeNm
        this.recoContribPct = this.config.data.recoContribPct * 100
    }

    onSubmit() {
        this.ref.close({
                ...this,
                data: this.config.data,
                recoContribPct: this.recoContribPct / 100
            })
    }

    close() {
        this.ref.close()
    }
}